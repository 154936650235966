import { render, staticRenderFns } from "./CourseToc.vue?vue&type=template&id=0125de50&scoped=true&"
import script from "./CourseToc.vue?vue&type=script&lang=js&"
export * from "./CourseToc.vue?vue&type=script&lang=js&"
import style0 from "./CourseToc.vue?vue&type=style&index=0&id=0125de50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0125de50",
  null
  
)

export default component.exports