<template>
  <Container class="OverviewPanel">
    <h3>{{ $t('title') }}</h3>
    <div class="content-wrapper">
      <div class="left-container">
        <Feed
          v-if="courseHasTheory && !allTheoryDone"
          type="theory"
          :next-theory-journey-step="nextTheoryJoruneyStep"
          :course-id="course.id"
          :active-journey="activeJourney" />
        <Feed
          v-if="courseHasPractice && currentScenario && !isMentalHygiene"
          type="practice"
          :current-scenario="currentScenario"
          :lifeskill-id="lifeskillId"
          :active-journey="currentScenario.journey" />
          <Feed
          v-if="journeyFinished && !mentalHygieneFinished && currentMentalHygieneStep && isMentalHygiene"
          type="practice"
          :current-mental-hygiene-step="currentMentalHygieneStep"
          :lifeskill-id="lifeskillId" />
        <SuggestedVideos :lifeskill-id="lifeskillId" />
      </div>
      <div class="right-container">
        <About :lifeskill-id="lifeskillId" />
        <TeamLifekeys :lifeskill="lifeskillId" />
        <TeamLeaderboard :lifeskill="lifeskillId" />
      </div>
    </div>
  </Container>
</template>

<translations>
    introduction: 'Introduction'
    introduction_no: 'Introduksjon'
    title: "Next tasks"
    title_no:  "Neste oppgaver"
    quizText: 'Complete quiz to get your life key'
    quizText_no: 'Ta reisequizen for å få livsnøkkelen'
    journeyQuiz: 'Journey quiz'
    journeyQuiz_no: 'Reisequiz'
    
    
    
    theoryText: 'Finish the theory journey to get a life key'
    theoryText_no: 'Fullfør teorireisen for å få en livsnøkkel'

    doneStepsInformation: 'Journey {activeJourneyIndex} - Step {doneStepsCount}/{totalStepsCount}'
    doneStepsInformation_no: 'Reise {activeJourneyIndex} - Steg {doneStepsCount}/{totalStepsCount}'
    doneScenariosInformation: 'Scenario {doneScenariosCount}/{totalScenariosCountInJourney}'

    dailyJoys: Daily Joys
    dailyJoys_no: Dagens gleder

    kissTheFrog: Kiss The Frog
    kissTheFrog_no: Kyss froskene

    focusCheck: Focus Check
    focusCheck_no: Fokussjekk

    dailyPlan: Daily Plan
    dailyPlan_no: Dagsplan

    learningDiary: Learning Diary
    learningDiary_no: Læringsdagbok

    practiceJourney1: Practice journey 1
    practiceJourney1_no: Praksisreise 1
    doneForToday: Done for today, come back tomorrow!
    doneForToday_no: Ferdig for i dag, sees i morgen!
    mentalHygiene: Mental hygiene
    mentalHygiene_no: Mentalhygiene


</translations>

<script>
import Feed from '../../components/Feed';
import SuggestedVideos from '../../components/SuggestedVideos';
import About from '@/modules/dashboard/components/About';
import Container from '@/modules/dashboard/components/Container';
import TeamLeaderboard from '@/modules/team/TeamLeaderboard';
import TeamLifekeys from '@/modules/team/TeamLifekeys';
import { mapGetters } from 'vuex';

import joyIcon from '@/assets/images/mh-joy.svg';
import frogIcon from '@/assets/images/mh-frog.svg';
import focusIcon from '@/assets/images/mh-focus.svg';
import planIcon from '@/assets/images/mh-plan.svg';
import diaryIcon from '@/assets/images/mh-diary.svg';

export default {
    components: {
        Feed,
        SuggestedVideos,
        About,
        Container,
        TeamLeaderboard,
        TeamLifekeys,
    },

    props: {
        lifeskillId: {
            type: String,
            required: true,
            default: ''
        }
    },

    data() {
        return {
            excludedLifeskillIdsForTheory: ['10'],
            excludedLifeskillIdsForPractice: ['02']
        };
    },

    async mounted() {
      await this.$store.dispatch('scenario/loadProgress');
      await this.$store.dispatch('scenario/loadData', this.course.id);
    },

    computed: {
        ...mapGetters({
            getCourseStats: 'moduleCourse/getCourseStats',
            courseByLifeskillId: 'moduleCourse/courseByLifeskillId',
            getStepById: 'moduleCourse/getStepById',
            getJourneyById: 'moduleCourse/getJourneyById',
            getFirstStepFromJourney: 'moduleCourse/getFirstStepFromJourney',
            coursesInProgress: 'moduleApp/inProgressItems',

            joysTimesFinishedCount: 'moduleMentalhygiene/joysTimesFinishedCount',
            frogsTimesFinishedCount: 'moduleMentalhygiene/frogsTimesFinishedCount',
            focusChecksTimesFinishedCount: 'moduleMentalhygiene/focusChecksTimesFinishedCount',
            dayplansTimesFinishedCount: 'moduleMentalhygiene/dayplansTimesFinishedCount',
            diariesTimesFinishedCount: 'moduleMentalhygiene/diariesTimesFinishedCount',

            todaysJoys: 'moduleMentalhygiene/todaysJoys',
            todaysFrogs: 'moduleMentalhygiene/todaysFrogs',
            todaysFocusChecks: 'moduleMentalhygiene/todaysFocusChecks',
            dayplanToday: 'moduleMentalhygiene/dayplanToday',
            todaysDiary: 'moduleMentalhygiene/todaysDiary',
            hasAwardedLifekey: 'moduleMentalhygiene/hasAwardedLifekey',

            mentalHygieneFinished: 'moduleMentalhygiene/isTotalPracticeFinished',
            journeyFinished: 'moduleMentalhygiene/journeyFinished',

            areJoysFinishedToday: 'moduleMentalhygiene/areJoysFinishedToday',
            areFrogsFinishedToday: 'moduleMentalhygiene/areFrogsFinishedToday',
            areFocusChecksFinishedToday: 'moduleMentalhygiene/areFocusChecksFinishedToday',
            isDayplanFinishedToday: 'moduleMentalhygiene/isDayplanFinishedToday',
            areDiariesFinishedToday: 'moduleMentalhygiene/areDiariesFinishedToday',
        }),

        mentalHygieneSteps() {
            return [
                {
                    title: this.$t('dailyJoys') + ' - ' + Math.min(this.joysTimesFinishedCount+1, 10) + '/10',
                    icon: joyIcon,
                    timesFinished: Math.min(this.joysTimesFinishedCount, 10),
                    finishedToday: this.areJoysFinishedToday
                },
                {
                    title: this.$t('kissTheFrog') + ' - ' + Math.min(this.frogsTimesFinishedCount+1, 10) + '/10',
                    icon: frogIcon,
                    timesFinished: Math.min(this.frogsTimesFinishedCount, 10),
                    finishedToday: this.areFrogsFinishedToday
                },
                {
                    title: this.$t('focusCheck') + ' - ' + Math.min(this.focusChecksTimesFinishedCount+1, 10) + '/10',
                    icon: focusIcon,
                    timesFinished: Math.min(this.focusChecksTimesFinishedCount, 10),
                    finishedToday: this.areFocusChecksFinishedToday
                },
                {
                    title: this.$t('dailyPlan') + ' - ' + Math.min(this.dayplansTimesFinishedCount+1, 10) + '/10',
                    icon: planIcon,
                    timesFinished: Math.min(this.dayplansTimesFinishedCount, 10),
                    finishedToday: this.isDayplanFinishedToday
                },
                {
                    title: this.$t('learningDiary') + ' - ' + Math.min(this.diariesTimesFinishedCount+1, 10) + '/10',
                    icon: diaryIcon,
                    timesFinished: Math.min(this.diariesTimesFinishedCount, 10),
                    finishedToday: this.areDiariesFinishedToday
                }
            ];
        },

        allTheoryDone(){
          return this.courseStats.accumulatedStepCount === this.courseStats.accumulatedStepProgress
        },
        
        step() {
            const nextStepId = this.courseStats.firstActiveStepId;
            return this.$store.state.moduleCourse.steps[nextStepId];
        },
      
        nextTheoryJoruneyStep() {
            const nextStepId = this.courseStats.firstActiveStepId;
            
            const firstJourney = this.getJourneyById(this.firstJourneyId);
            const firstJourneyStepId = firstJourney.stepIds[0];
            
            const introductionStep = this.getStepById(this.course.introStepId)?  this.getStepById(this.course.introStepId) : null;

            if(introductionStep && introductionStep.progress<1){

                return {
                    stepId: introductionStep.id,
                    courseId: introductionStep.courseId,
                    title: this.$t('introduction'),
                    subtitle: '',
                    text: '',
                    activities: introductionStep.activityNames,
                    isIntroduction: true,
                    
                };
            } else {
                if (this.step) {
                  const text = this.$t('theoryText');
                  const step = this.getStepById(nextStepId ? nextStepId : firstJourneyStepId);
                  const isIntro = step.title==="Intro";
                  return {
                      stepId: step.id,
                      courseId: this.course.id,
                      title: step.title,
                      subtitle: isIntro? '' : this.activeTheoryJourneyDoneStepsInformation,
                      activities: step.activityNames,
                      text
                  };
                
              } else {
                return {
                      title: this.$t('journeyQuiz'),
                      subtitle: '',
                      text: this.$t('quizText'),
                      isQuiz: true
                  };
              }
            }
            
        },

        activeTheoryJourneyDoneStepsInformation() {
            const activeJourneyId = this.courseStats.firstActiveJourneyId;
            const activeJourneyIndex = this.getJourneyById(activeJourneyId).index+1;

            var doneStepsCount = 0;
            if(this.getStepById(this.course.introStepId)==null){
              doneStepsCount = this.courseStats.numberOfDoneSteps+1;
            } else {
              doneStepsCount = this.step.number;
            }
            
            const totalStepsCount = this.courseStats.numberOfSteps

            return this.$t('doneStepsInformation', {
                activeJourneyIndex,
                doneStepsCount,
                totalStepsCount
            });
        },

        scenarios() {
          return this.$store.getters['scenario/byCourseId'](this.course.id)
            .map(x => x.stepGroups).flat()
            .map(sg => sg.steps || []).flat()
            .map(step => {
              return (step.scenarios||[]).map(s => ({
                stepId: step.id,
                  ...s,
              }));
            })
            .flat()
        },

        nextScenarioIndex(){
          if(this.scenarios){
            for (var i = 0; i < this.scenarios.length; i++) {
              if (!this.$store.getters['scenario/isOpen'](this.scenarios[i].id)) {
                return i;
              } 
            }
          } 
          return 0;
        },


        isMentalHygiene(){
          return this.lifeskillId==='70';
        },

        currentMentalHygieneStep(){
          var currentStep = null;
          for (var key in this.mentalHygieneSteps) {
            if(!this.mentalHygieneSteps[key].finishedToday && this.mentalHygieneSteps[key].timesFinished<10){
              currentStep = {
                stepNumber: key,
                subtitle: this.$t('practiceJourney1'),
                title: this.mentalHygieneSteps[key].title,
                icon: this.mentalHygieneSteps[key].icon,
                
                isMentalHygiene: true,
              }
              break;
            } else {
              currentStep = {
                subtitle: this.$t('practiceJourney1'),
                title: this.$t('mentalHygiene'),
                text: this.$t('doneForToday'),
                icon: '@/assets/images/lifeskillBadgeImages/practice-badge.png',
                isMentalHygiene: true,
              }
            }
          }
          return currentStep;
        },

        currentScenario() {

          const scenario = this.scenarios.find(s => !this.$store.getters['scenario/isDone'](s.id));
          if (scenario) {
            const step = this.getStepById(scenario.stepId);
            const journey = this.getJourneyById(step.stepGroupId);

            return {
              id: scenario.id,
              step,
              journey,
              totalNumOfScenarios: this.course.totalNumberOfScenarios,
              nextScenarioIndex: this.nextScenarioIndex,
            }
          }
          return null;
        },

        activePracticeJourneyDoneScenariosInformation() {
            const currentScenarioInfo = this.course.currentScenarioInfo;
            const doneScenariosCount = currentScenarioInfo.scenarioIndex;

            return this.$t('doneScenariosInformation', {
                doneScenariosCount,
                totalScenariosCountInJourney: this.totalScenariosCountInJourney
            });
        },

        totalScenariosCountInJourney() {
            return this.scenarios.length;
        },

        courseStats() {
            return this.getCourseStats(this.course.id);
        },

        firstJourneyId() {
            return this.course.stepGroupIds[0];
        },

        activeJourney() {
            const firstActiveJourneyId = this.courseStats.firstActiveJourneyId;

            return firstActiveJourneyId
            
                ? this.getJourneyById(firstActiveJourneyId)
                : this.getJourneyById(this.firstJourneyId);
        },

        course() {
            return this.courseByLifeskillId(this.lifeskillId);
        },

        courseHasTheory() {
            const courseIsNotOnExcludeList = !this.excludedLifeskillIdsForTheory.some(id => id === this.lifeskillId);

            return this.course && courseIsNotOnExcludeList;
        },

        courseHasPractice() {
          const excluded = this.excludedLifeskillIdsForPractice
            .some(id => id === this.lifeskillId);
          if (excluded) {
            return false;
          }

          return this.totalScenariosCountInJourney > 0;
        }
    },
};
</script>

<style lang="scss" scoped>
.OverviewPanel {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 1em;

    .About {
      padding-bottom: 2em;
    }

    h3 {
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        color: #EEEEEE;
        margin-top: 54px;
        margin-bottom: 1.38em;
        text-align: left;
        width: 100%;
        margin-bottom: 22px;
        @media (max-width: 886px) {
            font-size: 18px;
            margin: 54px auto 22px;
        }
        @media (max-width: 530px) {
            font-size: 16px;
        }
    }
    .content-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        @media (max-width: 884px) {
            flex-direction: column;
            align-items: center;
        }
        .left-container {
            width: 100%;
            margin-right: 30px;

            @media (max-width: 884px) {
                margin: 0;
            }
        }
    }
}
</style>
