<template>
  <div class="About">
    <div
      v-if="isPracticeOrTheoryPage"
      class="modal-intro">
      <h4>{{ isTheoryPage ? $t('titleTheory') : $t('titlePractice') }}</h4>
      <p>
        {{ isTheoryPage ? $t('textTheory') : $t('textPractice') }} <br>
        <span @click="openPopup(popupType)">{{ $t('clickHere') }}.</span>
      </p>
    </div>

    <div class="about-section">
      <h4>
        {{ $t('secondTitle') }}
      </h4>
      <div
        v-if="course"
        class="course-info"
        :class="{ isCollapsed }"
        v-html="course.abstract" />
      <h4 class="learn-more">
        <a @click="isCollapsed = !isCollapsed">{{ isCollapsed ? $t('showLess') : '...' }}</a>
      </h4>
    </div>
    <div class="authors-wrapper">
      <h4>{{ $t('thirdTitle') }}</h4>
      <AboutAuthor />
    </div>
  </div>
</template>

<translations>
    titleTheory: 'Learn fast. Remember forever'
    titleTheory_no: 'Lær hurtig. Husk for alltid'
    textTheory: 'Master the theory super-fast with fun memory techniques and repetitions just before your brain starts to forget. All you need is 5 minutes per day.'
    textTheory_no: 'Mestre teorien lynraskt med morsomme husketeknikker og repetisjoner rett før hjernen din begynner å glemme. Alt du trenger er 5 minutter om dagen.'
    titlePractice: 'Ace New Habits'
    titlePractice_no: 'Skap nye vaner'
    textPractice: 'Let new habits shape your future'
    textPractice_no: 'La nye vaner gjøre deg dyktig'
    secondTitle: 'About this lifeskill'
    secondTitle_no: 'Om ferdigheten'
    thirdTitle: 'Authors'
    thirdTitle_no: 'Forfatter'
    showLess: 'Show less'
    showLess_no: 'Vis mindre'
    clickHere: Click here
    clickHere_no: Klikk her
</translations>

<script>
import AboutAuthor from '@/modules/course/components/AboutAuthor';
import TheoryPopup from '@/modules/course/components/MemomapsOnboardingPopup';
import PracticePopup from '@/modules/course/components/PracticeOnboardingPopup';

import { mapGetters } from 'vuex';

export default {
    components: {
        AboutAuthor
    },

    props: {
        lifeskillId: {
            type: String,
            required: false
        },
        popupType: {
            type: String,
            required: false
        }
    },

    data() {
        return {
            isCollapsed: false
        };
    },

    computed: {
        ...mapGetters({
            courseByLifeskillId: 'moduleCourse/courseByLifeskillId'
        }),

        course() {
            return this.courseByLifeskillId(this.lifeskillId);
        },

        isPracticeOrTheoryPage() {
            return this.isTheoryPage || this.popupType === 'practice';
        },

        isTheoryPage() {
            return this.popupType === 'theory';
        }
    },

    methods: {
        openPopup(type) {
            this.$modal.open({
                component: type === 'theory' ? TheoryPopup : PracticePopup,
                target: 'app-modal'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.About {
    max-width: 285px;
    min-width: 285px;
    @media (max-width: 886px) {
        margin-bottom: 2em;
        margin-top: 2em;
        max-width: 100%;
        min-width: 100%;
    }

    .modal-intro {
        margin-bottom: 2em;
    }

    h4 {
        font-size: 16px;
        margin: 0;
        line-height: 19px;
        color: #EEEEEE;
        font-weight: 500;
    }
    .about-section {
        position: relative;
        display: none; //TODO We need english translations for this section
        .course-info {
            font-size: 16px;
            line-height: 22px;
            color: rgba(21, 21, 21, 0.7);
            overflow: hidden;
            transition: 0.5s;
            max-height: 5em;

            &.isCollapsed {
                max-height: 50em;
            }
        }
        .learn-more {
            position: absolute;
            bottom: -2em;
            a {
                font-weight: normal;
                color: #5983f7;
            }
        }
    }

    p {
        font-size: 16px;
        line-height: 22px;
        color: #9FB4EF;
        span {
            color: #5983f7;
        }
        @media (max-width: 530px) {
            font-size: 14px;
        }
    }

    a {
        text-decoration: none;
        color: #151515;
        cursor: pointer;
    }
}
</style>
