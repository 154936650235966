<template>
  <div class="PracticeVerticalTimeline">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="Step"
      @click.self="$emit('click', index)">
      <div
        class="bullet"
        :class="[`align-${item.align}`]">
        <div
          class="line"
          :class="{ active: stepIsCurrent(item) || stepIsDone(item) }" />

        <slot
          name="bullet"
          :type="item.type"
          :item="item"
          :index="index" />

        <div
          class="line"
          :class="{ active: stepIsDone(item) }" />
      </div>
      <div class="content">
        <slot
          name="content"
          :item="item"
          :text="item.text"
          :index="index">
          <div class="content-wrapper">
            <p>{{ $t('step', { index: index + 1 }) }}</p>
            <div class="headerTitle">
              {{ title(item) }}
            </div>
            <div class="icons">
              <div
                v-for="(scenario, i) in item.scenarios"
                :key="scenario.id"
                :data-scenario="scenario.id"
                @click="gotoScenario(item, scenario, i)"
                :class="{ done: scenarioIsDone(scenario), current: scenarioIsCurrent(item, scenario) }"
                class="icon --global-clickable">
                <svgicon
                  class="leaf"
                  icon="leaf" />
              </div>
            </div>
          </div>
          <div class="button-wrapper">
            <div
              v-if="journeyLocked && !stepIsDone(item)"
              class="button-blue">
              {{ $t('locked') }}
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<translations>
  step: Step {index}
  step_no: Steg {index}

  start: Start
  start_no: Start

  locked: Locked
  locked_no: Låst
</translations>

<script>

export default {
    props: {
        items: {
            type: Array
        },
        courseId: {
            type: String,
            required: true
        },
        journeyLocked: {
            type: Boolean,
            required: true
        },
    },
    computed: {
        course() {
            return this.$store.state.moduleCourse.courses[this.courseId];
        },
        currentScenarioInfo() {
            return this.course.currentScenarioInfo;
        },
    },
    methods: {
        title(step) {
          const s = this.$store.getters['moduleCourse/getStepById'](step.id);
          if (s) {
            return s.title;
          }
        },

        stepIsCurrent(step) {
          const index = this.items.findIndex(x => x.id === step.id);
          const previousStep = this.items[index-1];
          if (this.stepIsDone(step)) {
            return false;
          }
          if (!previousStep || this.stepIsDone(previousStep)) {
            return true;
          }
          return false;
        },

        stepHasProgress(step) {
          return (step.scenarios ||[]).some(s => this.scenarioIsDone(s));
        },

        stepIsDone(step) {
          return (step.scenarios || []).every(s => this.scenarioIsDone(s));
        },

        scenarioIsDone(scenario) {
          return this.$store.getters['scenario/isDone'](scenario.id);
        },

        scenarioIsCurrent(item, scenario) {
          if (this.scenarioIsDone(scenario)) {
            return false;
          }
          if (this.journeyLocked) {
            return false;
          }
          if (!this.$store.getters['scenario/isOpen'](scenario.id)) {
            return false;
          }

          const idx = item.scenarios.findIndex(s => s.id === scenario.id);
          const prev = item.scenarios[idx-1];
          if (!prev) {
            return true;
          }
          if (prev && this.scenarioIsDone(prev)) {
            return true;
          }
          return false;
        },

        gotoScenario(step, scenario, scenarioIndex) {
          if (this.$store.getters['scenario/isOpen'](scenario.id)) {
            this.$router.push({
              name: 'ScenarioView',
              params: {
                id: scenario.id,
                lifeskillId: this.course.lifeskillNumber,
                stepId: step.id,
                scenarioIndex
              }
            });
          }
        },
    }
};
</script>

<style lang="scss" scoped>
$margin: 0.8em;

.PracticeVerticalTimeline {
  .Step {
    position: relative;
    display: flex;
    align-items: center;

    &:first-child {
      .line1 {
        visibility: hidden;
      }
    }
    &:last-child {
      .line2 {
        visibility: hidden;
      }
    }
  }

  .content-wrapper {
    padding-right: 20px;
    .icons {
      display: flex;
      margin-top: 0.94em;
      .icon {
        background-color: none;
        border: 1px solid #2C3182;
        width: 2.2em;
        height: 2.2em;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.31em;
        @media (max-width: 530px) {
          width: 29px;
          height: 29px;
        }

        .leaf {
          width: 0.97em;
          height: 0.97em;
          fill: #5983F7;
        }

      }

      .done {
        background-color: #2351CE;

        .leaf {
          fill: #A3B9F9;
        }
      }

      .current {
        background-color: rgb(235, 242, 253);
        border: 1px solid rgb(217, 228, 245);

        animation: pulse-animation2 3s ease 1s infinite;
        $shadowColor: $purple;
        box-shadow: 0 0 0 0 rgba($shadowColor, 0.5);

        @keyframes pulse-animation2 {
          0% {
            box-shadow: 0 0 0 0 var(--local-color, $shadowColor);
          }
          50% {
            box-shadow: 0 0 0 0.5em transparent;
          }
          100% {
            box-shadow: 0 0 0 0 transparent;
          }
        }

        .leaf {
          fill: #5983f7;
        }
      }
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    .button-blue {
      cursor: pointer;
    }
  }

  .headerTitle {
    font-size: 140%;
    font-weight: 600;
    color: #EEEEEE;
    @media (max-width: 530px) {
      font-size: 16px;
    }
  }

  .bullet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    min-width: 66px;

    &.align-top {
      .line1 {
        display: none;
      }
    }
  }

  .line {
    min-height: $margin;
    flex: 1;

    background: repeating-linear-gradient(transparent 0px, transparent 2px, rgba(255, 255, 255, 0.1) 2px, rgba(255, 255, 255, 0.1) 4px);
    width: 2px;

    &.active {
      background-color: rgb(48, 122, 255);
    }
  }

  .Step:last-child {
    .line:last-child {
      visibility: hidden;
    }
  }

  .content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 1.88em;
    padding-bottom: 1.25em;
    border-bottom: 1px solid rgba(133, 133, 133, 0.12);
    p {
      font-size: 1em;
      line-height: 1.38em;
      color: #99B1BF;
      margin: 0;
      @media (max-width: 530px) {
        font-size: 14px;
      }
    }
  }
}
</style>
