<template>
  <div class="DashboardMemorySystemDetails">
    <!-- <div class="title" v-t="`memogenius.techniques.${systemId}.title`"></div> -->
    <div
      class="text"
      v-html="text" />

    <div class="footer">
      <StandardButton
        v-for="articleId in articles"
        :key="articleId"
        class="articleButton"
        :label="getArticleButtonLabel(articleId)"
        @click="navigateToArticle(articleId)">
        {{
          $te(`memogenius.articlesMetadata.${articleId}.labelAsIntroButton`)
            ? $t(`memogenius.articlesMetadata.${articleId}.labelAsIntroButton`)
            : $t('memogenius.MemogeniusSystemIntroView.articleButtonLabel')
        }}
      </StandardButton>
      <StandardButton
        v-if="hasPractice"
        class="practiceButton"
        :label="$t(`memogenius.MemogeniusSystemIntroView.practiceButtonLabel`)"
        icon="arrow-right"
        @click="startPractice()">
        {{ $t(`memogenius.MemogeniusSystemIntroView.practiceButtonLabel`) }}
      </StandardButton>
    </div>
  </div>
</template>

<script>
import StandardButton from '@/components/StandardButton';
import MemogeniusSystemIntroPopup from '@/components/memogenius/MemogeniusSystemIntroPopup';

const images = require.context('@/assets/images/memogenius/system/bg/', true, /\.(png|jpg)$/);

export default {
    components: { StandardButton },
    props: {
        systemId: {
            type: String
        }
    },
    inject: ['theme'],
    computed: {
        text() {
            return this.$t(`memogenius.techniques.${this.systemId}.intro`);
        },
        backgroundImage() {
            return images(`./${this.systemId}.jpg`);
        },
        hasPractice() {
            return this.$t(`memogenius.techniques.${this.systemId}.hasPractice`) === 'yes';
        },
        articles() {
            return this.$t(`memogenius.techniques.${this.systemId}.articles`);
        }
    },
    methods: {
        getArticleButtonLabel(articleId) {
            return this.$te(`memogenius.articlesMetadata.${articleId}.labelAsIntroButton`)
                ? this.$t(`memogenius.articlesMetadata.${articleId}.labelAsIntroButton`)
                : this.$t('memogenius.MemogeniusSystemIntroView.articleButtonLabel');
        },
        navigateToArticle(articleId) {
            this.$modal.open({
                component: MemogeniusSystemIntroPopup,
                target: 'app-modal',
                props: {
                    systemId: this.systemId,
                    articleId: articleId,
                    showArticleOnly: true
                }
            });
        },
        startPractice() {
            this.$store.commit('moduleApp/setSystemExitTo', this.$route.path);
            this.$router.push({
                name: 'MemogeniusSystem',
                params: {
                    systemId: this.systemId
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.DashboardMemorySystemDetails {
}

.text {
    line-height: 1.5em;
    color: rgba(white, 0.9);

    /deep/ p:first-child {
        margin-bottom: 1em;
        margin-top: 0;
        font-weight: bold;
    }
}
.footer {
    padding-top: 1em;
}

@include media('>=#{$breakpointForItemScaling}') {
    .footer {
        display: flex;
        justify-content: space-between;
    }
}
@include media('<#{$breakpointForItemScaling}') {
    .footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .practiceButton {
        margin-top: 1em;
    }
}
</style>
