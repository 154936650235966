<template>
  <transition
    name="collapse"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave">
    <slot />
  </transition>
</template>

<script>
export default {
    props: {
        horizontal: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        enter(el) {
            if (this.horizontal) {
                const targetWidth = el.clientWidth;
                el.style.width = '0px';
                el.getBoundingClientRect();
                el.style.width = `${targetWidth}px`;
            } else {
                const targetHeight = el.clientHeight;
                el.style.height = '0px';
                el.getBoundingClientRect();
                el.style.height = `${targetHeight}px`;
            }
        },
        afterEnter(el) {
            el.style.removeProperty('height');
            el.style.removeProperty('width');
        },
        leave(el) {
            if (this.horizontal) {
                el.style.width = el.clientWidth + 'px';
                el.getBoundingClientRect();
                el.style.width = '0.01px'; // NOTE: Needed to avoid strange flickering bug in Safari (0px would behave as if style.width got blank right before leave completed)
            } else {
                el.style.height = el.clientHeight + 'px';
                el.getBoundingClientRect();
                el.style.height = '0.01px';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.collapse-enter-active,
.collapse-leave-active {
    overflow: hidden;
    transition: height 0.5s, width 0.5s;
}
</style>
