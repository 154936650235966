<template>
  <div
    class="DashboardGenericNotification"
    :class="{ unread }">
    <div class="text">
      {{ text }}
    </div>
    <div
      v-if="image"
      class="image" />
    <div class="time">
      {{ time }}
    </div>
  </div>
</template>

<script>
export default {
    props: {
        unread: {
            type: Boolean,
            default: false
        },
        text: String,
        image: String,
        time: String
    }
};
</script>

<style lang="scss" scoped>
.DashboardGenericNotification {
    padding: 1em;

    &.unread {
        background-color: #c5fdc5; //green;
    }
}
.image {
    width: 5em;
    height: 4em;
    background-color: gray;
    margin-top: 0.5em;
}
.time {
    color: rgba(black, 0.5);
    margin-top: 0.5em;
}
</style>
