<template>
  <div class="DashboardMentalHygiene">
    <template v-if="journeyFinished">
      <div
        class="--global-clickable"
        @click="goto()">
        <Icons 
          v-if="step > 0"
          :step="step"
          :active="false" />
      </div>
    </template>
  </div>
</template>

<script>
import Icons from '@/modules/mentalhygiene/components/TopBarAnimations.vue';
import { mapGetters } from 'vuex';
// import moment from 'moment';

const steps = {
  joy: 1,
  frog: 2,
  focus: 3,
  plan: 4,
  diary: 5,
}

export default {
  components: { Icons },
  computed: {
    ...mapGetters({
      joyDone: 'moduleMentalhygiene/areJoysFinishedToday',
      frogsDone: 'moduleMentalhygiene/areFrogsFinishedToday',
      focusDone: 'moduleMentalhygiene/areFocusChecksFinishedToday',
      planDone: 'moduleMentalhygiene/isDayplanFinishedToday',
      diaryDone: 'moduleMentalhygiene/areDiariesFinishedToday',
      journeyFinished: 'moduleMentalhygiene/journeyFinished',
    }),

    step() {
      // const t = moment().hour(15).minute(0);
      // const afterWork = moment().isAfter(t);
      if (!this.joyDone) { return steps.joy }
      if (!this.frogsDone) { return steps.frog }
      if (!this.focusDone) { return steps.focus }
      if (!this.planDone) { return steps.plan }
      if (!this.diaryDone) { return steps.diary }
      return 0;
    },

  },
  data: function() {
    return {
    }
  },

  props: {},

  mounted() {
    return this.$store.dispatch('moduleMentalhygiene/load');
  },

  methods: {
    goto() {
      if (this.step) {
        this.$router.push({
          name: 'MentalHygiene',
          params: {
            setInitialTab: true,
            stepNumber: this.step - 1
          }
        });
      }
    },
  },

  filters: {},
}
</script>

<style lang="scss" scoped>
.DashboardMentalHygiene {
}
</style>

