<template>
  <div
    class="DashboardLanguageItem"
    @click.stop="tryGotoMemolanguage()">
    <div class="header">
      <div
        class="icon"
        :style="{ backgroundImage: `url(${image})` }" />
    </div>
    <div class="body">
      <div class="spacer" />
      <div
        v-t="`memolanguage.languageLabels.${languageId}`"
        class="name" />
    </div>
  </div>
</template>

<script>
import DashboardLanguageBetaPopup from './DashboardLanguageBetaPopup';

const images = require.context('@/assets/images/', true, /\.(png|jpg)$/);

export default {
    props: {
        languageId: {
            type: String,
            required: true
        }
    },
    computed: {
        image() {
            try {
                return images(`./flags/${this.languageId}.png`);
            } catch (err) {
                return null;
            }
        }
    },
    methods: {
        tryGotoMemolanguage() {
            if (this.$store.getters['moduleAuth/canAccessOldMemolanguage']) {
                this.showBetaPopup();
            } else {
                this.gotoMemolanguage();
            }
        },
        showBetaPopup() {
            this.$modal
                .open({
                    component: DashboardLanguageBetaPopup,
                    target: 'app-modal',
                    props: {
                        languageId: this.languageId
                    }
                })
                .waitDone()
                .then(message => {
                    if (message === 'beta') {
                        this.gotoMemolanguage();
                    }
                });
        },
        gotoMemolanguage() {
            this.$router.push(`/memolanguage/${this.languageId}/home`);
        }
    }
};
</script>

<style lang="scss" scoped>
.DashboardLanguageItem {
    background-color: $defaultItemBackgroundColor;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.icon {
    width: 50%;
    min-width: 4em;
    max-width: 6em;
    margin: auto;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &::after {
        content: '';
        display: block;
        padding-top: 100%;
    }
}

.header {
    padding-top: 1em;
}

.body {
    padding: 1em 0.5em;
    padding-top: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.spacer {
    flex: 1;
    min-height: 1em;
}

.name {
    text-align: center;
    color: rgba(black, 0.9);
    font-size: 90%;
}
</style>
