<template>
  <div
    class="SuggestedVideo"
    @click="gotoVideo()">
    <div class="informations-wrapper">
      <div class="thumbnail-wrapper">
        <div class="layer">
          <div class="play-button">
            <svgicon
              icon="play-button"
              class="icon" />
          </div>
        </div>
        <img
          :src="video.thumbnail"
          alt="video">
      </div>
      <div class="text-wrapper">
        <h3>{{ videoTitle }}</h3>
        <p>{{ numberOfCompletedParts }} {{ $t('of') }} {{ numberOfParts }} {{ $t('brainDates') }}</p>
      </div>
    </div>
    <div class="button-wrapper">
      <a class="button-blue">{{ $t('play') }}</a>
    </div>
  </div>
</template>

<translations>
    of: 'of'
    of_no: 'av'
    brainDates: 'Brain Dates'
    brainDates_no: 'hjernedates'
    play: 'Play'
    play_no: 'Spill'
</translations>

<script>
import { mapGetters, mapActions } from 'vuex';
import VideoController from '../VideoController';

export default {
    props: {
        id: {
            type: String,
            required: true
        },

        lifeskillId: {
            type: String,
            required: true
        }
    },

    computed: {
        ...mapGetters({ getVideoById: 'moduleApp/getVideoById' }),

        video() {
            return this.getVideoById(this.id);
        },

        videoTitle() {
            return this.capitalize(this.video.title);
        },

        numberOfParts() {
            return this.videoController.parts.length;
        },

        numberOfCompletedParts() {
            return this.videoController.parts.filter(p => p.completed).length;
        },
    },

    async created() {
        this.videoController = new VideoController(this.video.id, this.lifeskillId);
        await this.updateVideoData(this.id);
    },

    methods: {
        ...mapActions({ updateVideoData: 'moduleApp/loadVideo' }),

        gotoVideo() {
            this.$router.push({
                name: 'DashboardVideoView',
                params: {
                    videoId: this.video.id,
                    lifeskillId: this.lifeskillId || this.video.lifeskillIds[0]
                }
            });
        },

        capitalize(string) {
            return string[0].toUpperCase() + string.slice(1).toLowerCase();
        }
    }
};
</script>

<style lang="scss" scoped>
.SuggestedVideo {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #1F2362;
    cursor: pointer;
    .informations-wrapper {
        padding-right: 1.25em;
        display: flex;
        align-items: center;
        .thumbnail-wrapper {
            min-width: 5.5em;
            min-height: 4em;
            max-width: 5.5em;
            max-height: 4em;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-right: 1.3em;

            &:hover {
                .layer {
                    visibility: visible;
                }
            }
            .layer {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                visibility: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                .play-button {
                    width: 2em;
                    height: 1.5em;
                    border-radius: 0.5em;
                    background: #5983f7;
                    opacity: 0.8;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    .icon {
                        fill: white;
                        width: 0.7em;
                        height: 0.7em;
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }
        }

        .text-wrapper {
            h3 {
                margin-top: 0.8em;
                font-size: 18px;
                font-weight: 600;
                line-height: 20px;
                color: #EEEEEE;
                margin-bottom: 0.3em;
                @media (max-width: 530px) {
                    font-size: 16px;
                    margin: 0.5em 0;
                }
            }

            p {
                margin-top: 3px;
                margin-bottom: 1.25em;
                color: #9FB4EF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                @media (max-width: 530px) {
                    font-size: 14px;
                    margin: 0.5em 0;
                    margin-bottom: 1.5em;
                }
            }
        }
    }
    .button-wrapper {
        display: flex;
        align-items: center;
        .button-blue {
            cursor: pointer;
        }
        @media (max-width: 1000px) {
            display: none;
        }
    }
}
</style>
