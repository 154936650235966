var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PlaylistCarousel"},[_c('div',{staticClass:"carousel-top-section"},[_c('div',{staticClass:"top-section-left-side"},[_c('h3',[_vm._v(_vm._s(_vm.playlist.name))]),_c('p',[_vm._v(_vm._s(_vm.playlist.videos.length + ' ' + _vm.$t('videos')))])]),_c('div',{staticClass:"top-section-right-side"},[_c('button',{staticClass:"view-all-button",on:{"click":_vm.handleViewAll}},[_vm._v(" "+_vm._s(_vm.viewAll ? _vm.$t('viewSome') : _vm.$t('viewMore'))+" ")]),(!_vm.viewAll)?_c('button',{staticClass:"direction-button",on:{"click":_vm.handleMoveCarouselRight}},[_c('svgicon',{staticClass:"carousel-icon",attrs:{"icon":"carousel-arrow-left"}})],1):_vm._e(),(!_vm.viewAll)?_c('button',{staticClass:"direction-button",on:{"click":_vm.handleMoveCarouselLeft}},[_c('svgicon',{staticClass:"carousel-icon",attrs:{"icon":"carousel-arrow-right"}})],1):_vm._e()])]),_c('div',{staticClass:"carousel"},[_c('div',{staticClass:"slider",style:({
        transform: ("translateX(" + _vm.carouselPosition + "%)"),
        flexWrap: _vm.viewAll ? 'wrap' : 'nowrap',
        transition: _vm.viewAll ? 'none' : '0.5s',
      }),on:{"mousedown":_vm.mouseDown,"mouseup":_vm.mouseUp}},_vm._l((_vm.playlist.videos),function(video){return _c('VideoItem',{key:video.id,staticClass:"video-item",style:({
          width: _vm.videoWidth,
          minWidth: _vm.videoWidth,
        }),attrs:{"video-id":video.id,"is-dragging":_vm.isDragging,"lifeskill-id":_vm.lifeskillId},on:{"clicked-video":_vm.handleClickedVideo}})}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }