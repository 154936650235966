<template>
  <div class="container">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
    width: 965px;
}
</style>
