import MediaQueryObserver from '@/MediaQueryObserver';

const itemWidth = 20; // 14
const itemGap = 2;
const pageMargin = 2;
const breakpointFor2Columns = `${itemWidth * 3 + itemGap * 2 + pageMargin * 2}em`;
const breakpointForItemScaling = `${itemWidth * 2 + itemGap * 1 + pageMargin * 2}em`;
const mediaQueryObserver = new MediaQueryObserver();

export default class GridLayoutController {
    mapping = {
        wide: {
            columns: 3,
            row: {
                maxWidth: 'calc(100% - 2em)',
                justifyContent: 'center'
            },
            heightRatio: 1.1,
            item: {
                width: `${itemWidth}em`
            },
            gap: {
                width: `${itemGap}em`,
                flexShrink: 0
            },
            detail: {
                width: `${itemWidth * 3 + itemGap * 2}em`
            }
        },
        narrow: {
            columns: 2,
            row: {
                maxWidth: 'calc(100% - 2em)',
                justifyContent: 'center'
            },
            heightRatio: 1.1,
            item: {
                width: `${itemWidth}em`
            },
            gap: {
                width: `${itemGap}em`,
                flexShrink: 0
            },
            detail: {
                width: `${itemWidth * 2 + itemGap * 1}em`
            }
        },
        narrowWithScale: {
            columns: 2,
            row: {
                maxWidth: 'calc(100% - 2em)',
                justifyContent: 'center'
            },
            heightRatio: 1.1,
            item: {
                flex: 1,
                width: '0%'
            },
            gap: {
                width: '1em',
                flexShrink: 0
            },
            detail: {
                width: 'calc(100% - 2em)'
            }
        }
    };
    constructor(layoutOptions, callback) {
        if (layoutOptions.heightRatio !== undefined) {
            this.mapping.wide.heightRatio = layoutOptions.heightRatio;
            this.mapping.narrow.heightRatio = layoutOptions.heightRatio;
            this.mapping.narrowWithScale.heightRatio = layoutOptions.heightRatio;
        }

        this._update = this.update.bind(this);
        this.callback = callback;
        mediaQueryObserver.addQuery('a', { maxWidth: breakpointFor2Columns });
        mediaQueryObserver.addQuery('b', { maxWidth: breakpointForItemScaling });
        mediaQueryObserver.addListener(this._update);

        return this.dispose.bind(this);
    }
    update(state) {
        if (state.a) {
            if (state.b) {
                this.callback(this.mapping.narrowWithScale);
            } else {
                this.callback(this.mapping.narrow);
            }
        } else {
            this.callback(this.mapping.wide);
        }
    }
    dispose() {
        mediaQueryObserver.removeListener(this._update);
    }
}
