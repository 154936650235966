<template>
  <div
    class="RelatedReading"
    :class="{ hideArrows }">
    <div
      v-for="(articleId, i) of articleIds"
      :key="`article${i}`"
      class="Article Item --global-clickable"
      @click="showArticle(articleId)">
      <div class="Article__icon">
        <svgicon
          class="articleIcon"
          icon="theory-section-icon" />
      </div>
      <div class="Item__label">
        {{ $t('memogenius.MemogeniusGameIntroView.readArticle') }}
      </div>
      <svgicon
        class="Item__arrow"
        icon="arrow-right" />
    </div>
    <div
      v-for="(systemId, i) of systemIds"
      :key="i"
      class="System Item --global-clickable"
      @click="openModal(systemId)">
      <div
        class="System__icon"
        :style="{ backgroundImage: `url(${systemIcon(systemId)})` }" />
      <div class="Item__label">
        {{ $t(`memogenius.techniques.${systemId}.title`) }}
      </div>
      <svgicon
        class="Item__arrow"
        icon="arrow-right" />
    </div>
  </div>
</template>

<script>
import MemogeniusSystemIntroPopup from '@/components/memogenius/MemogeniusSystemIntroPopup';
import MemogeniusArticlePopup from '@/components/memogenius/MemogeniusArticlePopup';
const images = require.context('@/assets/images/memogenius/system/icon/', true, /\.(png|jpg)$/);

export default {
    props: {
        gameId: {
            type: String,
            default: 'faces'
        },
        hideArrows: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        systemIds() {
            return this.$t(`memogenius.games.${this.gameId}.techniques`);
        },
        articleIds() {
            return this.$t(`memogenius.games.${this.gameId}.articles`);
        }
    },
    methods: {
        systemIcon(systemId) {
            try {
                return images(`./${systemId}.png`);
            } catch (err) {
                return null;
            }
        },
        openModal(systemId) {
            this.$modal.open({
                component: MemogeniusSystemIntroPopup,
                target: 'app-modal',
                props: {
                    systemId: systemId
                }
            });
        },
        showArticle(articleId) {
            this.$modal.open({
                component: MemogeniusArticlePopup,
                target: 'app-modal',
                props: {
                    articleId
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.RelatedReading {
    &.hideArrows {
        .Item__arrow {
            display: none;
        }
    }
}
.Item {
    display: flex;
    align-items: center;
    padding: 0.5em 0;
    &:not(:first-child) {
        border-top: 1px solid rgba(white, 0.05);
    }

    &:hover {
        .Article__icon,
        .System__icon {
            transform: scale(1.1);
        }
    }
}
.Item__label {
    flex: 1;
}
.Item__arrow {
    $size: 1em;
    width: $size;
    height: $size;
    fill: white;
}

.System__icon,
.Article__icon {
    transition: transform 0.3s;
}

.System__icon {
    $size: 2.5em;
    width: $size;
    height: $size;
    background-color: black;
    border-radius: 50%;
    margin-right: 1em;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.Article__icon {
    $size: 2.5em;
    width: $size;
    height: $size;
    margin-right: 1em;

    display: flex;
    justify-content: center;
    align-items: center;
}
.articleIcon {
    $size: 60%;
    width: $size;
    height: $size;
    fill: white;
}
</style>
