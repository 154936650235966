<template>
  <div class="MemogeniusSystemIntroPopup">
    <div class="scrollContainer">
      <div>
        <div
          class="header"
          :style="{
            backgroundImage: `linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.62) 51%, rgba(0, 0, 0, 0.95) 100%), url(${bgImage})`
          }">
          <div
            class="icon"
            :style="{ backgroundImage: `url(${icon})` }" />
          <div class="title">
            {{ $t(`memogenius.techniques.${systemId}.title`) }}
          </div>
        </div>
        <div class="body">
          <div
            v-if="!showArticleOnly"
            class="text"
            v-html="$t(`memogenius.techniques.${systemId}.intro`)" />
          <br>
          <RichTextBlock
            v-if="content"
            class="richTextBlock"
            :html="content" />
        </div>
      </div>
      <div
        v-if="!showArticleOnly"
        class="footer">
        <StandardButton
          v-if="!content"
          class="articleButton"
          :label="$t(`memogenius.MemogeniusSystemIntroView.readMore`)"
          @click="loadArticle(articleId)" />

        <StandardButton
          v-if="hasPractice"
          class="practiceButton"
          icon="arrow-right"
          :label="$t(`memogenius.MemogeniusSystemIntroView.practiceButtonLabel`)"
          @click="startPractice()" />
      </div>
    </div>
  </div>
</template>

<script>
import marked from 'marked';
import axios from 'axios';

import StandardButton from '@/components/StandardButton';
import RichTextBlock from '@/components/memogenius/RichTextBlock';

const images = require.context('@/assets/images/', true, /\.(jpg|png)$/);

export default {
    components: { StandardButton, RichTextBlock },
    props: {
        systemId: String,
        showArticleOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            content: ''
        };
    },
    computed: {
        bgImage: function () {
            return images(`./memogenius/system/bg/${this.systemId}.jpg`);
        },
        icon: function () {
            return images(`./memogenius/system/icon/${this.systemId}.png`);
        },
        hasPractice() {
            return !!this.system.system;
        },
        articleId() {
            return this.$t(`memogenius.techniques.${this.systemId}.articles`)[0];
        },
        system() {
            return this.$store.state.moduleMemogenius.systems.find(s => s.id === this.systemId);
        }
    },
    created() {
        if (this.showArticleOnly) {
            this.loadArticle(this.articleId);
        }
    },
    methods: {
        loadArticle() {
            const lang = 'no'; // this.$i18n.locale
            // if (navigationInstruction.previousInstruction && navigationInstruction.previousInstruction.config.name === 'game-intro') {
            //   this.returnInstruction = navigationInstruction.previousInstruction.fragment;
            // }

            const documentUrl = `${process.env.BASE_URL}memory-system-articles/${this.articleId}_${lang}.md`;

            axios.get(documentUrl + `?t=${Date.now()}`).then(response => {
                let titleMatch = response.data.match(/class="document-title">(.*?)<\/div>/);
                this.title = titleMatch ? titleMatch[1] : '';
                this.content = marked(response.data);
            });
        },
        startPractice() {
            this.$store.commit('moduleApp/setSystemExitTo', this.$route.path);
            let url = `/system/${this.system.system}`;
            this.$router.push(url);
            this.$emit('signal', 'done');
        }
    }
};
</script>

<style lang="scss" scoped>
$buttonColor: $memogenius-greenColor;
$buttonGap: 0.8em;

.MemogeniusSystemIntroPopup {
    overflow: hidden;
    width: 40em;

    display: flex;
    flex-direction: column;
    // justify-content: center;

    color: black; //white;

    // animation: fade-in-with-lift 0.5s ease 0.5s;
    // animation-fill-mode: backwards;

    position: relative;
    transform: translate3d(
        0,
        0,
        0
    ); // NOTE: Needed in conjunction with the sticky in order to keep border-radius clipping

    @include media('<mobile') {
        // min-height: 100%;
    }
    @include media('>=mobile') {
        // display: flex;
        // box-shadow: 0 0 1em rgba(black, 0.3);
    }
}

.scrollContainer {
    overflow-y: auto;

    flex-direction: column;
    justify-content: center;

    background-color: white; //black;//transparent;

    // position: relative;
    // overflow: hidden;
    // max-height: 100%;
    // border: 1px solid red;
}

.backButton {
    position: absolute;
    top: 0;
    left: 0;
}

.header {
    padding: 1em;
    // padding-top: 2em;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    color: white;

    // border-radius: 4px;

    position: sticky;
    top: -9em;
}
.body {
    padding: 2em;
    padding-bottom: 1em;
}
.footer {
    padding: 2em;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
}

.icon {
    $size: 5em;
    width: $size;
    height: $size;
    margin: auto;
    margin-top: 2em;
    margin-bottom: 2em;
    background-size: contain;
}
.title {
    font-size: 1.5em;
    text-align: center;
}
.text {
    line-height: 1.5em;
    font-weight: 300;
    color: #444;
    padding-top: 0;

    /deep/ p:first-child {
        margin-bottom: 1em;
        margin-top: 0;
        font-weight: 600;
        color: #333;
    }
}

.richTextBlock {
}

@include media('<mobile') {
    .body {
        padding: 1em;
    }
}
@include media('>=mobile') {
}
</style>
