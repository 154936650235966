<template>
  <div class="SkeletonPreloading">
    <div class="dummy-image-wrapper">
      <div class="dummy-image" />
    </div>
    <div class="dummy-text">
      <div class="first-dummy-paragraph" />
      <div class="second-dummy-paragraph" />
      <div class="third-dummy-paragraph" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.SkeletonPreloading {
    padding: 2em;
    display: flex;
    .dummy-image-wrapper {
        .dummy-image {
            width: 6em;
            height: 6em;
            background: #eaeaea;
        }
    }

    .dummy-text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 1.5em;
        .first-dummy-paragraph {
            background: #eaeaea;
            height: 1.5em;
            width: 100%;
        }

        .second-dummy-paragraph {
            background: #eaeaea;
            height: 1.5em;
            width: 60%;
        }

        .third-dummy-paragraph {
            background: #eaeaea;
            height: 1.5em;
            width: 30%;
        }
    }
}
</style>
