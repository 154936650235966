<template>
  <div class="DashboardLifeskillView">
    <ScrollableContainerWithStickyHeader
      ref="header"
      class="scrollContainer"
      :sticky-height="headerStickyHeight"
      @scroll="onHeaderScroll"
    >
      <div slot="header" class="header" :xstyle="{ backgroundColor: darkenedThemeColor }">
        <div ref="imageContainer" class="imageContainer">
          <div
            ref="headerImage"
            class="image"
            :style="{
              backgroundImage: `url(${backgroundImage})`,
              xbackgroundColor: theme.color,
            }"
          />
        </div>
        <div class="header-wrapper">
          <container class="container">
            <div ref="headerContentSection" class="headerContentSection">
              <LifeskillNumbershapeWithProgress
                :lifeskill-id="lifeskillId"
                :play="animateNumbershape"
                class="icon"
                @mouseover.native="animateNumbershape = true"
                @mouseleave.native="animateNumbershape = false"
              />

              <div class="headerInformationsWrapper">
                <div class="label">
                  {{ $t(`dashboard.general.lifeskillLabels.${lifeskillId}`) }}
                </div>

                <div v-if="totalLifekeys" class="lifeskillProgressText">
                  {{ percentageProgress + '% ' + $t('general.completed') + ' - ' }}
                  {{ remainingLifekeys }}
                  {{ $tc('dashboard.general.lifekey', totalLifekeys) }}
                  {{ $t('general.forDiploma') }}
                </div>
              </div>
            </div>
          </container>
        </div>

        <div ref="tabsSection" class="tabsSection">
          <div ref="tabsSectionTint" class="tabsSectionTint" />

          <container class="container">
            <div class="tabs">
              <div class="menu-wrapper">
                <div class="tabs-wrapper">
                  <div
                    v-for="tab of tabs"
                    :key="tab.name"
                    class="Tab --global-clickable"
                    :class="{ 'is-active': tab.name === activeTab.name }"
                    @click="selectPanel(tab.name)"
                  >
                    <div class="Tab__textSection">
                      <div class="Tab__label">
                        {{ $t(`tabLabels.${tab.name}`) }}
                      </div>
                      <div class="Tab__text">
                        {{ tab.text || $t('tabLabels.noLifekeys') }}

                        <svgicon
                          v-if="
                            (tab.name === 'practice' && practiceLifekeys) ||
                            (tab.name !== 'overview' && tab.name !== 'practice')
                          "
                          class="key-icon"
                          icon="keyShort"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="links-wrapper">
                  <a class="follow" @click="toggleLifeskillFollow({ lifeskillId })">{{
                    follows ? $t('tabLabels.unfollow') : $t('tabLabels.follow')
                  }}</a>
                </div>
              </div>
            </div>
          </container>
        </div>
      </div>
      <component
        :is="activeTab.component"
        slot="body"
        class="body"
        :lifeskill-id="lifeskillId"
        :tab-name="childTabName"
      />
    </ScrollableContainerWithStickyHeader>

    <DashboardTopBar ref="topbar" class="topbar" :transparent="true" theme="dark-bg" />

    <transition name="fade">
      <LifeskillLoadingRive v-if="showLoadingAnimation" :lifeskill-id="lifeskillId" class="riveAnimation" />
    </transition>
  </div>
</template>

<translations>
  tabLabels:
    courses: 'Courses'
    courses_no: 'Kurs'
    overview: 'Up next'
    overview_no: 'Neste'
    theory: 'Theory'
    theory_no: 'Teori'
    practice: 'Practice'
    practice_no: 'Praksis'
    videos: 'Videos'
    videos_no: 'Video'
    numbershape: 'Shape'
    numbershape_no: 'Tallform'
    follow: 'Follow'
    follow_no: 'Følg'
    unfollow: 'Unfollow'
    unfollow_no: 'Avfølg'
    noLifekeys: 'No life keys'
    noLifekeys_no: 'Ingen livsnøkler'
</translations>

<script>
import { TimelineLite } from 'gsap'
import Color from 'color'
import ScrollableContainerWithStickyHeader from '@/components/ScrollableContainerWithStickyHeader'
import DashboardTopBar from '../../components/DashboardTopBar'
import LifeskillNumbershapeWithProgress from './LifeskillNumbershapeWithProgress'
import CoursePanel from './CoursePanel'
import OverviewPanel from './OverviewPanel'
import GamesAndSystemsPanel from './GamesAndSystemsPanel'
import LanguagesPanel from './LanguagesPanel'
import VideosPanel from './VideosPanel'
import ScenariosPanel from './ScenariosPanel'
import CalculationGeniusPanel from './CalculationGeniusPanel'
import categoryThemes from '@/category-themes'
import Container from '@/modules/dashboard/components/Container'
import { getCategoryIdFromLifeskillId } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
import LifeskillLoadingRive from './LifeskillLoadingRive'

const images = require.context('@/assets/images/', true, /\.(png|jpg)$/)
export default {
  components: {
    DashboardTopBar,
    ScrollableContainerWithStickyHeader,
    LifeskillNumbershapeWithProgress,
    CoursePanel,
    CalculationGeniusPanel,
    GamesAndSystemsPanel,
    LanguagesPanel,
    VideosPanel,
    ScenariosPanel,
    OverviewPanel,
    Container,
    LifeskillLoadingRive,
  },

  provide() {
    return {
      theme: this.theme,
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'DashboardHomeView') {
        vm.showLoadingAnimation = true
      }
      if (from.name === 'DashboardVideoView' && to.params.tabName === 'videos') {
        const scrollToVideoId = from.params.videoId
        const videoElem = vm.$el.querySelector(`.VideoItem[data-video-id="${scrollToVideoId}"]`)
        if (videoElem) {
          videoElem.scrollIntoView(false)
        }
      }
    })
  },

  props: {
    lifeskillId: {
      type: String,
      required: true,
    },
    tabName: {
      type: String,
      default: '',
    },
    childTabName: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      theme: Object.assign({}, categoryThemes[getCategoryIdFromLifeskillId(this.lifeskillId)]),
      animateNumbershape: false,
      isMobile: false,
      showLoadingAnimation: false,
    }
  },

  computed: {
    ...mapGetters({
      getLifeskillIdByNumbershape: 'moduleApp/getLifeskillIdByNumbershape',
    }),
    follows() {
      return this.$store.getters['moduleApp/follows'](this.lifeskillId)
    },

    lifekeys() {
      return this.$store.getters['moduleApp/lifekeys'].filter(lk => lk.lifeskill === this.lifeskillId)
    },
    acquiredTheoryLifekeys() {
      return Math.min(
        this.lifekeys.filter(lk => lk.context.startsWith('theory-journey')).length,
        this.stats.totalTheoryLifekeys
      )
    },
    acquiredPracticeLifekeys() {
      return Math.min(
        this.lifekeys.filter(lk => lk.context.startsWith('habit-journey')).length,
        this.stats.totalPracticeLifekeys
      )
    },
    acquiredVideoLifekeys() {
      return Math.min(
        this.lifekeys.filter(lk => lk.context.indexOf('video') > -1).length,
        this.stats.totalVideoLifekeys
      )
    },
    percentageProgress() {
      return Math.round(this.stats.progress * 100)
    },
    practiceLifekeys() {
      return this.stats.totalPracticeLifekeys
    },
    backgroundImage() {
      try {
        return images(`./lifeskill-backgrounds-large/${this.lifeskillId}.jpg`)
      } catch (err) {
        return null
      }
    },
    tabs() {
      const theoryLifekeys = this.stats.totalTheoryLifekeys
      const videoLifeKeys = this.stats.totalVideoLifekeys
      const lifeskillExceptionsIds = ['02', '03', '11']
      const tabs = []
      if (this.lifeskill.masterCourseId || this.lifeskill.isEssential) {
        tabs.push(
          {
            name: 'overview',
            component: 'OverviewPanel',
            text: this.$t('dashboard.DashboardCourseDetails.whatsUp'),
          },
          {
            name: 'theory',
            component: 'CoursePanel',
            text: theoryLifekeys ? this.acquiredTheoryLifekeys + '/' + theoryLifekeys : '',
          }
        )
      }
      if (lifeskillExceptionsIds.includes(this.lifeskillId)) {
        const component = (id => {
          switch (id) {
            case '02':
              return 'GamesAndSystemsPanel'
            case '03':
              return 'CalculationGeniusPanel'
            case '11':
              return 'LanguagesPanel'
            default:
              break
          }
        })(this.lifeskillId)
        tabs.push({
          name: 'practice',
          component,
          icon: 'dumbbells',
          text: this.practiceLifekeys ? this.acquiredPracticeLifekeys + '/' + this.practiceLifekeys : '',
        })
      } else {
        if (this.lifeskill.masterCourseId) {
          const course = this.$store.state.moduleCourse.courses[this.lifeskill.masterCourseId]
          if (course && course.totalNumberOfScenarios) {
            tabs.push({
              name: 'practice',
              component: 'ScenariosPanel',
              icon: 'dumbbells',
              text: this.practiceLifekeys ? this.acquiredPracticeLifekeys + '/' + this.practiceLifekeys : '',
            })
          }
        }
      }
      if (this.lifeskill.numberOfRelatedVideos) {
        tabs.push({
          name: 'videos',
          component: 'VideosPanel',
          icon: 'video2',
          text: videoLifeKeys ? this.acquiredVideoLifekeys + '/' + videoLifeKeys : '',
        })
      }
      if (tabs.length === 0) {
        // Will never be empty from now because of overview tab
        tabs.push({
          name: 'theory',
          component: 'CoursePanel',
          icon: 'book',
          text: theoryLifekeys
            ? this.$tc('dashboard.DashboardCourseDetails.lifekey', theoryLifekeys, {
                count: theoryLifekeys,
              })
            : '',
        })
      }

      return tabs
    },

    activeTab() {
      // NOTE: because of asynchronicity, this.tabName might be invalid at this point, so we fallback to first tab
      return this.tabs.find(tab => tab.name === this.tabName) || this.tabs[0]
    },

    darkenedThemeColor() {
      return Color(this.theme.color).darken(0.1)
    },

    lifeskill() {
      return this.$store.state.moduleApp.lifeskills[this.lifeskillId]
    },

    imageUrl() {
      return require('../../../../assets/images/key.png')
    },

    stats() {
      return this.$store.getters['moduleApp/getLifeskillStats'](this.lifeskillId)
    },

    aquiredLifekeys() {
      return this.stats.acquiredLifekeys
    },

    totalLifekeys() {
      return this.stats.totalLifekeys
    },

    remainingLifekeys() {
      return this.totalLifekeys - this.aquiredLifekeys
    },
  },

  watch: {
    lifeskillId() {
      Object.assign(this.theme, categoryThemes[getCategoryIdFromLifeskillId(this.lifeskillId)])
      if (this.$el) {
        this.$nextTick(() => {
          this.$refs.header.handleResize()
          this.setupAnimation()
        })
      }
    },
    tabName: {
      handler(tabName) {
        if (!this.tabs.find(tab => tab.name === tabName)) {
          if (this.tabs.length === 2) {
            this.selectPanel(this.tabs[1].name)
          } else {
            this.selectPanel(this.tabs[0].name)
          }
        }
      },
      immediate: true,
    },
  },

  async mounted() {
    this.fadeOutLoadingAnimation()

    await this.loadVideos()

    this.setIsMobile()

    window.addEventListener('resize', this.setIsMobile)
    this.setupAnimation()
    if (this.tempScrollFraction !== undefined) {
      this.onHeaderScroll(this.tempScrollFraction)
      delete this.tempScrollFraction
    }
    console.log(this.acquiredPracticeLifekeys, ' acquiredPracticeLifekeys')
  },

  beforeDestroy() {
    this.tl.kill()
  },

  methods: {
    fadeOutLoadingAnimation() {
      setTimeout(() => {
        this.showLoadingAnimation = false
      }, 2600)
    },
    ...mapActions({
      loadVideosForLifeskill: 'moduleApp/loadVideosForLifeskill',
      loadVideos: 'moduleApp/loadAllVideos',
      toggleLifeskillFollow: 'moduleApp/toggleLifeskillFollow',
    }),
    selectPanel(tabId) {
      if (tabId === this.tabName) {
        return
      }
      this.$router.replace({
        name: 'DashboardLifeskillViewWithTab',
        params: { lifeskillId: this.lifeskillId, tabName: tabId },
      })
    },
    headerStickyHeight() {
      if (this.$refs.topbar && this.$refs.tabsSection) {
        return this.$refs.topbar.$el.clientHeight + this.$refs.tabsSection.clientHeight
      } else {
        return 0
      }
    },
    onHeaderScroll(fraction) {
      if (this.tl) {
        this.tl.time(fraction)
      } else {
        this.tempScrollFraction = fraction
      }
    },
    setupAnimation() {
      if (this.tl) {
        this.tl.kill()
      }
      let tl = new TimelineLite()
      tl.pause()
      tl.seek(0)
      tl.staggerFromTo(
        this.$refs.headerContentSection.children,
        0.5,
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
        0.1
      )
      this.tl = tl
    },
    setIsMobile() {
      const screenWith = window.screen.width

      if (screenWith < 774) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$headerRow3-height: $topBarHeight;
$activePanelBackgroundColor: mix(black, white, 3%);
.header-wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
}
.DashboardLifeskillView {
  background: linear-gradient(180deg, #0a1335 0%, #1e1456 93.87%);
  overflow-y: auto;
}

.key-icon {
  width: 1.1em;
  height: 1.1em;
  fill: gold;
  margin-left: 0.3em;
  transform: rotate(90deg);
}

.scrollContainer {
  height: 100%;
}
.header {
  background-color: black;
}
.headerContentSection {
  position: relative;
  padding-top: 0;
  display: flex;

  @media (max-width: 885px) {
    justify-content: center;
  }
  @media (max-width: 774px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 885px) and (orientation: landscape) {
    display: none;
  }
}
.headerInformationsWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
  @media (max-width: 774px) {
    align-items: center;
    margin-left: 0;
  }
}
.icon {
  $size: 7em;
  width: $size;
  height: $size;
  margin-top: 2em;
  margin-bottom: 2em;
  @media (max-width: 774px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
    margin-top: 1.2em;
  }
}
.tag {
  margin-bottom: 1em;
}
.label {
  color: rgba(white, 0.9);
  font-size: 48px;
  margin: 10px 0 5px;
  text-align: left;
  @media (max-width: 774px) {
    text-align: center;
  }
  @media (max-width: 610px) {
    font-size: 24px;
  }
  @media (max-width: 350px) {
    font-size: 22px;
  }
}
.lifeskillProgressText {
  color: rgba(white, 0.8);
  font-size: 16px;
  margin-left: 3px;
  @media (max-width: 774px) {
    text-align: center;
  }
  @media (max-width: 610px) {
    font-size: 14px;
  }
  @media (max-width: 350px) {
    font-size: 12px;
  }
}
.imageContainer {
  position: absolute;
  top: -2em; // NOTE: Make image extend out of page. Looks nicer with Safari elastic over-scroll
  right: 0;
  bottom: 7.4em;
  left: 0;
  overflow: hidden;
  background-color: black;

  @media (max-width: 530px) {
    bottom: 90px;
  }
}
.container {
  margin: 0 3vw;
}
.image {
  position: absolute;
  $offset: 0; // 15px; // NOTE: 15px needed here if we blur the image
  top: -$offset;
  right: -$offset;
  bottom: -$offset;
  left: -$offset;
  background-position: center;
  background-size: cover;
  background-color: rgb(9, 3, 49);
  filter: brightness(0.5);
  transform: scale(1.1);
}
.image--blurred {
  filter: blur(4px) brightness(0.5);
}
.tabsSection {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-top: 0.5px solid rgba(100, 198, 255, 0.2);
  border-bottom: 0.5px solid rgba(100, 198, 255, 0.2);
  // background-color: rgba(black, 0.2);
  display: flex;
  justify-content: center;
  height: 111px;
  background: #17144a;
  @media (max-width: 530px) {
    height: 90px;
  }
}
.tabsSectionTint {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  // background-color based on category
}
.tabs {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.menu-wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  @media (max-width: 885px) {
    justify-content: center;
    width: auto;
  }
  @media (max-width: 530px) {
    width: 100%;
  }
  .tabs-wrapper {
    display: flex;
    @media (max-width: 530px) {
      width: 100%;
    }
  }
  .links-wrapper {
    display: flex;
    align-items: center;
    min-width: 112px;
    justify-content: center;
    @media (max-width: 885px) {
      display: none;
    }
    .follow {
      text-decoration: none;
      font-size: 16px;
      line-height: 22px;
      color: #bcbcbc;
      cursor: pointer;
    }
    .arrow-button {
      width: 34px;
      height: 34px;
      background: rgba(216, 216, 216, 0.16);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: $purple;
      }

      img {
        margin-left: 1px; // centering
      }
    }
  }
}

.Tab {
  position: relative;
  min-width: 112px;
  color: rgba(white, 0.9);
  line-height: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-of-type {
    // justify-content: flex-start;
  }

  @media (max-width: 530px) {
    min-width: 25%;
  }

  &.is-active {
    background: rgba(60, 184, 255, 0.05);
    box-shadow: 0px 4px 5px -3px rgba(1, 119, 255, 1);
  }
}
.Tab__icon {
  $size: 1.5em;
  width: $size;
  height: $size;
  fill: white;
  margin-right: 1em;
}
.Tab__textSection {
  font-size: 16px;
  text-align: left;
  padding: 0 8px;
  @media (max-width: 530px) {
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }

  img {
    margin-right: 3px;
  }
}
.Tab__label {
  text-transform: capitalize;
  line-height: 22px;
}
.Tab__text {
  color: rgba(226, 226, 226, 0.5);
  line-height: 22px;
  display: flex;
  align-items: center;

  @media (max-width: 530px) {
    justify-content: center;
  }
}
.body {
  min-height: calc(100vh - #{$headerRow3-height + $topBarHeight});
  min-height: calc(var(--vh, 1vh) * 100 - #{$headerRow3-height + $topBarHeight});
}
.DashboardLifeskillView .topbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.riveAnimation {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to left, #0a1335, #1e1456);
  width: 100vw;
  height: 100vh;
  z-index: 100000;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
