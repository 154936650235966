<template>
  <div class="PanelHeader">
    <DashboardWidthWrapper>
      <div class="title">
        <slot name="title" />
      </div>
      <div class="text">
        <slot name="text" />
      </div>
      <slot />
    </DashboardWidthWrapper>
  </div>
</template>

<script>
import DashboardWidthWrapper from '../../components/DashboardWidthWrapper';

export default {
    components: {
        DashboardWidthWrapper
    }
};
</script>

<style lang="scss" scoped>
.PanelHeader {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2em;
}
.title {
    font-size: 160%;
    color: rgba(black, 0.8);
}
.text {
    line-height: 1.5em;
    margin-top: 1em;
    max-width: 40em;
}
</style>
