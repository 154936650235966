<template>
  <div
    class="DashboardTopBar"
    :class="{ isTransparent: transparent, canUpgrade: canShowUpgrade && canUpgrade, disabled }">
    <MemolifeLogo
      class="--global-clickable"
      :alwaysShowHeart="true"
      @click.native="navigateToHome()" />
      
    <div class="spacer" />
    <UpgradeButton
      v-if="canShowUpgrade && canUpgrade"
      in-header />

    <DashboardMentalHygiene />

    <DashboardTopBarDailyGoal class="dailyGoal" />
    <DashboardTopBarNotifications class="notifications" />

    <Avatar 
      v-if="profile"
      ref="avatar"
      class="profile --global-clickable"
      @click.native="showProfileSidebar()"
      :profile="true" 
      :dashboard-header="true" />
    

    <UpgradeBar v-if="canShowUpgrade && canUpgrade" />
  </div>
</template>

<translations>
  back: 'Back'
  back_no: 'Tilbake'
</translations>

<script>
import MemolifeLogo from '@/components/MemolifeLogo';
import Avatar from '@/components/Avatar';
import UpgradeButton from './UpgradeButton';
import DashboardAccountPopup from './DashboardAccountPopup';
import DashboardTopBarNotifications from './DashboardTopBarNotifications';
import DashboardTopBarDailyGoal from './DashboardTopBarDailyGoal';
import UpgradeBar from './UpgradeBar';
import DashboardMentalHygiene from './DashboardMentalHygiene';

export default {
    components: {
        MemolifeLogo,
        DashboardTopBarNotifications,
        DashboardTopBarDailyGoal,
        UpgradeButton,
        UpgradeBar,
        Avatar,
        DashboardMentalHygiene,
    },
    props: {
        transparent: {
            type: Boolean,
            default: false
        },
        canShowUpgrade: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        profile() {
            return this.$store.state.moduleAuth.profile;
        },
        canUpgrade() {
            return this.$can('upgrade', { modelName: 'Account' });
        },
        disabled() {
            return this.$store.getters['moduleApp/gettingStartedHasFocus'] && this.$route.name === 'DashboardHomeView';
        },
        showElement() {
            return this.$route.name === 'DashboardHomeView';
        }
    },
    methods: {
        navigateToHome() {
          console.log("navigateToHome");
          if (this.$route.name === 'DashboardHomeView')
            return;
          this.$router.push({
            name: 'DashboardHomeView'
          });
        },
        showProfileSidebar() {
            this.$modal.open({
                component: DashboardAccountPopup,
                target: 'app-dropdown',
                hostOptions: {
                    pointAt: this.$refs.avatar.$el
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.DashboardTopBar {
    position: relative;
    height: $topBarHeight;
    display: flex;
    align-items: center;
    padding: 0 2em;

    &.disabled::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(#12254d, 0.8);
    }

    .mobile-back-button {
        color: white;
        text-decoration: none;
        font-size: 14px;
        width: 64px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background: rgba(196, 196, 196, 0.15);
        cursor: pointer;
        @media (min-width: 530px) {
            display: none;
        }
    }
}

.tab {
    color: rgba(white, 0.9);
}

.divider {
    width: 1px;
    height: 1.5em;
    background-color: rgba(white, 0.2);
    margin: 0 1em;
}
.spacer {
    flex: 1;
}

.UpgradeButton {
    margin: 0 0.5em;
}

.profile {
    margin: 0 1em;
    margin-left: 0.5em;
    margin-right: 0;
    flex-shrink: 0;
}

.hamburger {
    padding: 1em;
    padding-left: 0;
}
.hamburgerIcon {
    $size: 1em;
    width: $size;
    height: $size;
    display: block;
    fill: rgba(white, 0.8);
}

.upgradeBar {
    height: 2em;
    background-color: $standardButtonColor;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.upgradeBarLabel {
    font-size: 90%;
    text-transform: uppercase;
}

@include media('>=mobile') {
    .upgradeBar {
        display: none;
    }
}
@include media('<mobile') {
    .DashboardTopBar {
        padding: 0 1em;

        &.canUpgrade {
            padding-top: 2em;
            height: $topBarHeight + 2em;
        }
        &:not(.canUpgrade) {
            .upgradeBar {
                display: none;
            }
        }
    }
    .UpgradeButton {
        display: none;
    }
    .MemolifeLogo /deep/ .text {
        display: none;
    }
}
</style>
