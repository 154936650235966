<template>
  <div class="MemogeniusArticlePopup">
    <div class="scrollContainer">
      <div>
        <div class="header">
          <!-- <div class="header" :style="{backgroundImage: `url(${bgImage})`}"> -->

          <!-- <div class="icon" :style="{backgroundImage: `url(${icon})`}"></div> -->
          <!-- <div class="title">{{ $t(`memogenius.techniques.${systemId}.title`) }}</div> -->
        </div>

        <!-- <div if.bind="system" class="banner" :style="{backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(${bannerImage})`}">
          <div class="bannerContent">
            <div class="icon" :style="{backgroundImage: `url(${icon})`}"></div>
            <div class="title">{{ systemTitle }}</div>
          </div>
        </div> -->
        <div class="body">
          <RichTextBlock
            v-if="content"
            class="text"
            :html="content" />
        </div>
        <!-- <div class="footer">
          <div class="closeButtonAtEnd" click.delegate="back()">Close</div>
        </div> -->
        <!-- <div class="closeButton" @click="back()">
          <svgicon class="svg-icon" icon="close"></svgicon>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import marked from 'marked';
import axios from 'axios';
import RichTextBlock from '@/components/memogenius/RichTextBlock';

// const images = require.context('@/assets/images/', true, /\.(jpg|png)$/)

export default {
    components: { RichTextBlock },
    props: {
        // systemId: {
        //   type: String
        // },
        articleId: {
            type: String
        }
    },
    data() {
        return {
            content: '',
            system: null
        };
    },
    computed: {
        // bannerImage: function () {
        //   return images(`./memogenius/system/bg/${this.systemId}.jpg`)
        // },
        // icon: function () {
        //   return images(`./memogenius/system/icon/${this.systemId}.png`)
        // }
    },
    created() {
        // this.systemId = this.$t(`memogenius.articlesMetadata.${this.articleId}.systemId`)
        // this.systemTitle = this.$t(`memogenius.techniques.${this.systemId}.title`)

        const lang = 'no'; // this.$i18n.locale
        // if (navigationInstruction.previousInstruction && navigationInstruction.previousInstruction.config.name === 'game-intro') {
        //   this.returnInstruction = navigationInstruction.previousInstruction.fragment;
        // }

        const documentUrl = `${process.env.BASE_URL}memory-system-articles/${this.articleId}_${lang}.md`;

        axios.get(documentUrl + `?t=${Date.now()}`).then(response => {
            let titleMatch = response.data.match(/class="document-title">(.*?)<\/div>/);
            this.title = titleMatch ? titleMatch[1] : '';
            this.content = marked(response.data);
        });
    },
    methods: {
        back() {
            // if (this.returnInstruction) {
            //   this.router.navigate(this.returnInstruction)
            // } else {
            //   this.router.navigateToRoute('games')
            // }
        }
    }
};
</script>

<style lang="scss" scoped>
$buttonColor: $memogenius-greenColor;

.MemogeniusArticlePopup {
    width: 40em;
    position: relative;

    display: flex;
    flex-direction: column;

    color: black;

    // animation: fade-in-with-lift 0.5s ease 0.5s;
    // animation-fill-mode: backwards;

    overflow: hidden;
    transform: translate3d(
        0,
        0,
        0
    ); // NOTE: Needed in conjunction with the sticky in order to keep border-radius clipping
}

.scrollContainer {
    overflow-y: auto;

    flex-direction: column;
    justify-content: center;

    background-color: white;
}

.closeButton {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1em;
    // cursor: pointer;

    background-color: rgba(black, 0.2);

    .svg-icon {
        $size: 0.8em;
        display: block;
        width: $size;
        height: $size;
        fill: white;
    }
}

.header {
    padding: 1em;
    padding-top: 2em;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    background-color: gray;
    color: white;

    // border-radius: 4px;

    position: sticky;
    top: 0; //-7em;
}

.title {
    color: white;
    font-size: 80%;
    font-weight: 300;
}
.body {
    padding: 2em;
}
.text {
}
.footer {
    padding: 1em 2em;
}
</style>
