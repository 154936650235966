<template>
  <div
    class="ScenarioItem"
    :class="{ collapsed }">
    <template v-if="!hideName">
      <div
        class="title header --global-clickable"
        @click.stop="collapsed = !collapsed">
        <JourneyIcon
          class="journeyIcon"
          type="practice"
          :label="`${journey.index + 1}`" />

        <div class="headerText">
          <div class="headerTitle">
            {{ journey.name || alternativeScenarioTitle }}
          </div>
          <p
            v-if="journeyCompleted && journeyLocked"
            class="optionally-paragraph">
            {{ $t('completedInRelatedLifeskill') }}
          </p>
          <p
            v-else-if="journeyIsLockedButHasProgress"
            class="optionally-paragraph">
            {{ $t('lockedWithProgress') }}
          </p>
          <p v-if="journeyLocked && !journeyCompleted">
            {{ $t('completeJourney') }}
          </p>

          <div
            v-if="!(journeyCompleted && journeyLocked)"
            class="text">
            <span>
              {{ $t('journeyProgress', { percent: percentageProgress }) }}
            </span>
          </div>
          <div class="progress-bar-wrapper">
            <div
              v-if="percentageProgress !== 0 && percentageProgress !== 100"
              class="progress-bar"
              :style="{ width: percentageProgress + '%' }" />
          </div>
        </div>

        <div
          class="buttonToggleCollapse">
          <svgicon
            class="buttonToggleCollapseIcon"
            icon="chevron-down" />
        </div>
      </div>
    </template>

    <TransitionExpandCollapse>
      <div
        v-if="!collapsed"
        class="paddingBox">
        <PracticeVerticalTimeline
          :items="items"
          :content-overflow-hidden="false"
          :course-id="courseId"
          :journey-locked="journeyLocked">
          <template
            slot="bullet"
            slot-scope="props">
            <StepBullet
              :current="stepIsCurrent(props.item)"
              :progress="stepProgress(props.item)"
              icon="steps-3" />
          </template>
        </PracticeVerticalTimeline>
      </div>
    </TransitionExpandCollapse>
    <div
      class="claimLifekeyButton"
      v-if="journeyCompleted && !lifekeyAwarded"
      @click="claimLifekey()">
      {{ $t('claimLifekey') }}
      <svgicon
        icon="keyShort"
        class="icon" />
    </div>
  </div>
</template>

<translations>
  journeyProgress: '{percent}% completed'
  journeyProgress_no: 'Du har fullført {percent}% av reisen'
  journeyCompleted: Completed
  journeyCompleted_no: Fullført
  completedInRelatedLifeskill: "You've completed 100% of the journey (in a related skill)"
  completedInRelatedLifeskill_no: Du har fullført 100% av ferdigheten (i en relatert ferdighet)
  completeJourney: Complete theory journey first
  completeJourney_no: Fullfør teorireisen først
  lockedWithProgress: Started in related lifeskill
  lockedWithProgress_no: Startet i relatert ferdighet
  scenarioJourney: Scenario Journey
  scenarioJourney_no: Scenario Reise
  show: Show
  show_no: Vis
  locked: Locked
  locked_no: Låst
  claimLifekey: Click to get
  claimLifekey_no: Klikk og få
</translations>

<script>
import { mapGetters } from 'vuex';
import PracticeVerticalTimeline from '@/components/PracticeVerticalTimeline';
import TransitionExpandCollapse from '@/components/TransitionExpandCollapse';

import StepBullet from './StepBullet';
import JourneyIcon from './JourneyIcon';

export default {
    components: {
        TransitionExpandCollapse,
        PracticeVerticalTimeline,
        StepBullet,
        JourneyIcon
    },

    inject: ['theme'],

    props: {
        useEllipsis: {
            type: Boolean,
            default: false
        },
        hideActivities: {
            type: Boolean,
            default: false
        },
        hideName: {
            type: Boolean,
            default: false
        },
        journey: {
            type: Object,
            required: true
        },
        courseId: {
            type: String,
            required: true
        },
        expand: {
          type: Boolean,
          default: true,
        },
    },

    data() {
      return {
        collapsed: !this.expand,
      }
    },

    methods: {
      stepIsCurrent(step) {
        const index = this.journey.steps.findIndex(x => x.id === step.id);
        const previousStep = this.journey.steps[index-1];
        if (this.stepIsDone(step)) {
          return false;
        }
        if (!previousStep || this.stepIsDone(previousStep)) {
          return true;
        }
        return false;
      },

      stepIsDone(step) {
        return (step.scenarios || []).every(s => this.scenarioIsDone(s.id));
      },

      scenarioIsDone(scenarioId) {
        return this.$store.getters['scenario/isDone'](scenarioId);
      },

      stepProgress(step) {
        const done = (step.scenarios || []).filter(s => this.scenarioIsDone(s.id)).length;
        const max = (step.scenarios || []).length;
        return max / done;
      },

      claimLifekey() {
        this.$store.dispatch('moduleApp/awardLifekey', {
          lifeskill: this.lifeskill,
          context: this.lifekeyContext,
        }, { root: true });
      },
    },

    computed: {
        ...mapGetters({
            getJourneyById: 'moduleCourse/getJourneyById',
            lifekeys: 'moduleApp/lifekeys',
        }),

        lifeskill() {
          const c = this.$store.state.moduleCourse.courses[this.courseId];
          return c.lifeskillNumber;
        },

        journeyLocked() {
          const j = this.getJourneyById(this.journey.id);
          const locked =  j.quiz.done === 0;
          return locked;
        },

        journeyIsLockedButHasProgress() {
          return this.journeyLocked && this.percentageProgress > 0;
        },

        journeyCompleted() {
            return this.percentageProgress === 100;
        },

        lifekeyContext() {
          return `habit-journey:${this.journey.id}`;
        },

        lifekeyAwarded() {
          const context = `habit-journey:${this.journey.id}`;
          return this.$store.getters['moduleApp/hasAwardedLifekey'](this.lifeskill, context);
        },

        alternativeScenarioTitle() {
            return `${this.$t('scenarioJourney')} ${this.journey.index + 1}`;
        },

        percentageProgress() {
          const total = this.scenarios.length;
          const done = this.done.length;
          return Math.floor((done / total) * 100);
        },

        scenarios() {
          return this.items
            .map(s => s.scenarios)
            .flat();
        },

        done() {
          return this.scenarios
            .filter(s => this.scenarioIsDone(s.id));
        },

        items() {
          return this.journey.steps
            .filter(s => s.scenarios);
        },

        completed() {
            return this.percentageProgress === 100;
        }
    }
};
</script>

<style lang="scss" scoped>
$bulletColumnWidth: 4em;
$bulletColumnRightMargin: 2em;

.ScenarioItem {
    position: relative;
    background-color: #1A1E54;
    box-shadow: 0px 2px 23px 3px rgba(79, 79, 79, 0.1);
    padding: 1.2em 0;
    border-radius: 4px;
    .title {
        padding: 0 18.5px;
        width: 100%;
        @media (max-width: 530px) {
            padding: 0 8px;
        }
    }

    &.collapsed {
        .buttonToggleCollapseIcon {
            transform: rotate(0deg);
        }
    }

    &:not(.collapsed) {
        .buttonToggleCollapse {
            border: 1px solid $purple;
        }
    }

    /deep/ .Step {
        .bullet {
            width: 95px;
            margin-right: $bulletColumnRightMargin;
            @media (max-width: 530px) {
                width: 4em;
            }
        }
    }
}

.header {
    display: flex;
    align-items: center;
}

.headerText {
    flex: 1;
    padding-right: 10px;
    p {
        font-size: 16px;
        line-height: 22px;
        color: #9FB4EF;
        margin-top: 5px;
        margin-bottom: 10px;
        @media (max-width: 530px) {
            font-size: 14px;
        }
    }
    .optionally-paragraph {
        margin-top: 10px;
        margin-bottom: 10px;
        color: #9FB4EF;
    }
}

.headerTitle {
    font-size: 140%;
    font-weight: 600;
    margin-top: 15px;
    color: #EEEEEE;
    @media (max-width: 530px) {
        font-size: 16px;
    }
}

.journeyIcon {
    width: 96px;
    height: 96px;
    margin-right: $bulletColumnRightMargin;
    @media (max-width: 530px) {
        width: 66px;
        height: 66px;
    }
}

.buttonToggleCollapse {
    width: 35px;
    height: 35px;
    background-color: rgba(black, 0.03);
    border: 1px solid rgba(black, 0.02);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        border: 1px solid $purple;
    }
    @media (max-width: 1000px) {
        width: 25px;
        height: 25px;
    }
}

.buttonToggleCollapseIcon {
    width: 12px;
    height: 12px;
    fill: $completionColor;
    transition: transform 0.5s;
    transform: rotate(-180deg);
    @media (max-width: 1000px) {
        width: 7px;
        height: 7px;
    }
}

.progress-bar-wrapper {
    margin: 7.5px 0;
    background: #eaeaea;
    .progress-bar {
        width: 0;
        height: 3px;
        background-color: #5983f7;
        border-top-left-radius: 50% 50px;
        border-bottom-left-radius: 50% 50px;
        border-top-right-radius: 50% 50px;
        border-bottom-right-radius: 50% 50px;
    }
}

.label {
    font-weight: 600;
}
.text {
    font-size: 16px;
    line-height: 22px;
    color: #9fb4ef91;
    margin-top: 10px;
    @media (max-width: 530px) {
        font-size: 14px;
    }
}

.paddingBox {
    // NOTE: needed to avoid cutting shadow while collapsing
    padding: 0 18.5px;
    @media (max-width: 530px) {
        padding: 0 8px;
    }
}

.claimLifekeyButton {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: -1em;
  right: 0;
  // width: 6em;
  padding: 0.6em 1em;
  margin-top: 1em;
  border-radius: 0 0 0 0.3em;
  background-color: #2351CE;
  color: white;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: #2e5bd8;
  }

  .icon {
    fill: #ffaf00;
        min-width: 17px;
        min-height: 17px;
        max-width: 17px;
        max-height: 17px;
        margin-left: 7px;
  }

  @media (max-width: 530px) {
      // width: 8em;
      padding: 0.5em 1em;
      font-size: 0.8em;

      .icon {
        fill: #ffaf00;
            min-width: 15px;
            min-height: 15px;
            max-width: 15px;
            max-height: 15px;
            margin-left: 5px;
      }
  }
}
</style>
