<template>
  <div class="DashboardWidthWrapper">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.DashboardWidthWrapper {
    margin: auto;

    // ------------------------------------------------
    // 3 column layout
    // ------------------------------------------------

    @include media('>=#{$breakpointFor2Columns}') {
        max-width: $contentWidthFor3Columns;
    }

    // ------------------------------------------------
    // 2 column layout
    // ------------------------------------------------

    @include media('<#{$breakpointFor2Columns}') {
        max-width: $contentWidthFor2Columns;
    }

    // ------------------------------------------------
    // 2 column layout with scaling
    //------------------------------------------------

    @include media('<#{$breakpointForItemScaling}') {
        $gap: 1em;
        max-width: calc(100% - #{$gap * 2});
    }
}
</style>
