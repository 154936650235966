<template>
  <div class="SuggestedVideos">
    <div class="first-part-wrapper">
      <div class="image-container">
        <div class="image-wrapper">
          <svgicon
            icon="videos_icon"
            :original="true"
            class="icon" />
        </div>
        <div class="title-refresh-wrapper-mobile">
          <div class="title-wrapper-mobile">
            <p class="mobile-title">
              {{titleInfo}}
            </p>
            <!-- <p>{{ $t('refresh') }}</p> -->
          </div>
          <div class="refresh-wrapper-mobile">
            <button @click="shuffleVideos()">
              <img
                src="@/assets/images/refresh.png"
                alt="refresh">
            </button>
          </div>
        </div>
      </div>

      <div class="suggested-videos-wrapper">
        <div class="title-refresh-wrapper">
          <div class="title-wrapper">
            <p>{{titleInfo}}</p>
          </div>
          <div class="refresh-wrapper">
            <button @click="shuffleVideos()">
              <img
                src="@/assets/images/refresh.png"
                alt="refresh">
            </button>
          </div>
        </div>
        <template v-if="loading">
          <Skeleton
            v-for="n of suggestedVideosCount"
            :key="n" />
        </template>
        <template v-else>
          <SuggestedVideo
            v-for="video in randomVideos"
            :id="video.id"
            :key="video.id"
            :lifeskill-id="lifeskillId" />
        </template>
      </div>
    </div>
    <div class="second-part-wrapper">
      <p>{{ braindatesForNextLifekey }} {{ $t('footerText') }}</p>
    </div>
  </div>
</template>

<translations>
    title: 'Journey {currentJourneyNumber} - Brain date {acquiredVideoBraindates}/50'
    title_no: 'Reise {currentJourneyNumber} - Hjernedate {acquiredVideoBraindates}/50'
    refresh: Refresh videos
    refresh_no: Vis nye videoer
    footerText: 'braindates to next life key'
    footerText_no: 'hjernedates til neste livsnøkkel'
</translations>

<script>
import SuggestedVideo from './SuggestedVideo';
import Skeleton from './Skeleton';
import { mapGetters, mapActions } from 'vuex';
import { take, shuffle } from 'lodash';

export default {
    components: {
        SuggestedVideo,
        Skeleton
    },
    props: {
        lifeskillId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            suggestedVideosCount: 3,
            randomVideos: [],
            loading: true
        };
    },

    computed: {
        ...mapGetters({
            getAcquiredVideoBraindates: 'moduleApp/getVideoBraindatesMap',
            getLifeskillStats: 'moduleApp/getLifeskillStats'
        }),


        lifeskillStats(){
            return this.getLifeskillStats(this.lifeskillId);
        },

        titleInfo(){
            const { acquiredVideoBraindates, acquiredVideoLifekeys, totalVideoLifekeys } = this.lifeskillStats;
            let currentJourneyNumber = 0;
            if(acquiredVideoLifekeys!=totalVideoLifekeys){
                currentJourneyNumber = acquiredVideoLifekeys + 1;
            } else {
                currentJourneyNumber = totalVideoLifekeys;
            }
            return this.$t('title', {
                currentJourneyNumber,
                acquiredVideoBraindates
            });
        },


        braindatesForNextLifekey() {
            let braindatesToAquireLifekey = 25;
            
            const {acquiredVideoBraindates, acquiredVideoLifekeys, totalVideoLifekeys } = this.lifeskillStats;

            if (acquiredVideoLifekeys === totalVideoLifekeys) {
                return;
            }

            if (braindatesToAquireLifekey <= acquiredVideoBraindates) {
                braindatesToAquireLifekey += 25;
            }

            return braindatesToAquireLifekey - acquiredVideoBraindates;
        },

        lifeskillVideos() {
          return this.$store.getters['moduleApp/getVideosForLifeskill'](this.lifeskillId);
        }
    },

    async mounted() {
      await this.loadVideos();
      this.shuffleVideos();
      this.loading = false;
    },

    methods: {
        ...mapActions({ loadVideos: 'moduleApp/loadAllVideos' }),
        shuffleVideos() {
          this.randomVideos = take(shuffle(this.lifeskillVideos), this.suggestedVideosCount);
        }
    }
};
</script>

<style lang="scss" scoped>
.SuggestedVideos {
    display: flex;
    position: relative;
    flex-direction: column;
    background: #1A1E54;
    box-shadow: 0px 2px 23px 3px rgba(79, 79, 79, 0.1);
    border-radius: 4px;
    padding: 1.2em 1.4em 4.5em;
    width: 100%;
    position: relative;
    justify-content: space-between;
    margin: 1em 0;
    @media (max-width: 530px) {
        padding: 1.2em 8px 6em 8px;
    }

    .image-container {
        @media (max-width: 530px) {
            display: flex;
        }
        .image-wrapper {
            min-width: 97px;
            min-height: 97px;
            max-width: 97px;
            max-height: 97px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10.0799px;
            @media (max-width: 530px) {
                min-width: 68px;
                min-height: 68px;
                max-width: 68px;
                max-height: 68px;
            }
            .icon {
                width: 100%;
                height: 100%;
            }
        }
        .title-refresh-wrapper-mobile {
            display: none;
            justify-content: space-between;
            @media (max-width: 530px) {
                display: flex;
                align-items: center;
                margin-left: 1em;
                width: 100%;
            }
            .title-wrapper-mobile {
                @media (max-width: 530px) {
                    padding-right: 1em;
                }
                h3 {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 26px;
                    margin: 0;
                    color: #EEEEEE;
                }
                p {
                    font-size: 16px;
                    line-height: 22px;
                    color: #99B1BF;
                    margin: 0;
                    &:nth-child(2) {
                        @media (max-width: 530px) {
                            font-size: 14px;
                        }
                    }
                }
                .mobile-title {
                    font-size: 14px;
                    color: #99B1BF;
                }
            }
            .refresh-wrapper-mobile {
                display: flex;
                align-items: center;
                button {
                    background-color: transparent;
                    border: none;
                    outline: none;
                    cursor: pointer;
                }
            }
        }
    }

    .first-part-wrapper {
        display: flex;
        width: 100%;
        @media (max-width: 530px) {
            flex-direction: column;
        }
        .suggested-videos-wrapper {
            margin-left: 1.2em;
            width: 100%;
            @media (max-width: 530px) {
                margin-top: 1em;
                margin-left: 0;
            }
            .title-refresh-wrapper {
                display: flex;
                justify-content: space-between;
                @media (max-width: 530px) {
                    display: none;
                }
                .title-wrapper {
                    p {
                        font-size: 16px;
                        line-height: 22px;
                        color: #99B1BF;
                    }
                }
                .refresh-wrapper {
                    display: flex;
                    align-items: center;
                    button {
                        background-color: transparent;
                        border: none;
                        outline: none;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .second-part-wrapper {
        background: #1F2362;
        border-radius: 4px;
        position: absolute;
        bottom: 6px;
        right: 10px;
        left: 10px;
        @media (max-width: 530px) {
            bottom: 1em;
        }
        p {
            text-align: center;
            color: #99B1BF;
            font-size: 16px;
            line-height: 22px;
        }
    }
}
</style>
