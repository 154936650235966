<template>
  <div class="ScrollableContainerWithStickyHeader">
    <div>
      <div
        ref="header"
        class="headerContainer">
        <slot name="header" />
      </div>
      <div class="bodyContainer">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus';

export default {
    props: {
        stickyHeight: {
            type: Function,
            default: () => 0
        }
    },
    mounted() {
        this.lastStickyFraction = 0;
        this.scrollTopMax = 0;
        this.$el.addEventListener('scroll', this.handleScroll);
        EventBus.$on('resize-end', this.handleResize);
        this.handleResize();
    },
    beforeDestroy() {
        this.$el.removeEventListener('scroll', this.handleScroll);
        EventBus.$off('resize-end', this.handleResize);
    },
    methods: {
        handleScroll() {
            let scrollTop = this.$el.scrollTop;
            let stickyFraction = 1;
            if (scrollTop < this.scrollTopMax) {
                stickyFraction = Math.max(0, scrollTop) / this.scrollTopMax;
            }
            if (this.lastStickyFraction !== stickyFraction) {
                this.$emit('scroll', stickyFraction);
                this.lastStickyFraction = stickyFraction;
            }
        },
        handleResize() {
            let headerElem = this.$refs.header;
            let headerHeight = headerElem.clientHeight;
            let stickyHeight = this.stickyHeight();
            this.scrollTopMax = headerHeight - stickyHeight;
            headerElem.style.top = `-${this.scrollTopMax}px`;
            this.handleScroll();
        }
    }
};
</script>

<style lang="scss" scoped>
.ScrollableContainerWithStickyHeader {
    overflow-y: scroll;
}

.bodyContainer {
    display: flex;
    justify-content: center;
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    height: 65vh;
}

@supports (position: sticky) {
    .headerContainer {
        position: sticky !important;
        // NOTE: top set via code
    }
}
</style>
