<template>
  <div class="TeamLeaderboard">
    <div v-if="leaderboard.length > 0">
      <h4>Team Leaderboard</h4>
      <h5>{{ $t(`dashboard.general.lifeskillLabels.${lifeskill}`) }}</h5>

      <div class="board">
        <div
          class="row"
          v-for="item in leaderboard"
          :key="item.avatar">
          <div class="avatar">
            <Avatar :avatar="item.avatar" />
          </div>
          <div class="name">
            {{ item.displayName }}
          </div>
          <div class="braindates">
            <div class="amount">
              {{ item.braindates }}
            </div>
            <div class="icon">
              <svgicon
              class="heart"
              :style="{ fill }"
              icon="heart-like" />
            </div>
          </div>
          <div class="braindates">
            <div class="amount">
              {{ item.crystals }}
            </div>
            <div class="icon">
              <svgicon
              class="heart"
              :style="{ fill }"
              icon="heart-like" />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<translations>
</translations>

<script>
import Avatar from '@/components/Avatar';
import gql from 'graphql-tag';
import apolloClient from '@/apollo-client';
import { getCategoryIdFromLifeskillId } from '@/utils';
import categoryThemes from '@/category-themes';
import uniqBy from 'lodash/uniqBy';

export default {
    components: { Avatar },
    props: {
        lifeskill: {
            type: String,
            required: true
        }
    },

    data: () => {
        return {
            leaderboard: []
        };
    },

    mounted() {
        apolloClient
        .query({
          query: gql`
                query TeamLeaderboardLifeskill {
                  teamLeaderboardLsList(_ls: "10") {
                    avatar
                    braindates
                    crystals
                    displayName
                    teamId
                    teamName
                  }
                }
                `,
          variables: { ls: this.lifeskill }
        })
        .then(result => {
          this.leaderboard = uniqBy(result.data.teamLeaderboardLsList, 'avatar');
        });
    },
    computed: {
      fill() {
        const categoryId = getCategoryIdFromLifeskillId(this.lifeskill);
        return categoryId
          ? categoryThemes[categoryId].color 
          : categoryThemes.default.color;
      }
    }
};
</script>

<style lang="scss" scoped>
.TeamLeaderboard {
  .board {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    align-items: center;
    padding-bottom: 1em;

  }

  h4 {
    color: #EEEEEE;
    margin-top: 0px;
    font-size: 16px;
    line-height: 19px;
  }

  .braindates {
    display: flex;
    margin-left: auto;
  }

  .amount {
    color: #6E8CDC;
    padding-right: 5px;
  }

  .icon {
    width: 0.8em;
  }

  .heart {
    padding-right: 10px;
    fill: #9885c2;

  }

  h5 {
    display: none;
  }

  .Avatar {
    margin-right: 1em;
    width: 2em;
    height: 2em;
  }

  .name {
    color: #EEEEEE;
  }

}
</style>
