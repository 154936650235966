<template>
  <div
    class="DashboardTopBarDailyGoal --global-clickable"
    @click="showDashboardGoalPopup()">
    <div
      ref="progressContainer"
      class="progressContainer"
      :class="{ goalReached }">
      <CircularProgress
        class="progressIndicator"
        :progress="goalProgress"
        :color="progressColor"
        track-color="rgba(255, 255, 255, 0.1)"
        :stroke-width="10" />
      <svgicon
        class="heartIcon"
        :class="{ hasColor: goalProgress > 0 }"
        icon="heart-like" />
    </div>
    <div class="mobile textSection">
      <div
        class="streak"
        :class="{ today }">
        {{ streakLength }}
      </div>
    </div>
  </div>
</template>

<translations>
  title: 'Daily Goal'
  title_no: 'Dagsmål'
  steak: '{count} day streak'
  streak_no: '{count}-dagers streak'
</translations>

<script>
import { EventBus } from '@/event-bus';
import DailyGoalPopup from './DailyGoalPopup';

import CircularProgress from '@/components/CircularProgress';

export default {
    components: { CircularProgress },
    data() {
        return {
            progressColor: 'white'
        };
    },
    computed: {
        today() {
          return this.braindateCountToday > 0;
        },
        requiredDailyBraindates() {
            return this.$store.state.moduleApp.memolife.dailyRequiredBraindates;
        },
        braindateCountToday() {
            return this.$store.getters['moduleApp/braindatesForCurrentDay'].length;
        },
        goalProgress() {
            return Math.min(1, this.braindateCountToday / this.requiredDailyBraindates);
        },
        goalReached() {
            return this.braindateCountToday >= this.requiredDailyBraindates;
        },
        streakLength() {
            return this.$store.getters['moduleApp/streakLength'];
        }
    },
    mounted() {
        EventBus.$on('request-open-daily-goal-settings', this.showDashboardGoalPopupInSettingsMode);
    },
    beforeDestroy() {
        EventBus.$off('request-open-daily-goal-settings', this.showDashboardGoalPopupInSettingsMode);
    },
    methods: {
        showDashboardGoalPopup(showSettingsInitially = false) {
            this.$modal.open({
                component: DailyGoalPopup,
                target: 'app-dropdown',
                props: { showSettingsInitially },
                hostOptions: {
                    pointAt: this.$refs.progressContainer
                }
            });
        },
        showDashboardGoalPopupInSettingsMode() {
            this.showDashboardGoalPopup(true);
        }
    }
};
</script>

<style lang="scss" scoped>
.DashboardTopBarDailyGoal {
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 0 0.5em;
}

.textSection {
    margin-left: 0.5em;
}
.title {
    color: rgba(white, 0.9);
    font-size: 90%;
}
.streak {
    color: rgba(white, 0.5);
    font-size: 90%;
}

.today {
  color: white;
}


.progressContainer {
    position: relative;
    $size: 1.8em;
    width: $size;
    height: $size;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progressIndicator {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.heartIcon {
    $size: 0.8em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.2);
    &.hasColor {
        fill: white; // $standardButtonColor;
    }
}
</style>
