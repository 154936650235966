<template>
  <div class="SectionWithTabs">
    <div class="tabsSection">
      <DashboardWidthWrapper class="tabs">
        <div
          v-for="(item, i) of items"
          :key="i"
          class="Tab --global-clickable"
          :class="{ 'is-active': activeItem.name === item.name }"
          @click="handleClick(item)">
          <div class="Tab__iconContainer">
            <svgicon
              class="Tab__icon"
              :icon="item.icon" />
          </div>
          <div class="Tab__titleAndSubtitle">
            <div class="Tab__title">
              {{ item.title }}
            </div>
            <div class="Tab__subtitle">
              {{ item.subtitle }}
            </div>
          </div>
        </div>
      </DashboardWidthWrapper>
    </div>
    <div class="bodySection">
      <slot />
    </div>
  </div>
</template>

<script>
import DashboardWidthWrapper from '@/modules/dashboard/components/DashboardWidthWrapper';

export default {
    components: { DashboardWidthWrapper },
    props: {
        items: {
            type: Array
        },
        activeItem: {}
    },
    methods: {
        handleClick(item) {
            this.$emit('click', item);
        }
    },
};
</script>

<style lang="scss" scoped>
$activePanelBackgroundColor: mix(black, white, 3%);
$passivePanelBackgroundColor: mix(black, white, 7%);

$activePanelBorderColor: mix(black, white, 7%);
$passivePanelBorderColor: mix(black, white, 9%);

.tabsSection {
    position: relative;
    border-bottom: 1px solid $activePanelBorderColor;
}
.tabs {
    position: relative;
    display: flex;
    margin: auto;
}

.Tab {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    height: 5em;
    border-radius: 0.5em 0.5em 0 0;
    padding: 0 1em;
    bottom: -1px;
    overflow: hidden; // Needed for possible ellipsis in children

    &:not(:first-child) {
        margin-left: 1em;
    }

    &.is-active {
        background-color: $activePanelBackgroundColor;
        background: linear-gradient(
            0deg,
            $activePanelBackgroundColor 0%,
            lighten($activePanelBackgroundColor, 2%) 100%
        );

        border: 1px solid $activePanelBorderColor;
        border-bottom: 1px solid $activePanelBackgroundColor;
        cursor: default;
    }

    &:not(.is-active) {
        background-color: $passivePanelBackgroundColor;
        border: 1px solid $passivePanelBorderColor;
        border-bottom: 1px solid mix($activePanelBorderColor, $passivePanelBorderColor, 50%);
    }
}

.Tab__iconContainer {
    $size: 2.8em;
    width: $size;
    height: $size;
    // background: linear-gradient(90deg, #4792ff, #71a9ff);
    background-color: #292929;

    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;

    .Tab__icon {
        $size: 50%;
        width: $size;
        height: $size;
        fill: white;
    }
}

.Tab__titleAndSubtitle {
    flex: 1;
    overflow: hidden;
}
.Tab__title {
    font-size: 120%;
    color: rgba(black, 0.8);

    overflow: hidden;
    text-overflow: ellipsis;
}
.Tab__subtitle {
    font-size: 80%;
    color: rgba(black, 0.7);
    margin-top: 0.3em;
}

.bodySection {
    position: relative;
    background-color: $activePanelBackgroundColor;
}

// ------------------------------------------------
// 2 column layout
// ------------------------------------------------

@include media('<#{$breakpointFor2Columns}') {
    .Tab {
        height: 4em;
    }
    .Tab__subtitle {
        display: none;
    }
}
</style>
