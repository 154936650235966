<template>
    <div class="MentalHygienePractice">
        <div
            v-for="(mentalHygieneStep, index) of mentalHygieneSteps"
            :key="mentalHygieneStep.id"
            class="mental-hygiene-step">

          <img :src="mentalHygieneStep.icon">

            <div class="progress-wrapper">
                <div class="progress-bar-info">
                    <div class="mental-hygiene-step-name"> {{ $t(mentalHygieneStep.id) }} </div>
                    <div class="mental-hygiene-step-progress">
                        {{ mentalHygieneStep.timesFinished + '/' + maxTimesFinishedStepCount }}
                    </div>
                </div>
                <div class="progress-bar-wrapper">
                    <div class="progress-bar" :style="{ width: mentalHygieneStep.percentageProgress }" />
                </div>
            </div>
            <button
                class="button-blue"
                :style="{ pointerEvents: mentalHygieneStep.finishedToday && 'none' }"
                @click="!mentalHygieneStep.finishedToday && goToMentalHygiene(index)"
                :disabled="journeyFinished && mentalHygieneStep.finishedToday">
              <span v-if="journeyFinished">{{ $t('start') }}</span>
              <span v-if="!journeyFinished">{{ $t('locked') }}</span>
            </button>
        </div>
        <div class="info" v-if="!hasAwardedLifekey"> <p>{{ $t('complete') }}</p> </div>
    </div>
</template>

<translations>
  start: Start
  start_no: Start

  locked: Locked
  locked_no: Låst

  complete: Complete all tasks in mental hygiene to get a life key.
  complete_no: Gjør din mentalhygiene og få en livsnøkkel.

  dailyJoys: Daily Joys
  dailyJoys_no: Dagens gleder

  kissTheFrog: Kiss The Frog
  kissTheFrog_no: Kyss froskene

  focusCheck: Focus Check
  focusCheck_no: Fokussjekk

  dailyPlan: Daily Plan
  dailyPlan_no: Dagsplan

  learningDiary: Learning Diary
  learningDiary_no: Læringsdagbok

</translations>

<script>
import { mapGetters, mapActions } from 'vuex';
import joyIcon from '@/assets/images/mh-joy.svg';
import frogIcon from '@/assets/images/mh-frog.svg';
import focusIcon from '@/assets/images/mh-focus.svg';
import planIcon from '@/assets/images/mh-plan.svg';
import diaryIcon from '@/assets/images/mh-diary.svg';

export default {
    data() {
        return {
            maxTimesFinishedStepCount: 5
        };
    },

    computed: {
        ...mapGetters({
            joysTimesFinishedCount: 'moduleMentalhygiene/joysTimesFinishedCount',
            frogsTimesFinishedCount: 'moduleMentalhygiene/frogsTimesFinishedCount',
            focusChecksTimesFinishedCount: 'moduleMentalhygiene/focusChecksTimesFinishedCount',
            dayplansTimesFinishedCount: 'moduleMentalhygiene/dayplansTimesFinishedCount',
            diariesTimesFinishedCount: 'moduleMentalhygiene/diariesTimesFinishedCount',

            todaysJoys: 'moduleMentalhygiene/todaysJoys',
            todaysFrogs: 'moduleMentalhygiene/todaysFrogs',
            todaysFocusChecks: 'moduleMentalhygiene/todaysFocusChecks',
            dayplanToday: 'moduleMentalhygiene/dayplanToday',
            todaysDiary: 'moduleMentalhygiene/todaysDiary',
            hasAwardedLifekey: 'moduleMentalhygiene/hasAwardedLifekey',

            journeyFinished: 'moduleMentalhygiene/journeyFinished',

            areJoysFinishedToday: 'moduleMentalhygiene/areJoysFinishedToday',
            areFrogsFinishedToday: 'moduleMentalhygiene/areFrogsFinishedToday',
            areFocusChecksFinishedToday: 'moduleMentalhygiene/areFocusChecksFinishedToday',
            isDayplanFinishedToday: 'moduleMentalhygiene/isDayplanFinishedToday',
            areDiariesFinishedToday: 'moduleMentalhygiene/areDiariesFinishedToday',
        }),

        mentalHygieneSteps() {
            return [
                {
                    id: 'dailyJoys',
                    icon: joyIcon,
                    timesFinished: Math.min(this.joysTimesFinishedCount, 5),
                    percentageProgress: this.countPercentageProgress(this.joysTimesFinishedCount),
                    finishedToday: this.areJoysFinishedToday
                },
                {
                    id: 'kissTheFrog',
                    icon: frogIcon,
                    timesFinished: Math.min(this.frogsTimesFinishedCount, 5),
                    percentageProgress: this.countPercentageProgress(this.frogsTimesFinishedCount),
                    finishedToday: this.areFrogsFinishedToday
                },
                {
                    id: 'focusCheck',
                    icon: focusIcon,
                    timesFinished: Math.min(this.focusChecksTimesFinishedCount, 5),
                    percentageProgress: this.countPercentageProgress(this.focusChecksTimesFinishedCount),
                    finishedToday: this.areFocusChecksFinishedToday
                },
                {
                    id: 'dailyPlan',
                    icon: planIcon,
                    timesFinished: Math.min(this.dayplansTimesFinishedCount, 5),
                    percentageProgress: this.countPercentageProgress(this.dayplansTimesFinishedCount),
                    finishedToday: this.isDayplanFinishedToday
                },
                {
                    id: 'learningDiary',
                    icon: diaryIcon,
                    timesFinished: Math.min(this.diariesTimesFinishedCount, 5),
                    percentageProgress: this.countPercentageProgress(this.diariesTimesFinishedCount),
                    finishedToday: this.areDiariesFinishedToday
                }
            ];
        },
    },

    methods: {
        ...mapActions({ fetchMentalHygieneStats: 'moduleMentalhygiene/load' }),

        countPercentageProgress(stepTimesFinishedCount) {
          const c = Math.min(stepTimesFinishedCount, this.maxTimesFinishedStepCount);
          return (c / this.maxTimesFinishedStepCount) * 100 + '%';
        },

        goToMentalHygiene(stepNumber) {
            this.$router.push({
                name: 'MentalHygiene',
                params: {
                    setInitialTab: true,
                    stepNumber
                }
            });
        }
    },

    async mounted() {
        await this.fetchMentalHygieneStats();
    }
};
</script>

<style lang="scss" scoped>
.MentalHygienePractice {
    width: 100%;
    background: #1A1E54;
    border-radius: 4px;
    padding: 40px 20px 20px 30px;
    margin-right: 30px;
    @media (max-width: 886px) {
        margin-right: 0;
    }

    @media (max-width: 500px) {
        padding-left: 20px;
    }

    .mental-hygiene-step {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        margin-bottom: 30px;
        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            @media (max-width: 500px) {
                width: 30px;
                height: 30px;
            }
        }

        .progress-wrapper {
            width: 100%;
            height: 100%;
            padding: 0 40px 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media (max-width: 500px) {
                padding-right: 20px;
            }
            .progress-bar-info {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                @media (max-width: 500px) {
                    font-size: 10px;
                }

                .mental-hygiene-step-name {
                    color: white;
                }
                .mental-hygiene-step-progress {
                    color: white;
                }
            }

            .progress-bar-wrapper {
                background-color: lightgray;
                border-radius: 30px;
                .progress-bar {
                    height: 10px;
                    border-radius: 30px;
                    background: #5983f7;
                    @media (max-width: 500px) {
                        height: 5px;
                    }
                }
            }
        }

        button {
            cursor: pointer;
            align-self: flex-end;
            @media (max-width: 500px) {
                height: 5px;
                align-self: center;
            }
        }
        button:disabled {
          opacity: 0.5;
        }
    }
    .info {
        // background: rgba(196, 196, 196, 0.12);
        border-radius: 4px;
        p {
            text-align: center;
            padding: 30px 0;
            margin: 20px 0 0 0;
            font-size: 14px;
            color: white;
            opacity: 0.6;
            @media (max-width: 500px) {
                font-size: 10px;
            }
        }
    }
}
</style>
