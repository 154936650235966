<template>
  <div class="TeamLifekeys">
    <header>
      <h3> {{ $t('teamProgress') }} </h3>
      <select v-model="selectedOption">
        <option value="All">
          {{ $t('all') }}
        </option>
        <option v-if="lifeskillStats.totalTheoryLifekeys" value="Theory">
          {{ $t('theory') }}
        </option>
        <option v-if="lifeskillStats.totalPracticeLifekeys" value="Practice">
          {{ $t('practice') }}
        </option>
        <option v-if="lifeskillStats.totalVideoLifekeys" value="Video">
          {{ $t('videos') }}
        </option>
      </select>
    </header>

    <div class="whole-progress">
      <div>Total</div>
      <div class="progress-bar-wrapper">
        <div
          class="progress-bar"
          :style="{ width: (selectedLifekeys.length / totalLifekeysToAchieveByTeamInCourse) * 100 + '%' }"
        />
      </div>
      <div>{{ selectedLifekeys.length }}/{{ totalLifekeysToAchieveByTeamInCourse }}</div>
    </div>

    <div class="team-lifekeys-progress-wrapper">
      <div v-for="(playerProgress, i) in teamProgressByExpand" :key="playerProgress.userId" class="player-progress">
        <div class="progress">
          <Avatar
            :style="{ left: avatarProgress(playerProgress) }"
            :avatar="playerProgress.avatar"
            class="avatar"
            :class="{ loggedUser: i === 0 }"
            :alt="playerProgress.displayName"
          />
        </div>

        <div class="achieved-lifekeys">
          <p>{{ playerProgress.lifekeys.length }}/{{ lifekeysCount }}</p>
          <svgicon icon="keyShort" class="icon" />
        </div>
      </div>

      <div class="btn-wrapper all-tab-btn-wrapper">
        <button class="expand-collapse-btn" @click="viewAll = !viewAll">
          {{ viewAll ? 'View some' : 'View all' }}
        </button>
      </div>
    </div>
  </div>
</template>

<translations>

teamProgress: 'Team Progress'
teamProgress_no: 'Team-fremgang'

all: 'All'
all_no: 'Alt'

theory: 'Theory'
theory_no: 'Teori'

practice: 'Practice'
practice_no: 'Praksis'

videos: 'Videos'
videos_no: 'Video'

</translations>

<script>
import apolloClient from '@/apollo-client'
import gql from 'graphql-tag'
import _sortBy from 'lodash/sortBy'
import { mapGetters } from 'vuex'
import Avatar from '@/components/Avatar'

export default {
  components: { Avatar },
  props: {
    section: {
      type: String,
      default: 'All',
    },
    lifeskill: {
      required: true,
    },
  },

  data() {
    return {
      teamMembers: [],
      teamLifekeysList: [],
      selectedOption: this.section,
      viewAll: false,
    }
  },

  computed: {
    selectedLifekeys() {
      if (this.selectedOption === 'All') {
        return this.teamLifekeys
      }
      if (this.selectedOption === 'Practice') {
        return this.teamPracticeLifekeys
      }
      if (this.selectedOption === 'Theory') {
        return this.teamTheoryLifekeys
      }
      if (this.selectedOption === 'Video') {
        return this.teamVideoLifekeys
      }
      return []
    },

    ...mapGetters({
      getLifeskillStats: 'moduleApp/getLifeskillStats',
      profile: 'moduleAuth/profile',
    }),

    teamLifekeys() {
      return this.teamLifekeysList
    },

    lifeskillStats() {
      return this.getLifeskillStats(this.$route.params.lifeskillId)
    },

    totalCourseLifekeysCount() {
      return (
        this.lifeskillStats.totalTheoryLifekeys +
        this.lifeskillStats.totalPracticeLifekeys +
        this.lifeskillStats.totalVideoLifekeys
      )
    },

    totalLifekeysToAchieveByTeamInCourse() {
      return this.lifekeysCount * this.teamMembers.length
    },

    lifekeysCount() {
      let count = 0
      if (this.selectedOption === 'All') {
        count = this.totalCourseLifekeysCount
      }
      if (this.selectedOption === 'Theory') {
        count = this.lifeskillStats.totalTheoryLifekeys
      }
      if (this.selectedOption === 'Practice') {
        count = this.lifeskillStats.totalPracticeLifekeys
      }
      if (this.selectedOption === 'Video') {
        count = this.lifeskillStats.totalVideoLifekeys
      }
      return count
    },

    teamTheoryLifekeys() {
      return this.teamLifekeys.filter(lifekey => lifekey.context.startsWith('theory-journey'))
    },

    teamPracticeLifekeys() {
      return this.teamLifekeys.filter(lifekey => lifekey.context.startsWith('habit-journey'))
    },

    teamVideoLifekeys() {
      return this.teamLifekeys.filter(lifekey => lifekey.context.includes('video'))
    },

    alphabeticalSortedProgressByTeamMemberWithLeadingLoggedUser() {
      const progressByTeamMember = this.teamMembers.map(teamMember => {
        return {
          displayName: teamMember.displayName,
          userId: teamMember.userId,
          avatar: teamMember.avatar,
          lifekeys: this.selectedLifekeys.filter(lifekey => lifekey.userId === teamMember.userId),
        }
      })
      const loggedUserProgress = progressByTeamMember.find(
        teamMemberProgress => teamMemberProgress.userId === this.profile.id
      )
      const progressByTeamMemberWithoutLoggedUserProgress = progressByTeamMember.filter(
        teamMemberProgress => teamMemberProgress.userId !== this.profile.id
      )
      return [
        loggedUserProgress,
        ..._sortBy(progressByTeamMemberWithoutLoggedUserProgress, [
          progressByTeamMember => progressByTeamMember.displayName.toLowerCase(),
        ]),
      ].filter(x => x !== undefined)
    },

    teamProgressByExpand() {
      return this.viewAll
        ? this.alphabeticalSortedProgressByTeamMemberWithLeadingLoggedUser
        : this.alphabeticalSortedProgressByTeamMemberWithLeadingLoggedUser.slice(0, 5)
    },
  },

  async mounted() {
    this.fetch()
  },

  methods: {
    async fetch() {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query TeamLifekeys($lifeskillNumber: String!) {
            teamLifekeysList(condition: { lifeskillNumber: $lifeskillNumber }) {
              avatar
              context
              displayName
              lifeskillNumber
              teamId
              teamName
              userId
              lifekeyId
            }
            teamMembersList {
              avatar
              displayName
              userId
            }
          }
        `,
        variables: { lifeskillNumber: this.lifeskill },
      })

      this.teamLifekeysList = response.data.teamLifekeysList
      this.teamMembers = response.data.teamMembersList
    },

    avatarProgress(playerProgress) {
      if (playerProgress.lifekeys.length === 0) {
        return '0%'
      }
      const percent = Math.min((playerProgress.lifekeys.length / this.lifekeysCount) * 100, 100)
      return `calc(${percent}% - 30px)`
    },
  },
}
</script>

<style lang="scss" scoped>
.TeamLifekeys {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #eeeeee;

    h3 {
      font-size: 16px;
    }

    select {
      padding: 5px;
      background: #1f2362;
      border: none;
      outline: none;
      border-radius: 5px;
      color: #eeeeeec4;
    }
  }

  .whole-progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
    border-radius: 5px;
    color: #99b1bf;
  }
  .avatar {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: black;
    position: relative;
    animation-name: progress-avatar;
    animation-duration: 0.5s;

    @keyframes progress-avatar {
      from {
        left: 0;
      }
    }
  }

  .progress-bar-wrapper {
    background: #1f2362;
    width: 100%;
    margin: 0 10px;
    border-radius: 30px;
    display: flex;
  }

  .progress-bar {
    background: #216fc9;
    height: 100%;
    border-radius: 30px;
    height: 7px;
    animation-name: progress-bar;
    animation-duration: 0.5s;

    @keyframes progress-bar {
      from {
        width: 0;
      }
    }
  }

  .progress {
    width: 100%;
    background: #1f2362;
    height: 7px;
    display: flex;
    align-items: center;
    border-radius: 30px;
  }

  .player-progress {
    display: flex;
    align-items: center;
    margin: 30px 0;
    padding: 0 5px;
    .achieved-lifekeys {
      display: flex;

      p {
        color: #6e8cdc;
        margin: 0;
        padding-left: 20px;
      }

      .icon {
        fill: #ffaf00;
        min-width: 15px;
        min-height: 15px;
        max-width: 15px;
        max-height: 15px;
        margin-left: 5px;
      }
    }
  }

  .all-tab-btn-wrapper {
    margin: 10px 0 40px 0;
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;

    .expand-collapse-btn {
      background: inherit;
      border: none;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }
  }
}
</style>
