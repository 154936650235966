<template>
  <div class="JourneyItem" :class="{ collapsed, completed }">
    <template v-if="!hideName">
      <div class="title header --global-clickable" @click.stop="$emit('request-collapse', !collapsed)">
        <JourneyIcon class="journeyIcon" type="theory" :label="`${stepGroup.index + 1}`" />
        <div class="headerText">
          <div class="headerTitle">
            {{ stepGroup.name || `${$t('journey') + ' ' + (stepGroup.index + 1)}` }}
          </div>

          <div class="text">
            <span v-if="completed">{{ $t('journeyCompleted') }}</span>
            <span v-else>
              {{ $t('journeyProgress', { percent: percentageProgress }) }}
            </span>
          </div>
          <div class="progress-bar-wrapper">
            <div
              v-if="percentageProgress !== 0 && percentageProgress !== 100"
              class="progress-bar"
              :style="{ width: percentageProgress + '%' }"
            />
          </div>
        </div>

        <div class="buttonToggleCollapse">
          <svgicon class="buttonToggleCollapseIcon" icon="chevron-down" :xstyle="{ fill: theme.color }" />
        </div>
      </div>
    </template>

    <TransitionExpandCollapse>
      <div v-if="!collapsed" class="paddingBox">
        <div class="paddingBoxTopSpacer" />

        <VerticalTimeline
          :items="items"
          extend-top
          :content-overflow-hidden="false"
          :line-style="{ dasharray: [7, 4], color: 'rgba(255, 255, 255, 0.1)' }"
          :active-line-style="{ color: themeColor }"
        >
          <template slot="bullet" slot-scope="props">
            <StepBullet
              :current="props.item.current"
              :progress="props.item.progress"
              :icon="{ masterquiz: 'keyShort' }[props.type] || 'steps-3'"
            />
          </template>

          <template slot="content" slot-scope="props">
            <JourneyQuizItem
              v-if="props.item.type === 'masterquiz'"
              :journey-id="stepGroupId"
              :current="props.item.current"
            />
            <!-- <JourneyChallenge
              v-if="props.item.type === 'masterquiz'"
              :journey-id="stepGroupId"
              :course-id="stepGroup.courseId"
            /> -->
            <StepItem
              v-if="props.item.type !== 'masterquiz'"
              :course-id="stepGroup.courseId"
              :step-id="props.item.id"
              :current="props.item.current"
              :class="{ isIntro: props.item.isIntro }"
            />
            <JourneyChallenge
              v-if="props.item.type === 'masterquiz' || props.item.id === '36cf758a-8d5d-449e-b5f9-87441847d276'"
              :journey-id="stepGroupId"
              :course-id="stepGroup.courseId"
            />
          </template>
        </VerticalTimeline>
        <div class="paddingBoxBottomSpacer" />
        <div v-if="hasSomeLockedSteps" class="infoBox">
          <UpgradeButton compact />
        </div>
      </div>
    </TransitionExpandCollapse>
  </div>
</template>

<translations>
  journeyProgress: '{percent}% completed'
  journeyProgress_no: 'Du har fullført {percent}% av reisen'
  journeyCompleted: Completed
  journeyCompleted_no: Fullført
  journey: Journey
  journey_no: Reise
  completeJourney: Complete journey to get a life key
  completeJourney_no: Fullfør ferdigheten for å få livsnøkkel
</translations>

<script>
import { getStepGroupTitle } from '@/utils'
import VerticalTimeline from '@/components/VerticalTimeline'
import TransitionExpandCollapse from '@/components/TransitionExpandCollapse'
import UpgradeButton from '@/modules/dashboard/components/UpgradeButton'
import { cssVariables } from '@/category-themes'

import StepItem from './StepItem'
import JourneyQuizItem from './JourneyQuizItem'
import StepBullet from './StepBullet'
import JourneyIcon from './JourneyIcon'
import JourneyChallenge from './JourneyChallenge/index'

export default {
  components: {
    TransitionExpandCollapse,
    VerticalTimeline,
    StepBullet,
    StepItem,
    JourneyQuizItem,
    UpgradeButton,
    JourneyIcon,
    JourneyChallenge,
  },
  inject: ['theme'],
  props: {
    stepGroupId: {
      type: String,
      required: true,
    },
    useEllipsis: {
      type: Boolean,
      default: false,
    },
    hideActivities: {
      type: Boolean,
      default: false,
    },
    hideName: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    percentageProgress() {
      return Math.round(this.journeyProgress * 100)
    },
    stepGroup() {
      return this.$store.state.moduleCourse.stepGroups[this.stepGroupId]
    },
    steps() {
      return this.stepGroup.stepIds.map(stepId => this.$store.state.moduleCourse.steps[stepId])
    },
    title() {
      return getStepGroupTitle(this.stepGroup, this.$t.bind(this), this.$tc.bind(this))
    },
    quizType() {
      return this.stepGroup.quiz ? this.stepGroup.quiz.generator : ''
    },
    themeColor() {
      return cssVariables.completionColor
    },
    items() {
      const items = this.steps.map(step => {
        return {
          id: step.id,
          progress: step.progress,
        }
      })
      if (this.steps[0] && !this.steps[0].number) {
        items[0].isIntro = true
      }

      if (this.stepGroup.quiz) {
        if (
          this.quizType === 'standardStepGroupQuiz' ||
          this.quizType === 'chemistryStepGroupQuiz' ||
          this.quizType === 'historyJourneyQuiz'
        ) {
          items.push({
            type: 'masterquiz',
            progress: this.stepGroup.quiz.done ? 1 : 0,
          })
        } else if (this.quizType === 'memoGameA') {
          // Add special memo quiz
          items.push({
            type: 'masterquiz',
            subtype: 'memogame',
            progress: this.stepGroup.quiz.done ? 1 : 0,
          })
        }
      }
      const currentItem = items.find(item => item.progress < 1)
      if (currentItem) {
        currentItem.current = true
      }
      return items
    },
    hasSomeLockedSteps() {
      return this.steps.some(step => !this.$can('view', step))
    },
    journeyProgress() {
      return this.items.length ? this.items.reduce((accum, item) => (accum += item.progress), 0) / this.items.length : 0
    },
    completed() {
      return this.stepGroup.quiz ? this.stepGroup.quiz.done : this.journeyProgress === 1
    },
  },
}
</script>

<style lang="scss" scoped>
$bulletColumnWidth: 4em;
$bulletColumnRightMargin: 2em;

.JourneyItem {
  background-color: #1a1e54;
  box-shadow: 0px 2px 23px 3px rgba(79, 79, 79, 0.1);
  padding: 1.2em 0;
  border-radius: 4px;
  .title {
    padding: 0 18.5px;
    width: 100%;
    @media (max-width: 530px) {
      padding: 0 8px;
    }
  }
  .VerticalTimeline {
    /deep/ .extendedTopLine {
      height: 1.3em;
    }
  }

  &.collapsed {
    .buttonToggleCollapseIcon {
      transform: rotate(0deg);
    }
  }

  &.completed {
    .infoBoxContainer {
      display: none;
    }
  }

  &:not(.collapsed) {
    .buttonToggleCollapse {
      border: 1px solid $purple;
    }
  }

  /deep/ .Step {
    .bullet {
      width: 95px;
      margin-right: $bulletColumnRightMargin;
      @media (max-width: 530px) {
        width: 4em;
        margin-right: 0;
      }
    }
  }
}
.header {
  display: flex;
  align-items: center;
}

.headerText {
  flex: 1;
  padding-right: 20px;
  p {
    font-size: 16px;
    line-height: 22px;
    color: #9fb4ef;
    margin-top: 5px;
    margin-bottom: 20px;
    @media (max-width: 530px) {
      font-size: 14px;
    }
  }
}
.headerTitle {
  font-size: 140%;
  font-weight: 600;
  margin-top: 15px;
  color: #eeeeee;
  @media (max-width: 530px) {
    font-size: 16px;
  }
}
.title {
  padding: 1em 0;
}
.journeyIcon {
  width: 96px;
  height: 96px;
  margin-right: $bulletColumnRightMargin;
  @media (max-width: 530px) {
    width: 66px;
    height: 66px;
  }
}

.buttonToggleCollapse {
  width: 35px;
  height: 35px;
  background-color: rgba(black, 0.03);
  border: 1px solid rgba(black, 0.02);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid $purple;
  }
  @media (max-width: 1000px) {
    width: 25px;
    height: 25px;
  }
}

.buttonToggleCollapseIcon {
  width: 12px;
  height: 12px;
  fill: $completionColor;
  transition: transform 0.5s;
  transform: rotate(-180deg);
  @media (max-width: 1000px) {
    width: 7px;
    height: 7px;
  }
}

.progress-bar-wrapper {
  margin-top: 7.5px;
  background: #eaeaea;
  .progress-bar {
    width: 0;
    height: 3px;
    background-color: #5983f7;
    border-top-left-radius: 50% 50px;
    border-bottom-left-radius: 50% 50px;
    border-top-right-radius: 50% 50px;
    border-bottom-right-radius: 50% 50px;
  }
}

.label {
  font-weight: 600;
}
.text {
  font-size: 16px;
  line-height: 22px;
  color: #9fb4ef;
  margin-top: 10px;
  @media (max-width: 530px) {
    font-size: 14px;
  }
}

.paddingBox {
  // NOTE: needed to avoid cutting shadow while collapsing
  padding: 0 1.25em;
  @media (max-width: 530px) {
    padding: 0 8px;
  }
  // background: white;
}
.paddingBoxTopSpacer,
.paddingBoxBottomSpacer {
  height: 1em;
}
.infoBoxContainer {
  padding: 1.5em;
  padding-top: 0em;
  padding-bottom: 1em;
}
.infoBox {
  background-color: rgba(black, 0.03);
  border: 1px solid rgba(black, 0.02);
  padding: 1em;
  text-align: center;
  color: rgba(black, 0.5);
  line-height: 1.4em;
}
</style>
