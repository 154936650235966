<template>
  <div
    class="DashboardTopBarNotifications --global-clickable"
    @click="showNotificationPopup()">
    <svgicon
      class="icon"
      icon="notification" />
    <span class="valueBox">
      <span class="value">
        {{ notificationCount }}
      </span>
    </span>
  </div>
</template>

<script>
import DashboardNotificationPopup from './DashboardNotificationPopup';

export default {
    computed: {
        notificationCount() {
            return this.$store.getters['moduleApp/notifications'].length;
        }
    },
    mounted() {
        this.$store.dispatch('scenario/load');
        this.$store.dispatch('scenario/loadProgress');
        if (this.$store.state.moduleApp.memolife.initiallyOpenNotificationDropdown) {
            this.$store.commit('moduleApp/setInitiallyOpenNotificationDropdown', false);
            this.showNotificationPopup();
        }
    },
    methods: {
        showNotificationPopup() {
            this.$modal.open({
                component: DashboardNotificationPopup,
                target: 'app-dropdown',
                hostOptions: {
                    pointAt: this.$el
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.DashboardTopBarNotifications {
    display: flex;
    align-items: center;

    align-self: stretch;
    padding: 0 0.5em;
}
.icon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: white;
}
.valueBox {
    background-color: $standardButtonColor;
    border-radius: 2em;
    // padding: 0 0.5em;
    height: 1em;
    display: flex;
    align-items: center;
    margin-left: -0.2em;

    padding: 0;
    width: 1em;
    justify-content: center;
}
.value {
    color: white;
    font-size: 60%;
}
</style>
