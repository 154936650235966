<template>
  <container class="container">
    <div class="topSection">
      <DashboardWidthWrapper>
        <div v-if="showStats" />
        <div
          v-else-if="lifeskill.isEssential"
          v-t="'essentialComingSoonNotice'"
          class="essentialComingSoonNotice" />
        <div
          v-else
          v-t="'comingLaterNotice'"
          class="essentialComingSoonNotice" />
      </DashboardWidthWrapper>
    </div>

    <h3>{{ $t('pageTitle') }}</h3>
    <DashboardCourseDetails
      v-if="masterCourseId"
      :course-id="masterCourseId"
      hide-title />
  </container>
</template>

<translations>
  pageTitle: Learn with theory journeys
  pageTitle_no: Lær med teorireiser

  title: 'Learn fast. Remember forever'
  title_no: 'Lær hurtig. Husk for alltid'

  # text: 'Master the theory super-fast with fun memory techniques and repetitions just before your brain starts to forget. All you need is 5 minutes per day.'
  # text_no: 'Mestre teorien lynraskt med morsomme husketeknikker og repetisjoner rett før hjernen din begynner å glemme. Alt du trenger er 5 minutter om dagen.'
  text: 'Here''s how you learn the theory {0}'
  text_no: 'Slik lærer du teorien {0}'

  # learnMore: 'Learn more'
  # learnMore_no: 'Lær mer'

  learnMore: 'super-fast'
  learnMore_no: 'lynraskt'

  theoryPanelTitle: 'Theory'
  theoryPanelTitle_no: 'Teori'
  theoryPanelSubtitle: 'Learn fast. Remember forever'
  theoryPanelSubtitle_no: 'Lær hurtig. Husk for alltid'

  practicePanelTitle: 'Practice'
  practicePanelTitle_no: 'Praksis'
  practicePanelSubtitle: 'Let new habits shape your future'
  practicePanelSubtitle_no: 'La nye vaner gjøre deg dyktig'

  essentialComingSoonNotice: 'We''ll be launching these skills shortly, together with the the book tour "Stay Ahead of Change - 16 Skills You Need Before The Robots Take Over" by Memolife CEO Frank Wedde.'
  essentialComingSoonNotice_no: 'Vi lanserer disse ferdighetene løpende fremover, sammen med foredragsturneen for boken "Ligg et hode foran - 16 ferdigheter du trenger før robotene overtar" til Memolife CEO Frank Wedde.'

  comingLaterNotice: 'Memolife utvikler i alt 100 ferdigheter som vil hjelpe deg ligge et hode foran og få mer ut av livet. Det at du har vært inne på denne siden bidrar til at denne ferdigheten kan bli prioritert.'

  trialNoticeButton: 'Free trial'
  trialNoticeButton_no: 'Prøv gratis'
  trialNoticeText: 'Try the first step of the course and read the articles of the other steps for free. Upgrade her for full access.'
  trialNoticeText_no: 'Prøv det første steget i kurset og les artiklene til de andre stegene gratis. Oppgrader her for full tilgang.'
</translations>

<script>
import MemomapsOnboardingPopup from '@/modules/course/components/MemomapsOnboardingPopup';

import DashboardCourseDetails from '../../components/DashboardCourseDetails';
import DashboardWidthWrapper from '../../components/DashboardWidthWrapper';
import Container from '@/modules/dashboard/components/Container';

export default {
    components: {
        DashboardCourseDetails,
        DashboardWidthWrapper,
        Container
    },
    inject: ['theme'],
    props: {
        lifeskillId: {
            type: String
        }
    },
    data() {
        return {
            activeSectionName: null
        };
    },
    computed: {
        showStats() {
            return !!this.masterCourseId;
        },
        showTrialNotice() {
            return this.masterCourseId && !this.$can('use', { modelName: 'Course', courseId: this.masterCourseId });
        },
        lifeskill() {
            return this.$store.getters['moduleApp/getLifeskillById'](this.lifeskillId);
        },
        lifeskillLabel() {
            return this.$t(`dashboard.general.lifeskillLabels.${this.lifeskillId}`);
        },
        masterCourseId() {
            return this.lifeskill.masterCourseId;
        },
        activeSection() {
            return this.sections.find(section => section.name === this.activeSectionName) || this.sections[0];
        }
    },
    methods: {
        showMemomapsOnboardingPopup() {
            this.$modal
                .open({
                    component: MemomapsOnboardingPopup,
                    target: 'app-modal'
                })
                .waitDone()
                .then(message => {
                    this.$store.dispatch('moduleApp/markMemomapsOnboardingAsShown');
                });
        }
    }
};
</script>

<style lang="scss" scoped>
$backgroundColor: mix(black, white, 3%);
$topSectionBackroundColor: mix(black, white, 5%);

.container {
    margin: 0 1em;
    // background: #f7f7f7;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h3 {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: #EEEEEE;
    margin-top: 54px;
    margin-bottom: 22px;
    text-align: left;
    width: 100%;
    @media (max-width: 886px) {
        font-size: 18px;
        margin: 54px auto 22px;
    }
    @media (max-width: 530px) {
        font-size: 16px;
    }
}

.CoursePanel {
    background-color: $backgroundColor;
    @media (max-width: 884px) {
        display: none;
    }
}
.TrialNotice {
    background-color: white;
    padding: 1em 0;
}
.TrialNotice__button {
    display: inline-block;
    background-color: $standardButtonColor;
    color: white;
    border-radius: 4px;
    padding: 0.1em 0.5em;
    margin-right: 0.5em;
}
.TrialNotice__text {
    display: inline;
    line-height: 1.5em;
}

.essentialComingSoonNotice {
    line-height: 1.5em;
    color: rgba(black, 0.6);
    margin: auto;
    margin-top: 2em;
    max-width: 30em;
    font-size: 120%;
}

.learnMore {
    text-decoration: underline;
}

.CoursePanel .DashboardCourseDetails {
    padding-top: 0;
}

@include media('>=#{$breakpointFor2Columns}') {
}
@include media('<#{$breakpointFor2Columns}') {
}
</style>
