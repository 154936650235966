<template>
  <div
    v-if="!isMobile"
    class="VideosProgressBar">
    <div class="content-wrapper">
      <div class="progress-scale">
        <div class="title">
          {{ $t('title') }}
        </div>
        <div class="scale-item first">
          {{ braindatesForLifekey }}
        </div>
        <div class="scale-item second">
          {{ totalVideoBraindates }}
        </div>
      </div>
      <div class="progress-bar-wrapper">
        <div
          class="progress-bar"
          :style="{ width: percentageProgress + '%' }" />
      </div>
      <div class="progress-bottom-scale">
        <div class="info">
          {{ $t('brainDatesCount', { acquiredVideoBraindates, totalVideoBraindates }) }}
        </div>
        <svgicon
          class="first icon"
          icon="progress-key" />
        <svgicon
          class="second icon"
          icon="progress-key" />
      </div>
    </div>
  </div>

  <div
    v-else
    class="VideosProgressBar">
    <div class="content-wrapper">
      <div class="progress-scale">
        <div class="title">
          {{ $t('brainDatesCount', { acquiredVideoBraindates, totalVideoBraindates }) }}
        </div>
      </div>
      <div class="progress-bar-wrapper">
        <div
          class="progress-bar"
          :style="{ width: percentageProgress + '%' }" />
      </div>
      <div class="progress-bottom-scale">
       
        <svgicon
          class="first icon"
          icon="progress-key" />
        <svgicon
          class="second icon"
          icon="progress-key" />
      </div>
    </div>

  </div>
</template>

<translations>
    title: Video journey 1 / 2
    title_no: Videoreise 1 / 2
    title2: Video journey 2 / 2
    title_no2: Videoreise 2 / 2
    brainDates: Brain Dates
    brainDates_no: Hjernedates
    brainDatesCount: '{acquiredVideoBraindates} of {totalVideoBraindates} brain dates'
    brainDatesCount_no: '{acquiredVideoBraindates} av {totalVideoBraindates} hjernedates'
</translations>

<script>
import { mapGetters } from 'vuex';
import settings from '@/settings';

export default {
    props: {
        lifeskillId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            totalVideoBraindates: settings.video.maxBraindatesForLifekey,
            braindatesForLifekey: settings.video.braindatesForLifekey,
            isMobile: false
        };
    },

    computed: {
        ...mapGetters({
            getLifeskillStats: 'moduleApp/getLifeskillStats'
        }),

        braindates() {
            return this.$store.getters['moduleApp/braindates']
            .filter(b => b.context === 'video' && b.lifeskillNumber === this.lifeskillId);
        },

        acquiredVideoBraindates() {
          return Math.min(this.braindates.length, this.totalVideoBraindates);
        },

        percentageProgress() {
            return (this.acquiredVideoBraindates / this.totalVideoBraindates) * 100;
        },

        isFirstLifekeyAchieved() {
            return this.acquiredVideoBraindates >= this.braindatesForLifekey;
        },
    },

    created() {
        this.isMobileCheck();

        window.addEventListener('resize', () => {
            this.isMobileCheck();
        });
    },

    methods: {
        isMobileCheck() {
            const mobileBreakpoint = 767;
            const screenWidth = window.screen.width;

            if (screenWidth <= mobileBreakpoint) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.VideosProgressBar {
    background: #1A1E54;
    height: 9.813em;
    padding: 3.13em;
    box-shadow: 0px 2px 23px 3px rgba(79, 79, 79, 0.1);
    @media (max-width: 767px) {
        display: flex;
        align-items: center;
        padding: 1em;
    }
    .content-wrapper {
        @media (max-width: 767px) {
            width: 100%;
        }
        .progress-scale {
            position: relative;
            .title {
                font-weight: bold;
                font-size: 16px;
                line-height: 1.19em;
                color: #EEEEEE;
                display: inline-block;
                @media (max-width: 530px) {
                    font-weight: normal;
                    font-size: 14px;
                }
            }
            .first {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
            .second {
                position: absolute;
                top: 0;
                right: 0;
            }
            .scale-item {
                font-size: 16px;
                color: #9FB4EF;
                @media (max-width: 530px) {
                    font-size: 14px;
                }
            }
        }
        .progress-bar-wrapper {
            background-color: #1F2362;
            height: 0.25em;
            margin-top: 1em;
            border-top-left-radius: 50% 6em;
            border-bottom-left-radius: 50% 6em;
            border-top-right-radius: 50% 6em;
            border-bottom-right-radius: 50% 6em;
            .progress-bar {
                background-color: #305FDF;
                border-top-left-radius: 50% 6em;
                border-bottom-left-radius: 50% 6em;
                border-top-right-radius: 50% 6em;
                border-bottom-right-radius: 50% 6em;
                height: 100%;
            }
        }
        .progress-bottom-scale {
            position: relative;
            margin-top: 1em;
            .info {
                font-size: 16px;
                color: #9FB4EF;
                @media (max-width: 530px) {
                    font-size: 14px;
                }
            }
            .first {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
            .second {
                position: absolute;
                top: 0;
                right: 0;
            }
            .icon {
                fill: gold;
                width: 20px;
                height: 20px;
            }
        }
    }
}

.claimLifekeyButton {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: -1em;
  right: 0;
  // width: 6em;
  padding: 0.6em 1em;
  margin-top: 1em;
  border-radius: 0 0 0 0.3em;
  background-color: #2351CE;
  color: white;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: #2e5bd8;
  }

  .icon {
    fill: #ffaf00;
        min-width: 17px;
        min-height: 17px;
        max-width: 17px;
        max-height: 17px;
        margin-left: 7px;
  }

  @media (max-width: 530px) {
      // width: 8em;
      padding: 0.5em 1em;
      font-size: 0.8em;

      .icon {
        fill: #ffaf00;
            min-width: 15px;
            min-height: 15px;
            max-width: 15px;
            max-height: 15px;
            margin-left: 5px;
      }
  }
}

</style>
