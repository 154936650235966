<template>
  <div
    class="Avatar"
    :class="{dashboardHeader:dashboardHeader}"
    :style="style" />
</template>

<script>
import profileIcon from '@/assets/images/memo-profile-icon.svg';

export default {
    components: {
    },

    props: {
        color: {
            type: String,
            default: ''
        },
        avatar: {
            type: String,
            default: ''
        },
        profile: {
          type: Boolean,
          default: false,
        },
        dashboardHeader: {
          type: Boolean,
          default: false,
        }
    },

    computed: {
      style() {
        return {
          backgroundImage: `url(${this.userAvatar}),url(${profileIcon})`
        };
      },

      userAvatar() {
        let avatarId;
        if (this.profile) {
          avatarId = this.$store.getters['moduleAuth/profile'].avatar;
        } else {
          if (this.avatar) {
            avatarId = this.avatar;
          }
        }
        if (!avatarId) {
          return profileIcon;
        }

        const imageRequest = JSON.stringify({
          "bucket": "uploads.memolife.com",
          "key": `avatar/${avatarId}`,
          "edits": {
            "resize": {
              "width": 200,
              "height": 200,
              "fit": "cover"
            },
          }
        });
        const cloudFrontUrl = 'https://d34p974i19xk3q.cloudfront.net';
        const url = `${cloudFrontUrl}/${btoa(imageRequest)}`;
        return url
      },
    }
};
</script>

<style lang="scss" scoped>
.Avatar {
  border-radius: 50%;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;

  &.dashboardHeader {
    width: 2.2em;
    height: 2.2em;
  }
  
  @media (max-width: 620px) {
    width: 25vw;
    height: 25vw;
    margin-left: 2vw;
  }
}
</style>
