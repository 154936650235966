<template>
  <div class="LanguagesPanel">
    <PanelHeader>
      <div slot="title">
        {{ $t('title') }}
      </div>
      <div slot="text">
        {{ $t('text') }}<br><a
          v-if="$te('readMore')"
          class="readMore"
          href="https://flytoget.no/no/nyheter-og-presse/nyhetsarkiv/memolife/"
          target="_blank">
          {{ $t('readMore') }}
        </a>
      </div>
    </PanelHeader>

    <GridWithExpansion
      class="items"
      :items="languages"
      :index.sync="selectedItemIndex"
      :layout="layout"
      expansion-color="white"
      @select-item="selectItem">
      <DashboardLanguageItem
        slot="item"
        slot-scope="props"
        class="Item"
        :language-id="props.item" />

      <div
        slot="details"
        class="details">
        <h2>Details</h2>
      </div>
    </GridWithExpansion>
  </div>
</template>

<script>
import GridWithExpansion from '@/components/GridWithExpansion';
import DashboardLanguageItem from '../../components/DashboardLanguageItem';

import GridLayoutController from './GridLayoutController';
import PanelHeader from './PanelHeader';

export default {
    components: { DashboardLanguageItem, GridWithExpansion, PanelHeader },
    data() {
        return {
            layout: null,
            selectedItemIndex: -1
        };
    },
    computed: {
        languages() {
            return this.$store.getters['moduleApp/availableLanguages'];
        }
    },
    created() {
        this.disposeLayoutConroller = new GridLayoutController(
            {
                heightRatio: null
            },
            layout => {
                this.layout = layout;
            }
        );
    },
    beforeDestroy() {
        this.disposeLayoutConroller();
    },
    methods: {
        selectItem(x) {
            // alert(x)
        }
    },
    i18n: {
        messages: {
            en: {
                title: 'Learn your new language super-fast',
                text:
                    'Put the accelerated language learning theory to practice with daily exercises, fun memory techniques, dynamic content, spaced repetitions and much more. All you need is 5 minutes per day.'
            },
            no: {
                title: 'Lær det nye språket ditt lynraskt',
                text:
                    'Anvend teorien bak akselerrert språklæring i praksis med daglige øvelser, morsomme husketeknikker, dynamisk innhold, hjernebaserte repetisjoner og mye mer. Alt du trenger er 5 minutter per dag.',
                readMore: 'Lær språk på Flytoget'
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$backgroundColor: mix(black, white, 3%);

.LanguagesPanel {
    background-color: $backgroundColor;
    padding-bottom: 4em;
}

.readMore {
    color: inherit;
}

.items {
    margin-top: 3em;
}
.VideoItem {
    border: 1px solid $defaultItemBorderColor;
}
</style>
