<template>
  <div
    class="Feed"
    @click="isTheoryFeedOrHasActiveScenario && goToFirstActivity()">
    <div class="image-and-informations">
      <div class="image-wrapper">
        <img v-if="isTheoryFeed && nextTheoryJourneyStep.isIntroduction" class="icon" src="@/assets/images/lifeskillBadgeImages/intro-badge.png" alt="">
        <img v-if="isTheoryFeed && !nextTheoryJourneyStep.isIntroduction" class="icon" src="@/assets/images/lifeskillBadgeImages/theory-badge.png" alt="">
        <img v-if="!isTheoryFeed && !currentMentalHygieneStep" class="icon" src="@/assets/images/lifeskillBadgeImages/practice-badge.png" alt="">
        <img v-if="!isTheoryFeed && currentMentalHygieneStep && !mentalHygieneDoneForToday" class="mhIcon" :src="currentMentalHygieneStep.icon" alt="">
        <img v-if="!isTheoryFeed && currentMentalHygieneStep && mentalHygieneDoneForToday" class="icon" src="@/assets/images/lifeskillBadgeImages/practice-badge.png" alt="">
        
        <div
          v-if="isTheoryFeed"
          class="number">
          {{ activeJourney.index + 1 }}
        </div>
        <div
          v-if="currentScenario"
          class="number">
          {{ activeJourney.index + 1 }}
        </div>
        <div
          v-if="currentMentalHygieneStep && mentalHygieneDoneForToday"
          class="number">
           1 
        </div>
      </div>
      <div class="informations-wrapper">
        <p v-if="isTheoryFeed">
          {{ nextTheoryJourneyStep.subtitle }}
        </p>
        <p v-if="currentScenario">
          {{ scenarioSubtitle }}
        </p>
        <p v-if="currentMentalHygieneStep">
          {{ currentMentalHygieneStep.subtitle }}
        </p>
        <h3 v-if="isTheoryFeed">
          {{ nextTheoryJourneyStep.title }}
        </h3>
        <h3 v-if="currentScenario">
          {{ scenarioTitle }}
        </h3>
        <h3 v-if="currentMentalHygieneStep">
            {{ currentMentalHygieneStep.title }}
        </h3>
        <p
          v-if="isTheoryFeed"
          class="text">
          {{ nextTheoryJourneyStep.text }}
        </p>
        <p
          v-if="currentScenario"
          class="text">
          {{ scenarioText }}
        </p>
        <p
          v-if="currentMentalHygieneStep && mentalHygieneDoneForToday"
          class="text">
          {{ currentMentalHygieneStep.text }}
        </p>
      </div>
    </div>
    <div class="button-wrapper">
      <div
        v-if="isTheoryFeedOrHasActiveScenario"
        class="button-blue">
        {{ $t('start') }}
      </div>
      <div
        v-else
        class="button-blue">
        {{ $t('locked') }}
      </div>
    </div>
  </div>
</template>

<translations>
    start: Start
    start_no: Start
    locked: 'Locked'
    locked_no: 'Låst'

    scenarioSubtitle: 'Journey {journeyIndex} - Scenario {nextScenarioIndex}/{totalNumOfScenarios}'
    scenarioSubtitle_no: 'Reise {journeyIndex} - Scenario {nextScenarioIndex}/{totalNumOfScenarios}'
    scenarioJourney: 'Scenario Journey {number}'
    scenarioJourney_no: 'Scenarioreise  {number}'

    lockedPracticeText: 'Complete the theory journey to unlock practice'
    lockedPracticeText_no: 'Fullfør teorireisen for å åpne praksis'
    unlockedPracticeText: 'Complete practice journey to get your life key'
    unlockedPracticeText_no: 'Fullfør praksisreisen for å få livsnøkkelen'
    scenarioTomorrow: 'This scenario opens up tomorrow, see you then!'
    scenarioTomorrow_no: 'Dette scenarioet åpnes i morgen, sees da!'

</translations>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        type: {
            type: String,
            required: true,
            default: ''
        },
        nextTheoryJourneyStep: {
            type: Object,
            required: false,
            default: () => {}
        },
        activeJourney: {
            type: Object,
            required: false,
            default: () => {}
        },
        currentScenario: {
            type: Object,
            required: false,
            default: () => {}
        },
        lifeskillId: {
            type: String,
            required: false,
            default: ''
        },
        currentScenarioInfo: {
            type: Object,
            required: false,
            default: () => {}
        },
        courseId: {
            type: String,
            required: false,
            default: ''
        },
        currentMentalHygieneStep: {
            type: Object,
            required: false,
            default: () => {}
        },
    },


    computed: {
        ...mapGetters({
            getStepById: 'moduleCourse/getStepById',
            courseByLifeskillId: 'moduleCourse/courseByLifeskillId',
            mentalHygieneDoneForToday: 'moduleMentalhygiene/doneToday',
        }),

        hasActiveScenario(){
            return this.currentScenario && this.$store.getters['scenario/isOpen'](this.currentScenario.id);
        },

        hasActiveMentalHygieneStep() {
            return this.currentMentalHygieneStep && !this.mentalHygieneDoneForToday;
        },

        isTheoryFeed() {
            return this.type === 'theory';
        },

        isTheoryFeedOrHasActiveScenario() {
            return this.isTheoryFeed || this.hasActiveScenario || this.hasActiveMentalHygieneStep;
        },


        isScenarioOpen(){
            return this.$store.getters['scenario/isOpen'](this.currentScenario.id);
        },
        scenarioSubtitle(){
              const nextScenarioIndex = this.isScenarioOpen? this.currentScenario.nextScenarioIndex : this.currentScenario.nextScenarioIndex+1;
              const totalNumOfScenarios = this.currentScenario.totalNumOfScenarios;
              const journeyIndex = this.currentScenario.journey.index+1;

              return this.$t('scenarioSubtitle', {
                nextScenarioIndex,
                totalNumOfScenarios,
                journeyIndex
              });
        },
        scenarioTitle(){
            let title = this.$t('scenarioJourney');
            const step = this.currentScenario.step;
            if (step) {
              title = step.title;
            }
            return title;
        },
        scenarioText(){
            if(this.currentScenario.journey.quiz.done){
              return this.isScenarioOpen ? this.$t('unlockedPracticeText') : this.$t('scenarioTomorrow');
            } else {
              return this.$t('lockedPracticeText');
            } 
        }

    },

    methods: {
        goToFirstActivity() {
            this.isTheoryFeed ? this.goToTheory() : this.goToActiveScenario();
        },

        goToTheory() {
            const isNextStepQuiz = this.nextTheoryJourneyStep.isQuiz;

            isNextStepQuiz ? this.goToQuiz() : this.goToFirstTheoryJourney();
        },

        goToQuiz() {
            this.$router.push({
                name: 'StepGroupQuizView',
                params: {
                    courseId: this.courseId,
                    stepGroupId: this.activeJourney.id
                }
            });
        },

        goToFirstTheoryJourney() {
            this.$router.push({
                name: 'Course-ActivityTransition',
                params: {
                    courseId: this.nextTheoryJourneyStep.courseId,
                    stepId: this.nextTheoryJourneyStep.stepId,
                    activityName: this.nextTheoryJourneyStep.activities[0]
                }
            });
        },

        goToActiveScenario() {
            if(this.currentMentalHygieneStep){
                this.$router.push({
                    name: 'MentalHygiene',
                    params: {
                        setInitialTab: true,
                        stepNumber: this.currentMentalHygieneStep.stepNumber
                    }
                });
            } else{
                this.$router.push({
                    name: 'ScenarioView',
                    params: {
                        id: this.currentScenario.id,
                        lifeskillId: this.lifeskillId,
                        stepId: this.currentScenario.step.id,
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.Feed {
    display: flex;
    background: #1A1E54;
    box-shadow: 0px 2px 23px 3px rgba(79, 79, 79, 0.1);
    border-radius: 4px;
    padding: 1.2em 18.5px;
    width: 100%;
    position: relative;
    justify-content: space-between;
    margin: 1em 0;
    cursor: pointer;
    &:first-of-type {
        margin-top: 0;
    }
    @media (max-width: 530px) {
        padding: 1.2em 8px;
    }
}
.image-and-informations {
    display: flex;
    align-items: center;
}
.image-wrapper {
    min-width: 97px;
    min-height: 97px;
    max-width: 97px;
    max-height: 97px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width: 530px) {
        min-width: 68px;
        min-height: 68px;
        max-width: 68px;
        max-height: 68px;
    }

    .icon {
        width: 100%;
        height: 100%;
    }
    .mhIcon {
        width: 80%;
        height: 80%;
    }
    .number {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
        color: white;
        @media (max-width: 530px) {
            font-size: 10px;
        }
    }
}
.informations-wrapper {
    padding: 0 20px;
    @media (max-width: 530px) {
        padding: 0 10px;
    }

    p {
        margin: 0;
        margin-top: 5px;
        color: #99B1BF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        @media (max-width: 530px) {
            font-size: 14px;
        }
    }
    h3 {
        font-size: 22px;
        font-weight: 600;
        line-height: 26px;
        margin: 0;
        color: #EEEEEE;
        max-width: 600px;
        padding-right: 2em;
        @media (max-width: 530px) {
            font-size: 16px;
            padding-right: 0.5em;
        }
    }
    .text {
        margin-top: 0.8em;
        @media (max-width: 530px) {
            margin-top: 0.25em;
        }
    }
}
.button-wrapper {
    display: flex;
    align-items: center;
    .button-blue {
        cursor: pointer;
    }
}
</style>
