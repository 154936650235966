<template>
  <div
    v-if="course"
    class="DashboardCourseDetails">
    <CourseToc
      class="tocSection"
      :course-id="courseId" />
    <div>
      <About
        popup-type="theory"
        :lifeskill-id="course.lifeskillNumber" />

      <TeamLifekeys
        v-if="course.lifeskillNumber"
        :lifeskill="course.lifeskillNumber"
        section="Theory" />
    </div>
  </div>
</template>

<translations>
  abstractSectionTitle: 'About Course'
  abstractSectionTitle_no: 'Om kurset'
</translations>

<script>
import CourseToc from '@/modules/course/components/CourseToc';
import About from '@/modules/dashboard/components/About';
import TeamLifekeys from '@/modules/team/TeamLifekeys';

export default {
    components: {
        CourseToc,
        About,
        TeamLifekeys,
    },

    inject: ['theme'],

    props: {
        courseId: {
            type: String,
            required: true
        },
        showImage: {
            type: Boolean,
            default: false
        },
        hideTitle: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        course() {
            return this.$store.state.moduleCourse.courses[this.courseId];
        }
    }
};
</script>

<style lang="scss" scoped>
.DashboardCourseDetails {
    position: relative;
    padding-bottom: 4em;
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 886px) {
        flex-direction: column;
        align-items: center;
        padding-top: 0;
    }

    .tocSection {
        width: 100%;
        margin-right: 30px;
        @media (max-width: 886px) {
            padding-bottom: 30px;
        }
        @media (max-width: 886px) {
            margin-right: 0;
        }
    }
}

.image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 1em;

    &::after {
        content: '';
        display: block;
        padding-bottom: percentage(9 / 16);
    }
}

.About {
  padding-bottom: 2em;
}

.title {
    font-size: 140%;
    text-transform: lowercase;
    &::first-letter {
        text-transform: uppercase;
    }
}

.abstractSectionTitle {
    font-size: 120%;
    font-weight: 600;
}
.abstract {
    margin-top: 1em;
    line-height: 1.5em;
    color: rgba(black, 0.7);
}

.progressBox {
    width: 100%;
    margin-bottom: 2em;
}

.AboutAuthor {
    padding: 1em 0;
    border-top: 1px solid rgba(black, 0.05);
    border-bottom: 1px solid rgba(black, 0.05);
}
</style>
