<template>
  <SimpleSlides
    ref="simpleSlides"
    class="PracticeOnboardingPopup"
    :button-label="$t(`getStarted`)"
    @update:index="activeSlideIndex = $event"
    @done="$emit('signal', 'done')">
    <template slot="header">
      <div class="Slide__title">
        {{ $t('title') }}
      </div>
      <div class="Slide__subtitle">
        <div class="StepActivitiesIndicator">
          <template v-for="(labelKey, i) of [$t('slide1.tab'), $t('slide2.tab'), $t('slide3.tab')]">
            <div
              :key="i + '-a'"
              v-t="labelKey"
              class="StepActivitiesIndicator-Activity"
              :class="{ 'is-active': activeSlideIndex === i }"
              @click="setActiveSlideIndex(i)" />
            <svg
              v-if="i < 2"
              :key="i + '-b'"
              class="StepActivitiesIndicator__chevron"
              viewBox="0 0 35 100">
              <path
                class="arrow"
                d="M 0,0 L 30,50 L 0,100" />
            </svg>
          </template>
        </div>
      </div>
    </template>

    <template slot="slides">
      <div
        v-for="i of 3"
        :key="i"
        class="Slide">
        <div
          class="Slide__image"
          :style="{ backgroundImage: `url(${getSlideImage(i)})` }" />
        <div class="Slide__title">
          {{ $t(`slide${i}.title`) }}
        </div>
        <div class="Slide__text">
          {{ $t(`slide${i}.text`) }}
        </div>
      </div>
    </template>
  </SimpleSlides>
</template>

<translations>
  title: 'Ace New Habits'
  title_no: 'Skap nye vaner'

  getStarted: 'Get Started'
  getStarted_no: 'Kom i gang'

  slide1:
    tab: 'Trigger'
    title: 'Trigger your scenarios'
    title_no: 'Bli trigget til scenarier'

    text: 'Choose something you normally do in everyday life as a trigger to remind you of when & where to do your daily scenarios.'
    text_no: 'Velg noe du vanligvis gjør i hverdagen som en trigger for å minne deg på når og hvor du tar dine daglige scenarioer.'

  slide2:
    tab: 'Scenario'
    title: 'Let scenarios give mastery'
    title_no: 'La scenariene gi mestring'
    text: 'Complete your daily scenario with a brain date on Memolife and mastery in everyday life as a reward.'
    text_no: 'Fullfør dagens scenario med en ny hjernedate på Memolife og bedre mestring i hverdagen som belønning.'

  slide3:
    tab: 'Time'
    tab_no: 'Tid'
    title: 'Get mastery over time'
    title_no: 'Få nok mestring over tid'
    text: 'Scenario training for roughly 66 days will lead to a new skill diploma in Memolife and a new habit in real life.'
    text_no: 'Scenariotrening i circa 66 dager vil både gi deg nytt ferdighets-diplom på Memolife og ny vane i hverdagen.'
</translations>

<script>
import SimpleSlides from '@/components/SimpleSlides';
import img1 from './img1.svg';
import img2 from './img2.svg';
import img3 from './img3.svg';

export default {
    components: { SimpleSlides },
    data() {
        return {
            activeSlideIndex: 1
        };
    },
    mounted() {
        this.activeSlideIndex = this.$refs.simpleSlides.activeSlideIndex;
    },
    methods: {
        getSlideImage(slideNumber) {
            return [img1, img2, img3][slideNumber - 1];
        },
        setActiveSlideIndex(index) {
            this.$refs.simpleSlides.activeSlideIndex = index;
        }
    }
};
</script>

<style lang="scss" scoped>
.PracticeOnboardingPopup {
    width: 40em;
}

.Slide {
    text-align: center;
    padding: 2em 0;
}

.Slide__image {
    $size: 14em;
    width: $size;
    height: $size;
    margin: auto;

    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
}

@include media('<=mobile') {
    .Slide__image {
        $size: 10em;
        width: $size;
        height: $size;
    }

    .Slide {
        padding: 1em 0;
    }
}

.Slide__title {
    font-size: 130%;
    font-weight: 600;
    text-align: center;
}
.Slide__text {
    line-height: 1.5em;
    margin: auto;
    margin-top: 1em;
    max-width: 30em;
}

.StepActivitiesIndicator {
    display: flex;
    position: relative;
    align-items: center;
    font-weight: 300;
    justify-content: center;
    width: 100%;
}
.StepActivitiesIndicator-Activity {
    padding: 1em;
    color: rgba(black, 0.9);
    &:not(.is-active) {
        color: rgba(black, 0.4);
    }
}
.StepActivitiesIndicator__chevron {
    height: 2em;
    fill: none;
    stroke: rgba(black, 0.1);
    stroke-width: 2px;
    flex-shrink: 0;
}

.closeButton {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1em;

    .svg-icon {
        $size: 0.8em;
        width: $size;
        height: $size;
        fill: rgba(white, 0.9);
    }
}
</style>
