<template>
  <div class="SectionMemorySystems">
    <DashboardWidthWrapper>
      <div
        class="text"
        v-html="$t('text')" />
    </DashboardWidthWrapper>

    <GridWithExpansion
      class="items"
      :items="systems"
      :index.sync="selectedItemIndex"
      :layout="layout"
      expansion-color="#1b1a1a"
      @select-item="selectItem">
      <DashboardMemorySystemItem
        slot="item"
        slot-scope="props"
        class="Item"
        :system-id="props.item" />

      <DashboardMemorySystemDetails
        slot="detail"
        :key="selectedSystem"
        :system-id="selectedSystem"
        :index="selectedItemIndex" />
    </GridWithExpansion>
  </div>
</template>

<translations>
  text: 'Learn the memory systems to level up in the practice games'
  text_no: 'Lær huskesystemene for å level up i praksis-spillene'
</translations>

<script>
import GridWithExpansion from '@/components/GridWithExpansion';

import DashboardMemorySystemItem from '@/modules/dashboard/components/DashboardMemorySystemItem';
import DashboardMemorySystemDetails from '@/modules/dashboard/components/DashboardMemorySystemDetails';
import DashboardWidthWrapper from '@/modules/dashboard/components/DashboardWidthWrapper';

import GridLayoutController from '../GridLayoutController';

export default {
    components: { DashboardMemorySystemItem, DashboardWidthWrapper, GridWithExpansion, DashboardMemorySystemDetails },
    data() {
        return {
            layout: null,
            selectedItemIndex: -1
        };
    },
    computed: {
        systems() {
            return this.$store.getters['moduleMemogenius/getSystemIds'];
        },
        selectedSystem() {
            return this.systems[this.selectedItemIndex] || null;
        }
    },
    created() {
        this.disposeLayoutConroller = new GridLayoutController(
            {
                heightRatio: null
            },
            layout => {
                this.layout = layout;
            }
        );
    },
    beforeDestroy() {
        this.disposeLayoutConroller();
    },
    methods: {
        selectItem(x) {
            // alert(x)
        }
    }
};
</script>

<style lang="scss" scoped>
.SectionMemorySystems {
    padding-top: 2em;
}

.text {
    margin-bottom: 2em;
    line-height: 1.5em;
    color: rgba(black, 0.6);
}

.Item {
    border: 1px solid $defaultItemBorderColor;
}
</style>
