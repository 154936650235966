<template>
  <div class="DashboardAccountPopup">
    <div class="entries">

      <div class="Entry">
        <div
          v-t="'statistics'"
          class="Entry__link --global-clickable"
          @click="gotoStatistics()" />
      </div>

      <div class="Entry">
        <div
          v-t="'teamprogress'"
          class="Entry__link --global-clickable"
          @click="gotoLeaderboard()" />
      </div>

      <div class="Entry">
        <div
          v-t="'account'"
          class="Entry__link --global-clickable"
          @click="gotoAccount()" />
      </div>

      <div class="Entry">
        <div
          v-t="'feedback'"
          class="Entry__link feedbackItem --global-clickable"
          @click="openSupport()">
          Send us Feedback
          <!-- <svgicon class="feedbackIcon" icon="envelope"></svgicon> -->
        </div>
      </div>

      <!-- <div class="Entry">
        <div class="Entry__link" @click="signOut()" v-t="'logout'"></div>
      </div> -->
      <!-- <div class="Entry">
        <div class="Entry__link --global-clickable" v-t="'changegoals'" @click="gotoGoalSettings()">Change Daily Goals</div>
      </div> -->

      <div class="Entry">
        <div
          v-t="'whatsNew'"
          class="Entry__link --global-clickable"
          @click="showNewFeatures()" />
      </div>

      <div class="Entry">
        <div
          v-t="'logout'"
          class="Entry__link --global-clickable"
          @click="signOut()" />
      </div>

      <div class="Entry">
        <LanguageSwitch class="languageSwitch" />
      </div>
    </div>
  </div>
</template>

<translations>
  account: 'Account details'
  account_no: 'Ditt medlemskap'
  upgrade: 'Upgrade'
  upgrade_no: 'Oppgrader'
  logout: 'Sign out'
  logout_no: 'Logg ut'
  profile: 'Profile'
  profile_no: 'Profil'
  changegoals: 'Change daily goals'
  changegoals_no: 'Endre ditt daglige mål'
  statistics: 'My progress'
  statistics_no: 'Min fremgang'
  whatsNew: 'What''s new?'
  whatsNew_no: 'Hva er nytt?'
  feedback: 'Send us feedback'
  feedback_no: 'Gi en tilbakemelding'
  teamprogress: 'Team progress'
  teamprogress_no: 'Team-fremgang'
</translations>

<script>
import LanguageSwitch from '@/components/LanguageSwitch';
import { EventBus } from '@/event-bus';
import whatsnew from '@/whatsnew';

export default {
    components: { LanguageSwitch },
    computed: {
        canUpgrade() {
            return this.$can('upgrade', { modelName: 'Account' });
        }
    },
    created() {
        this.showWhatsnewEntry = whatsnew.currentId;
    },
    methods: {
        async gotoAccount() {
            const portalUrl = await this.$store.dispatch('moduleAuth/getPortalUrl');
            window.location.href = portalUrl;
        },
        gotoLeaderboard(){
          this.$emit('signal', 'close');
          this.$router.push({
              name: 'Leaderboard'
          });
        },
        gotoCheckout() {
            this.$emit('signal', 'close');
            this.$router.push({
                name: 'AccountCheckoutView',
                params: { plan: 'month' }
            });
        },
        gotoStatistics() {
            this.$emit('signal', 'close');
            if (this.$route.name === 'DashboardProfileLifeViewWithTab') {
                return;
            }
            this.$router.push({
                name: 'DashboardProfileView'
            });
        },
        showNewFeatures() {
            this.$emit('signal', 'close');
            EventBus.$emit('showWhatsNew');
        },
        openSupport() {
            this.$intercom.show();
        },
        async signOut() {
            this.$emit('signal', 'close');
            this.$router.push({
                name: 'AccountLogoutView'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.DashboardAccountPopup {
    background-color: white;
    // padding: 1em;
    min-width: 20em;
    border-radius: 0.5em;
}

.Entry {
    &.has-button {
        text-align: center;
        padding: 1em;
    }

    &:not(:last-child) {
        border-bottom: 1px solid rgba(black, 0.05);
    }
    &:hover {
        // background-color: rgba(black, 0.1);
    }
}

.Entry__link {
    // padding: 1em 0;
    min-height: 5em;

    padding: 1em 1em;
    display: flex;
    align-items: center;
    color: rgba(black, 0.8);
}

.feedbackItem {
    // display: flex;
    // align-items: center;
}
.feedbackIcon {
    display: inline-block;
    $size: 1.6em;
    width: $size;
    height: $size;
    fill: black;
    margin-left: 1em;
}

.languageSwitch {
    // padding: 1em;
    padding-right: 0;
}
</style>
