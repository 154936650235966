<template>
  <div
    class="StepBullet"
    :class="{ completed, current }"
    :style="{ backgroundColor: color, '--local-color': color }">
    <CircularProgress
      v-if="progress < 1"
      class="progress"
      :progress="progress"
      :color="color"
      track-color="rgba(0, 0, 0, 0.0)" />
    <svgicon
      v-if="progress === 1"
      class="icon icon--checkmark"
      icon="checkmark" />
    <svgicon
      v-else
      class="icon"
      :class="`icon--${icon}`"
      :icon="icon"
      :style="{ fill: color }" />
  </div>
</template>

<script>
import CircularProgress from '@/components/CircularProgress';
import { cssVariables } from '@/category-themes';

export default {
    components: { CircularProgress },
    props: {
        progress: {
            type: Number,
            default: 0
        },
        icon: {
            type: String,
            required: true
        },
        current: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: cssVariables.completionColor
        }
    },
    computed: {
        completed() {
            return this.progress === 1;
        }
    }
};
</script>

<style lang="scss" scoped>
.StepBullet {
    $bulletSize: 2.2em;

    position: relative;
    width: $bulletSize;
    height: $bulletSize;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 530px) {
        width: 31px;
        height: 31px;
    }

    // background-color: white;

    &.completed {
        .icon {
            // fill: rgba(black, 0.6) !important;
            fill: white !important;
        }
    }
    &:not(.completed):not(.current) {
        background-color: white !important;
        .icon {
            fill: rgba(black, 0.6) !important;
        }
    }
    &.current {
        background-color: white !important;
        .icon {
            // fill: rgba(red, 0.6) !important;
            // fill: white !important;
        }

        animation: pulse-animation2 3s ease 1s infinite;

        $shadowColor: black;
        box-shadow: 0 0 0 0 rgba($shadowColor, 0.5);

        @keyframes pulse-animation2 {
            0% {
                box-shadow: 0 0 0 0 var(--local-color, $shadowColor);
            }
            50% {
                box-shadow: 0 0 0 0.5em transparent;
            }
            100% {
                box-shadow: 0 0 0 0 transparent;
            }
        }
    }
}

.progress {
    position: absolute;
    $offset: 1px;
    top: -$offset;
    right: -$offset;
    bottom: -$offset;
    left: -$offset;
}
.icon {
    $size: 50%;
    width: $size;
    height: $size;
}
.icon--flag-with-checkmark {
    $size: 40%;
    width: $size;
    height: $size;
}
.icon--checkmark {
    $size: 40%;
    width: $size;
    height: $size;
}
</style>
