<template>
  <div
    class="StepItemActivity"
    :class="{ locked, '--global-clickable': !locked, pulsate, completed: progress === 1 }"
    @click.stop="!locked && $emit('click')">
    <svgicon
      class="icon"
      :icon="icon" />
  </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: true
        },
        locked: {
            type: Boolean,
            default: false
        },
        progress: {
            type: Number,
            default: 0
        },
        pulsate: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        activities() {
            return this.step.activityNames.map(name => this.step[name]);
        }
    }
};
</script>

<style lang="scss" scoped>
$normalBgColor: #1f2362;
$normalFillColor: #5983F7;

$completedBgColor: #2351CE;
$completedFillColor: #A3B9F9;

$itemSize: 2.2em;

.StepItemActivity {
    $size: $itemSize;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: $normalBgColor;
    transition: transform 0.3s;
    backface-visibility: hidden; // NOTE: Might help to reduce pixel bugs at end of transform
    display: flex;
    border: 1.5px solid #2351ce;
    align-items: center;
    justify-content: center;
    @media (max-width: 530px) {
        width: 28px;
        height: 28px;
    }

    &.completed {
        background-color: $completedBgColor;
        border: none;
        .svg-icon {
            fill: $completedFillColor;
        }
    }

    &.locked {
        border: none;
        .svg-icon {
            fill: #374d9e;
        }
    }

    &:not(.locked):hover {
        transform: scale(1.2);
        .svg-icon {
            fill: #cad7ff;
        }
    }
}

.svg-icon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: $normalFillColor;

    @media (max-width: 530px) {
        width: 13px;
        height: 13px;
    }
}
</style>
