<template>
  <div class="LifeskillLoadingRive">
    <canvas ref="canvas" id="lifeskillCanvas" class="riveContainer" width="2000px" height="1000px" />
  </div>
</template>


<script>
const rive = require("rive-js");
import RiveFile from '@/assets/rive/lifeskill-loading.riv'
import { getCategoryIdFromLifeskillId } from '@/utils';

export default {

    props: {
        lifeskillId: {
            type: String,
            required: true
        },
    },
  
  data() {
    return {
      r: null,
      inputs: null,
    };
  },

  mounted() {
    this.setRive();
  },

  computed: {
      getCategoryNumber() {
        return {
            'brain': 1,
            'soul': 2,
            'knowledge': 3,
            'leisure': 4,
            'mind': 5,
            'career': 6,
            'body': 7,
        }[this.category];
      },

      category() {
          return getCategoryIdFromLifeskillId(this.lifeskillId);
      },
      lifeskill() {
          return this.$store.state.moduleApp.lifeskills[this.lifeskillId];
      }
  },

  beforeDestroy(){
   this.r.stop();
   this.r.unsubscribeAll();
   this.r = null;
  },

  methods: {
    setRive(){
      this.r = new rive.Rive({
          src: RiveFile,
          canvas: this.$refs.canvas,
          stateMachines: 'lifeskill_loading_states',
          autoplay: true,
          layout: new rive.Layout({fit: 'fill', alignment: 'center'}),
          onload: () => {
            this.inputs = this.r.stateMachineInputs('lifeskill_loading_states');

            this.sunColorNumber(this.getCategoryNumber);
            this.lifeskillNumber(parseInt(this.lifeskillId));
          },
      }); 
    },

    sunColorNumber(v){
      this.inputs[1].value = v;
    },

    lifeskillNumber(v){
      this.inputs[0].value = v;
    },
  },
  
};
</script>

<style scoped lang="scss">

.riveContainer {
    // position: absolute;
    width: 100em;
    
  @media (max-width: 620px) {
    width: 150vw;
  }
}
#canvas {
    border: 1px solid red;
}

</style>
