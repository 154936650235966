<template>
    <div class="StepItem" :data-step-id="stepId" @click="locked ? openPopup() : gotoFirstActivity()">
        <div class="item-wrapper">
            <div class="image" :style="{ backgroundImage: `url(${step.image})` }" />
            <div class="content">
                <div v-if="step.number" class="stepNumber gray-text">
                    <span class="stepLabel">{{ $tc('dashboard.general.step') }} </span> {{ step.number }}
                </div>
                <div class="bold-title" :class="{ useEllipsis }" v-html="step.title" />
                <div class="spacer" />

                <div v-if="!locked" class="activities">
                    <StepItemActivity
                        v-for="(activity, i) of activities"
                        :key="i"
                        v-bind="activity"
                        class="Activity"
                        @click="gotoView(activity.name)"
                    />
                </div>
            </div>
            <div v-if="!locked" class="button-wrapper">
                <a class="button-blue" @click="gotoView('read')">Start</a>
            </div>
            <a v-else class="button-blue">Upgrade</a>
        </div>
        <StepBullet :progress="step.progress" :current="current" icon="steps-3" />
    </div>
</template>

<translations>
  locked: 'locked'
  locked_no: 'Låst'
</translations>

<script>
import ThemeMixin from '../theme-mixin';
import StepItemActivity from './StepItemActivity';
import StepBullet from './StepBullet';
import UpgradePopup from '@/modules/dashboard/components/UpgradePopup';

export default {
    components: { StepItemActivity, StepBullet },
    mixins: [ThemeMixin],
    props: {
        courseId: {
            type: String,
            required: true,
        },
        stepId: {
            type: String,
            required: true,
        },
        current: {
            type: Boolean,
            default: false,
        },
        useEllipsis: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        activities() {
            function isMandatory(n) {
                return ['read', 'quiz', 'quiz2'].indexOf(n) !== -1;
            }
            let precedingMandatoryActivitiesCompleted = true;
            let didAssignPulsation = false;

            return this.step.activityNames.map((activityName, i) => {
                const activity = this.step[activityName];

                // rule for pulsation: first mandatory task not completed
                const pulsate = !didAssignPulsation && activity.progress < 1 && precedingMandatoryActivitiesCompleted;
                if (pulsate) {
                    didAssignPulsation = true;
                }

                const locked = !precedingMandatoryActivitiesCompleted;
                if (isMandatory(activityName) && activity.progress < 1) {
                    precedingMandatoryActivitiesCompleted = false;
                }

                return {
                    name: activityName,
                    icon: this.iconMap[activityName],
                    progress: activity.progress,
                    locked,
                    pulsate,
                };
            });
        },
        locked() {
            return !this.$can('view', this.step);
        },
    },
    created() {
        this.iconMap = {
            read: 'activities/read',
            memorize: 'activities/memo-overview',
            quiz: 'activities/quiz',
            memorize2: 'activities/memo-details',
            quiz2: 'activities/quiz',
        };
    },
    methods: {
        gotoView(activityName) {
            this.$router.push({
                name: 'Course-ActivityTransition',
                params: { courseId: this.courseId, stepId: this.stepId, activityName },
            });
        },
        gotoFirstActivity() {
            this.gotoView(this.step.activityNames[0]);
        },
        openPopup() {
            this.$modal.open({
                component: UpgradePopup,
                target: 'app-modal',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.upgrade-button {
    margin-left: 20px;

    @media (max-width: 400px) {
        display: none;
    }
}

.StepItem {
    position: relative;
    border-radius: 1em;
    display: flex;
    cursor: pointer;
}
.item-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 22px;
    border-bottom: 1px solid rgba(133, 133, 133, 0.12);
    .content {
        width: 100%;
        margin-left: 26px;
        padding-right: 15px;
        @media (max-width: 530px) {
            margin-left: 15px;
            padding-right: 0;
        }
    }
}

.image {
    min-width: 6.1em;
    min-height: 6.1em;
    border-radius: 13.3405px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 530px) {
        align-self: flex-start;
        min-width: 68px;
        min-height: 68px;
    }
}

.spacer {
    flex: 1;
}

.stepLabel {
    text-transform: capitalize;
    @media (max-width: 530px) {
        font-size: 14px;
    }
}
.stepNumber {
    text-transform: uppercase;
}
.bold-title {
    margin-bottom: 1.25em;
    color: #EEEEEE;
    @media (max-width: 530px) {
        font-size: 16px;
        margin-bottom: 0.5em;
    }
}

.activities {
    display: flex;
    margin-top: 1em;
}
.Activity {
    &:not(:first-child) {
        margin-left: 1em;
        @media (max-width: 530px) {
            margin-left: 0.8em;
        }
    }
}

.StepBullet {
    display: none;
    position: absolute;
    top: -0.5em;
    right: -0.5em;
}

 .button-blue {
    cursor: pointer;
    @media (max-width: 600px) {
        display: none;
    }
}

.gray-text {
    color: #99B1BF;
    @media (max-width: 530px) {
        font-size: 14px;
    }
}
</style>
