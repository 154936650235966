<template>
  <div class="PlaylistCarousel">
    <div class="carousel-top-section">
      <div class="top-section-left-side">
        <h3>{{ playlist.name }}</h3>
        <p>{{ playlist.videos.length + ' ' + $t('videos') }}</p>
      </div>
      <div class="top-section-right-side">
        <button class="view-all-button" @click="handleViewAll">
          {{ viewAll ? $t('viewSome') : $t('viewMore') }}
        </button>
        <button v-if="!viewAll" class="direction-button" @click="handleMoveCarouselRight">
          <svgicon class="carousel-icon" icon="carousel-arrow-left" />
        </button>
        <button v-if="!viewAll" class="direction-button" @click="handleMoveCarouselLeft">
          <svgicon class="carousel-icon" icon="carousel-arrow-right" />
        </button>
      </div>
    </div>

    <div class="carousel">
      <div
        class="slider"
        :style="{
          transform: `translateX(${carouselPosition}%)`,
          flexWrap: viewAll ? 'wrap' : 'nowrap',
          transition: viewAll ? 'none' : '0.5s',
        }"
        @mousedown="mouseDown"
        @mouseup="mouseUp">
        <VideoItem
          v-for="video in playlist.videos"
          :key="video.id"
          class="video-item"
          :style="{
            width: videoWidth,
            minWidth: videoWidth,
          }"
          :video-id="video.id"
          :is-dragging="isDragging"
          :lifeskill-id="lifeskillId"
          @clicked-video="handleClickedVideo" />
      </div>
    </div>
  </div>
</template>

<translations>
    viewMore: View More
    viewMore_no: Se flere
    viewSome: View Some
    viewSome_no: Se noen
    videos: videos
    videos_no: videoer
</translations>

<script>
import VideoItem from '@/modules/dashboard/views/MemolifeTvView/VideoItem';

export default {
  components: {
    VideoItem,
  },
  props: {
    playlist: {
      type: Object,
      required: true,
    },
    lifeskillId: {
        type: String,
        default: null
    },
  },

  data() {
    return {
      viewAll: false,
      carouselPosition: 0,
      videosToShowCount: 3,
      dragging: {
        startX: null,
        endX: null,
      },
    };
  },

  computed: {
    maxCarouselLeftPosition() {
      return Math.floor(-this.playlist.videos.length / this.videosToShowCount) * 100 + 100;
    },

    videoWidth() {
      return 100 / this.videosToShowCount + '%';
    },

    isDragging() {
      return this.dragging.startX !== this.dragging.endX;
    },
  },

  created() {
    this.setVideosToShowCount();

    window.addEventListener('resize', this.setVideosToShowCount);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setVideosToShowCount);
  },

  methods: {
    handleMoveCarouselLeft() {
      if (!this.viewAll) {
        if (this.carouselPosition > this.maxCarouselLeftPosition) {
          this.carouselPosition -= 100;
        }
      }
    },

    handleMoveCarouselRight() {
      if (!this.viewAll) {
        if (this.carouselPosition < 0) {
          this.carouselPosition += 100;
        }
      }
    },

    handleClickedVideo(videoId) {
      this.$store.commit('moduleApp/updateVideosPageState', {
        selectedVideoId: videoId,
      });
    },

    handleViewAll() {
      this.viewAll = !this.viewAll;
      this.carouselPosition = 0;
    },

    mouseDown(event) {
      this.dragging.startX = event.clientX;
    },

    mouseUp(event) {
      this.dragging.endX = event.clientX;

      if (this.dragging.startX < this.dragging.endX) {
        this.handleMoveCarouselRight();
      } else {
        this.handleMoveCarouselLeft();
      }
    },

    setVideosToShowCount() {
      const screenWidth = window.screen.width;

      this.carouselPosition = 0; // set initial carousel position

      if (screenWidth > 767) {
        this.videosToShowCount = 3;
      } else if (screenWidth > 530) {
        this.videosToShowCount = 2;
      } else {
        this.videosToShowCount = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.PlaylistCarousel {
  $purple: #5983f7;

  margin-top: 2em;

  .carousel {
    overflow-x: hidden;
    width: 100%;

    .slider {
      display: flex;
      .video-item {
        padding: 0 5px;
      }
    }
  }

  .carousel-top-section {
    display: flex;
    justify-content: space-between;

    .top-section-left-side {
      display: flex;
      align-items: center;
      h3 {
        font-size: 24px;
        font-weight: 600;
        color: #EEEEEE;
        @media (max-width: 530px) {
          font-size: 20px;
        }
      }
      p {
        margin-left: 1em;
        font-size: 1em;
        color: $purple;
        @media (max-width: 530px) {
          font-size: 16px;
        }
      }
    }

    .top-section-right-side {
      display: flex;
      align-items: center;
      button:not(:last-child) {
        margin-right: 1em;
      }
      .view-all-button {
        color: $purple;
        background-color: inherit;
        border: none;
        font-size: 1em;
        cursor: pointer;
        outline: none;
        @media (max-width: 530px) {
          display: none;
        }
      }
      .direction-button {
        padding: 7px 7px 4px 7px;
        background-color: inherit;
        border: none;
        border-radius: 0.3em;
        cursor: pointer;
        outline: none;

        &:hover {
          background: rgba(140, 171, 255, 0.15);
        }
        .carousel-icon {
          width: 0.7em;
          height: 0.9em;
          fill: $purple;
        }
      }
    }
  }
}
</style>
