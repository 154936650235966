<template>
  <div class="PaymentFailurePopup">
    <div class="title">
      {{ $t('title') }}
    </div>
    <div class="subtitle">
      {{ $t('subtitle') }}
    </div>
    <div class="text">
      {{ $t('text') }}
    </div>
    <div class="list">
      <div
        v-for="(item, i) of items"
        :key="i"
        class="Item">
        <div class="Item__checkmark">
          <svgicon
            class="Item__checkmarkIcon"
            icon="checkmark" />
        </div>
        <div class="Item__text">
          {{ $t(`item${i + 1}`) }}
        </div>
      </div>
    </div>
    <div class="footer">
      <StandardButton
        :label="$t('upgradeButton')"
        @click="gotoPortal()" />
    </div>
  </div>
</template>

<translations>
  title: 'Missing Payments'
  title_no: 'Manglende Betaling'
  subtitle: 'Update your payment info'
  subtitle_no: 'Oppdater din betalingsinformasjon'
  upgradeButton: 'Update now'
  upgradeButton_no: 'Oppdater nå'

  text: 'You have missing payments for your subscription. Please update your payment information to continue using Memolife'
  text_no: 'Du har manglende betalinger for ditt medlemskap. Vennligst oppdater din betalingsinformasjon for å fortsatt bruke Memolife'

  # item1: 'Access to all games and levels'
  # item2: '9 full courses available'
  # item3: 'Memory Techniques'
  # item4: 'Course and video missions'
  # item5: 'And much more'

  item1: '9 ferdigheter med teori og praksis'
  item2: '100 ferdigheter med 2500+ videoer'
  item3: 'Tilgang til alle språk, huskespill og nivåer'
  item4: 'Påminnelser før hjernen din glemmer'
  item5: 'Daglig scenariotrening'
</translations>

<script>
import StandardButton from '@/components/StandardButton';

export default {
    components: { StandardButton },
    props: {},
    created() {
        this.items = [{}, {}, {}, {}, {}];
    },
    mounted() {
        window.dataLayer.push({
            event: 'payment_failure_popup'
        });
    },
    methods: {
        gotoPortal() {
            window.dataLayer.push({
                event: 'payment_failure_popup_goto_portal'
            });
            this.$emit('signal');
            this.$router.push({
                name: 'AccountPortalView'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.PaymentFailurePopup {
    padding: 2.5em;
    background-color: white;
    color: rgba(black, 0.9);
    overflow-y: auto;
}

.title {
    font-size: 200%;
    font-weight: 600;
    text-align: center;
    margin-left: -1em;
    margin-right: -1em;
}
.subtitle {
    color: rgba(black, 0.9);
    text-align: center;
    margin-top: 0.5em;
}
.text {
    margin-top: 2em;
    text-align: center;
    max-width: 20em;
    line-height: 1.5em;
    color: rgba(black, 0.9);
}
.list {
    margin-top: 2em;
    margin-bottom: 2em;
}
.footer {
    display: flex;
    justify-content: center;
}

.Item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
        margin-top: 0.5em;
    }
}
.Item__checkmark {
    $size: 1.6em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: $standardButtonColor;
    margin-right: 1em;

    display: flex;
    justify-content: center;
    align-items: center;
}
.Item__checkmarkIcon {
    $size: 0.8em;
    width: $size;
    height: $size;
    fill: white;
    flex-shrink: 0;
}
.Item__text {
    font-weight: 600;
    line-height: 1.3em;
    flex: 1;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
}
</style>
