<template>
  <div class="LanguageSwitch">
    <div
      class="Language --global-clickable"
      :class="{ 'is-active': language === 'en' }"
      @click="selectLanguage('en')">
      <div class="Language__flag Language__flag--en" />
      English
    </div>
    <div
      class="Language --global-clickable"
      :class="{ 'is-active': language === 'no' }"
      @click="selectLanguage('no')">
      <div class="Language__flag Language__flag--no" />
      Norsk
    </div>
  </div>
</template>

<script>
export default {
    computed: {
        language() {
            return this.$store.getters['moduleApp/locale'];
        }
    },
    methods: {
        selectLanguage(language) {
            if (this.language === language) {
                return;
            }
            this.$store.dispatch('moduleApp/setLocaleNoReload', language);
        }
    }
};
</script>

<style lang="scss" scoped>
.LanguageSwitch {
    display: flex;
    // align-items: center;
    height: 100%;
}

.Language {
    height: 5em;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &.is-active {
        // text-decoration: underline;
        background-color: $standardButtonColor;
        border-radius: 0.4em;
        color: white;
        cursor: default;
    }
    &:not(.is-active) {
        background-color: rgba(black, 0.02);
    }
}
.Language__flag {
    $size: 1.1em;
    width: $size * 1.3;
    height: $size;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 0.5em;
}
.Language__flag--no {
    background-image: url('../assets/images/lang-icons/no.svg');
}
.Language__flag--en {
    background-image: url('../assets/images/lang-icons/en.svg');
}
</style>
