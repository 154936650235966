<template>
  <div class="CourseTocIntroEntry">
    <div class="icon">
      <img class="icon-image" src="@/assets/images/lifeskillBadgeImages/intro-badge.png" alt="">
    </div>

    <div class="informations">
      <h3>{{ $t('label') }}</h3>
    </div>

    <a
      class="button-blue"
      @click="navigate()">{{buttonTitle}}</a>
  </div>
</template>

<translations>
  label: 'Introduction'
  label_no: 'Introduksjon'
  reread: 'Reread'
  reread_no: 'Les igjen'
</translations>

<script>
// import RelatedReadingVue from '../../memogenius-game/components/RelatedReading.vue';

export default {
    props: {
        stepId: {
            type: String
        },
        courseId: {
            type: String
        },
        useEllipsis: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        buttonTitle(){
            return this.step.progress===0? 'Start' : this.$t('reread');
        }
    },
    mounted(){
    },
    methods: {
        navigate() {
            this.$router.push({
                name: 'Course-ActivityRedirect',
                params: {
                    courseId: this.courseId,
                    stepId: this.stepId,
                    activityName: 'read'
                }
            });
            this.$emit('action');
        }
    }
};
</script>

<style lang="scss" scoped>
.CourseTocIntroEntry {
    display: flex;
    align-items: center;
    background-color: #1A1E54;
    padding: 1.2em 18.5px;
    border-radius: 4px;
    box-shadow: 0px 2px 23px 3px rgba(79, 79, 79, 0.1);
    @media (max-width: 530px) {
        padding: 1.2em 8px;
    }
}

.informations {
    padding-right: 20px;
    width: 100%;
    h3 {
        font-size: 22px;
        color: #EEEEEE;
        line-height: 26px;
        margin: 0;
        font-weight: 600;
        @media (max-width: 530px) {
            font-size: 16px;
        }
    }
    
}

.icon {
    min-width: 96px;
    min-height: 96px;
    max-width: 96px;
    max-height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 22px;
    @media (max-width: 530px) {
        min-width: 68px;
        min-height: 68px;
        max-width: 68px;
        max-height: 68px;
    }
}
.icon-image {
    width: 100%;
    height: 100%;
}
.label {
    font-size: 140%;
    font-weight: 600;
    flex: 1;
}

.icon-arrow {
    $size: 1em;
    width: $size;
    height: $size;
    fill: rgba(black, 0.3);
    margin-right: 1em;
}
</style>
