<template>
  <div class="IconsAnimationContainer">
    <div ref="imageContainer" class="animContainer" :class="{ active: active }" />
  </div>
</template>

<script>
import animationData from '../assets/topbar_icons.json';
import lottie from 'lottie-web';

export default {
  props: {
    step: Number,
    active: Boolean,
    done: Boolean,
    // skipAnim: Boolean
  },
  data() {
    return {
      animationState: 'initial',
    };
  },
  watch: {
    active() {
      this.setAnimation();
    },
    step() {
      this.setAnimation();
    },
    animationState(v) {
      if (v === 'joys') {
        this.animate(0, 60);
      } else if (v === 'joys_active') {
        this.animate(61, 120);
      } else if (v === 'frog') {
        this.animate(121, 180);
      } else if (v === 'frog_active') {
        this.animate(181, 240);
      } else if (v === 'focus') {
        this.animate(241, 300);
      } else if (v === 'focus_active') {
        this.animate(301, 360);
      } else if (v === 'plan') {
        this.animate(361, 420);
      } else if (v === 'plan_active') {
        this.animate(421, 480);
      } else if (v === 'diary') {
        this.animate(481, 540);
      } else if (v === 'diary_active') {
        this.animate(541, 600);
      }
    },
  },
  mounted() {
    this.anim = lottie.loadAnimation({
      container: this.$refs.imageContainer,
      renderer: 'canvas',
      loop: true,
      speed: 2,
      autoplay: false,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
      },
      animationData,
    });
    this.anim.setSpeed(0.3);
    if (this.step === 1) {
        if (this.active){
            this.animationState = 'joys_active';
        } else {
             this.animationState = 'joys';
        }
    } else if (this.step === 2) {
      this.animationState = 'frog';
    } else if (this.step === 3) {
      this.animationState = 'focus';
    } else if (this.step === 4) {
      this.animationState = 'plan';
    } else if (this.step === 5) {
      this.animationState = 'diary';
    }
  },
  beforeDestroy() {
        this.anim.destroy();
  },
  methods: {
    animate(a, b) {
      this.anim.show();
      this.anim.playSegments([a, b], true);
      this.anim.onComplete = () => {
        this.anim.show();
      };
    },
    setAnimation() {
      if (this.active) {
        if (this.step === 1) {
          this.animationState = 'joys_active';
        } else if (this.step === 2) {
          this.animationState = 'frog_active';
        } else if (this.step === 3) {
          this.animationState = 'focus_active';
        } else if (this.step === 4) {
          this.animationState = 'plan_active';
        } else if (this.step === 5) {
          this.animationState = 'diary_active';
        }
      } else {
        if (this.step === 1) {
          this.animationState = 'joys';
        } else if (this.step === 2) {
          this.animationState = 'frog';
        } else if (this.step === 3) {
          this.animationState = 'focus';
        } else if (this.step === 4) {
          this.animationState = 'plan';
        } else if (this.step === 5) {
          this.animationState = 'diary';
        }
      }
    },
  },
};
</script>

<style scoped>
.animContainer {
  width: 4.5em;
  height: 4.5em;
}
.animContainer.active {
  width: 5em;
  height: 5em;
}
</style>
