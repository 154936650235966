<template>
  <SimpleSlides
    ref="simpleSlides"
    class="MemomapsOnboardingPopup"
    :button-label="$t(`getStarted`)"
    @update:index="activeSlideIndex = $event"
    @done="$emit('signal', 'done')">
    <template slot="header">
      <div class="Slide__title">
        {{ $t('title') }}
      </div>
      <div class="Slide__subtitle">
        <div class="StepActivitiesIndicator">
          <template
            v-for="(labelKey, i) of [
              'memomaps.activities.read.label',
              'memomaps.activities.memo.label',
              'memomaps.activities.quiz.label'
            ]">
            <div
              :key="i + '-a'"
              v-t="labelKey"
              class="StepActivitiesIndicator-Activity"
              :class="{ 'is-active': activeSlideIndex === i }"
              @click="setActiveSlideIndex(i)" />
            <svg
              v-if="i < 2"
              :key="i + '-b'"
              class="StepActivitiesIndicator__chevron"
              viewBox="0 0 35 100">
              <path
                class="arrow"
                d="M 0,0 L 30,50 L 0,100" />
            </svg>
          </template>
        </div>
      </div>
    </template>

    <template slot="slides">
      <div
        v-for="i of 3"
        :key="i"
        class="Slide">
        <div
          class="Slide__image"
          :style="{ backgroundImage: `url(${getSlideImage(i)})` }" />
        <div class="Slide__title">
          {{ $t(`slide${i}.title`) }}
        </div>
        <div class="Slide__text">
          {{ $t(`slide${i}.text`) }}
        </div>
      </div>
    </template>
  </SimpleSlides>
</template>

<translations>
  title: 'Learn fast. Remember forever'
  title_no: 'Lær hurtig. Husk for alltid'

  getStarted: 'Get Started'
  getStarted_no: 'Kom i gang'

  slide1:
    title: 'Read 3 minute article'
    title_no: 'Les artikkelen på 3 min'

    text: 'Be inspired, get insight with brain research and master practical techniques that empower you to stay ahead of change.'
    text_no: 'Bli inspirert, få innsikt med hjerneforskning og lær praktiske teknikker som hjelper deg ligge et hode foran.'

  slide2:
    title: 'Memorize summary'
    title_no: 'Memorer sammendraget'
    text: 'Read the summary before using fun and science based memory techniques to memorize it. You’ll train your brain at the same time.'
    text_no: 'Les sammendraget før du bruker morsomme og vitenskapsbaserte husketeknikker for å memorere det. Du trener samtidig hjernen din.'

  slide3:
    title: 'Quiz & repeat before forgetting'
    title_no: 'Quiz & repeter før du glemmer'
    text: 'Ace your skills for this course step. Later, we’ll remind you to repeat just before your brain statistically starts to forget.'
    text_no: 'Bevis at du mestrer ferdighetene i dette kurssteget. Vi minner deg på å repetere like før hjernen begynner å glemme.'
</translations>

<script>
import SimpleSlides from '@/components/SimpleSlides';

import memoImg from './memo.png';
import quizImg from './quiz.png';
import readImg from './read.png';

export default {
    components: { SimpleSlides },
    data() {
        return {
            activeSlideIndex: 1
        };
    },
    mounted() {
        this.activeSlideIndex = this.$refs.simpleSlides.activeSlideIndex;
    },
    methods: {
        getSlideImage(slideNumber) {
            return [readImg, memoImg, quizImg][slideNumber - 1];
        },
        setActiveSlideIndex(index) {
            this.$refs.simpleSlides.activeSlideIndex = index;
        }
    }
};
</script>

<style lang="scss" scoped>
.MemomapsOnboardingPopup {
    width: 40em;
}

.Slide {
    text-align: center;
    padding: 2em 0;
}

.Slide__image {
    $size: 14em;
    width: $size;
    height: $size;
    margin: auto;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

@include media('<=mobile') {
    .Slide__image {
        $size: 10em;
        width: $size;
        height: $size;
    }

    .Slide {
        padding: 1em 0;
    }
}

.Slide__title {
    font-size: 130%;
    font-weight: 600;
    text-align: center;
}
.Slide__text {
    line-height: 1.5em;
    margin: auto;
    margin-top: 1em;
    max-width: 30em;
}

.StepActivitiesIndicator {
    display: flex;
    position: relative;
    align-items: center;
    font-weight: 300;
    justify-content: center;
    width: 100%;
}
.StepActivitiesIndicator-Activity {
    padding: 1em;
    color: rgba(black, 0.9);
    &:not(.is-active) {
        color: rgba(black, 0.4);
    }
}
.StepActivitiesIndicator__chevron {
    height: 2em;
    fill: none;
    stroke: rgba(black, 0.1);
    stroke-width: 2px;
    flex-shrink: 0;
}

.closeButton {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1em;

    .svg-icon {
        $size: 0.8em;
        width: $size;
        height: $size;
        fill: rgba(white, 0.9);
    }
}
</style>
