import Vue from 'vue';
import store from '@/store';
import { calculateRanges } from '@/utils';
import debounce from 'lodash-es/debounce';
import settings from '@/settings';

export default class VideoController {
    constructor(videoId, context) {
        let video = store.getters['moduleApp/getVideoById'](videoId);
        this.video = video;
        this.context = context;
        const duration = video.duration;
        const ranges = calculateRanges(duration, settings.video.preferredRangeLength, settings.video.remainderThreshold);
        const parts = ranges.map((range) => {
            return {
                locked: false,
                progress: 0,
                completed: false,
                start: range[0],
                end: range[1],
                playTime: 0,
            };
        });
        
        this.parts = parts;

        this.playhead = this.video.progress;

        window.dataLayer.push({
          event: 'video_open',
          video: this.video.id,
          title: this.video.title
        });

        this.setPartsProgress();
    }

    videoProgressUpdated() {
      const progress = this.video.progress;
      const part = this.parts.filter(p => p.end > progress)[0];
      if (!part) {
        return;
      }
      if (part.completed) {
        return;
      }

      part.playTime += 1;

      if (!this.playEventRegistered && progress > 0) {
        window.dataLayer.push({
          event: 'video_start',
          video: this.video.id,
          title: this.video.title
        });
        this.playEventRegistered = true;
      }

      const minTime = (part.end - part.start)  / 2; // user watched at least half of this part
      const idx = this.parts.findIndex(p => p.start === part.start);

      let endBuffer = 1; // 1 second before part is done
      if (idx === this.parts.length - 1) { // last part we give braindate 30s earlier
        if(this.video.id===settings.onboardingVideoId){ 
          endBuffer = 5;
        } else {
          endBuffer = 30;
        }
      }

      const end = part.end - endBuffer;
      const atEnd = progress >= end;
      const hasPlayedRequiredAmount = part.playTime >= minTime;

      if (atEnd && hasPlayedRequiredAmount) {
        this.onPartCompleted(idx);
        part.completed = true,
        part.progress = 1;
      } else {
        part.progress = Math.max(0, (progress - part.start) / (part.end - part.start));
      }
    }

    setPartsProgress() {
        const progress = this.video.progress;

        const partsToUpdate = this.parts.filter((part) => {
          return part.end <= progress;
        });

        partsToUpdate.forEach(part => {
          part.progress = Math.max(0, (progress - part.start) / (part.end - part.start));
          part.completed = part.progress >= 1;
        });
    }

    setPlayer(player) {
        this.player = player;
        this.player.$on('position', playhead => {
            this.playhead = playhead;
            updateProgress(this, this.video, playhead, this.context);
        });
    }

    seek(position) {
        this.playhead = position;
        this.player.seek(this.playhead);
    }

    play() {
        this.player.start();
    }

    videoCompleted(partIndex) {
        return this.parts.length === partIndex + 1;
    }

    onPartCompleted(partIndex) {
        window.dataLayer.push({
            event: 'video_part_completed',
            video: this.video.id,
            title: this.video.title,
            part: partIndex
        });
        if (this.videoCompleted(partIndex)) {
            window.dataLayer.push({
                event: 'video_completed',
                video: this.video.id,
                title: this.video.title,
                part: partIndex
            });
        }

      store.dispatch('moduleApp/videoPartCompleted', {
        lifeskillNumber: this.context || (this.video.lifeskillIds || [])[0] || '00',
        videoId: this.video._id,
        partIndex,
      });
    }

    destroy() {
    }
}

const updateProgress = debounce(
    (_this, video, playhead, context) => {
        if (playhead > video.progress) {
          _this.videoProgressUpdated()
          store.dispatch('moduleApp/updateProgressOnVideo', { id: video.id, value: playhead, lifeskillId: context });
        }
    },
    500,
    { maxWait: 1000 }
);
