<template>
  <div
    class="upgradeBar --global-clickable"
    @click="upgrade()">
    <span class="upgradeBarLabel">{{ $t(text) }}</span>
  </div>
</template>

<translations>
  upgrade: 'Upgrade now'
  upgrade_no: 'Oppgrader nå'
  paymentLabel: "Missing payments"
  paymentLabel_no: "Manglende betaling"
</translations>

<script>
import UpgradePopup from './UpgradePopup';
import PaymentFailurePopup from './PaymentFailurePopup';

export default {
    computed: {
        paymentFailure() {
            return this.$store.state.moduleAuth.profile.subscription.status === 'payment-failure';
        },
        text() {
            let t = this.paymentFailure ? 'paymentLabel' : 'upgrade';
            return t;
        }
    },
    mounted() {
        const e = this.paymentFailure ? 'payment_failure_button' : 'upgrade_button';
        window.dataLayer.push({
            event: e
        });
    },
    methods: {
        upgrade() {
            this.$modal.open({
                component: this.paymentFailure ? PaymentFailurePopup : UpgradePopup,
                target: 'app-modal'
            });
        }
    }
};
</script>
