<template>
  <div class="DashboardGameDetails">
    <div
      v-t="`memogenius.games.${gameId}.title`"
      class="title" />
    <div class="GameDetail">
      <div class="text">
        {{ $t(`memogenius.games.${gameId}.levelTexts.level0`) }}
      </div>

      <div class="footer">
        <div
          v-if="highestCompletedLevelInfo"
          class="highestCompletedLevelInfo">
          <div class="">
            {{ $t('level') }} {{ highestCompletedLevelInfo.levelNumber }}.{{
              highestCompletedLevelInfo.sublevelNumber
            }}
          </div>
          <div
            v-if="highestCompletedLevelInfo.stars"
            class="stars">
            <svgicon
              v-for="i in 3"
              :key="i"
              class="star"
              :class="[i <= highestCompletedLevelInfo.stars ? 'is-full' : 'is-empty']"
              icon="star" />
          </div>
        </div>
        <div class="spacer" />
        <StandardButton
          :label="$t('start')"
          icon="arrow-right"
          @click="gotoGame()" />
      </div>
    </div>

    <div class="resourcesSection">
      <div
        v-t="'memogenius.MemogeniusGameIntroView.resourcesLabel'"
        class="sideTitle" />

      <RelatedReading
        :game-id="gameId"
        hide-arrows />
      <div class="spacer" />
    </div>
  </div>
</template>

<translations>
  level: 'Level'
  level_no: 'Nivå'
  start: 'Start Game'
  start_no: 'Start Spillet'
</translations>

<script>
import StandardButton from '@/components/StandardButton';
import RelatedReading from '@/modules/memogenius-game/components/RelatedReading';

export default {
    components: { RelatedReading, StandardButton },
    props: {
        gameId: {
            type: String
        }
    },
    computed: {
        highestCompletedLevelInfo() {
            return this.$store.getters['moduleMemogenius/getHighestCompletedLevelInfo'](this.gameId);
        },
        nextAvailableGameInfo() {
            return this.$store.getters['moduleMemogenius/getNextAvailableGameInfo'](this.gameId);
        }
    },
    methods: {
        gotoGame() {
            this.$store.commit('moduleApp/setGameExitTo', this.$route.path);
            this.$router.replace({
                name: 'MemogeniusGame-Levels',
                params: {
                    gameId: this.gameId
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
$lockedLevelBg: #e8f6fd;

.DashboardGameDetails {
    color: rgba(white, 0.7);
}

.title {
    font-size: 140%;
    color: rgba(white, 0.9);
}

.GameDetail {
    margin-top: 1em;
}
.GameDetail {
    color: rgba(white, 0.7);
}
.text {
    line-height: 1.5em;
}
.footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2em;
}
.spacer {
    flex: 1;
}

.highestCompletedLevelInfo {
    color: rgba(white, 0.5);
}
.stars {
    margin-top: 0.3em;
    display: flex;
}
.star {
    $size: 0.8em;
    width: $size;
    height: $size;
    margin: 0 0.1em;
    &.is-full {
        fill: yellow;
    }
    &.is-empty {
        display: none;
    }
}

.resourcesSection {
    margin-top: 2em;
}

.sideTitle {
    font-size: 120%;
    color: rgba(white, 0.9);
}

.RelatedReading {
    margin-top: 1.5em;
}
</style>
