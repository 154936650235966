<template>
  <div
    class="AboutAuthor"
    @xclick="openPopup()">
    <div
      class="image"
      :style="{ backgroundImage: `url(${image})` }" />
    <div class="textSection">
      <div class="name">
        {{ name }}
      </div>
      <div class="text">
        {{ text }}
      </div>
    </div>
    <div
      class="showMore"
      @click="openPopup()">
      <div class="dot" />
      <div class="dot" />
      <div class="dot" />
    </div>
  </div>
</template>

<script>
import AboutAuthorPopup from './AboutAuthorPopup';
import defaultImage from '@/assets/images/frank-avatar.jpg';

export default {
    props: {
        image: {
            type: String,
            default: defaultImage
        },
        name: {
            type: String,
            default: 'Frank Wedde'
        },
        text: {
            type: String,
            default: 'CEO of Memolife'
        }
    },
    methods: {
        openPopup() {
            this.$modal.open({
                component: AboutAuthorPopup,
                target: 'app-modal'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.AboutAuthor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.2em;
    @media (max-width: 530px) {
        padding-right: 1em;
    }
}
.image {
    $size: 4em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: gray;
    flex-shrink: 0;
    margin-right: 1.2em;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.textSection {
    margin-left: 1em;
    margin-right: auto;
}
.name {
    font-size: 120%;
    font-weight: 600;
    color: #EEEEEE;
}
.text {
    margin-top: 0.3em;
    color: #9FB4EF;
    line-height: 1.4em;
}
.showMore {
    display: flex;
    cursor: pointer;
    .dot {
        width: 3px;
        height: 3px;
        background-color: #c4c4c4;
        margin-left: 3px;
        border-radius: 50%;
    }
}
</style>
