import json2mq from 'json2mq';
import ResizeObserver from 'resize-observer-polyfill';

function transformToPixels(query, fontSize) {
    const transformedQuery = {};
    Object.keys(query).forEach(key => {
        const val = query[key];
        if (val.endsWith('em')) {
            transformedQuery[key] = `${parseFloat(val) * fontSize}px`;
        } else {
            transformedQuery[key] = val;
        }
    });
    return transformedQuery;
}

class FontSizeObserver {
    listeners = [];
    fontSize = 0;

    constructor() {
        const fontSizeMeasureElem = document.createElement('div');
        fontSizeMeasureElem.setAttribute(
            'style',
            'position: absolute; top: 0; left: 0; visibility: hidden; height: 100em;'
        );
        document.body.appendChild(fontSizeMeasureElem);

        this.fontSize = fontSizeMeasureElem.clientHeight / 100;

        const ro = new ResizeObserver((entries, observer) => {
            for (const entry of entries) {
                const { height } = entry.contentRect;
                if (this.fontSize !== height / 100) {
                    this.fontSize = height / 100;
                    this.broadcast(this.fontSize);
                }
            }
        });
        ro.observe(fontSizeMeasureElem);
    }

    broadcast(fontSize) {
        this.listeners.forEach(listener => listener(fontSize));
    }

    observe(func) {
        this.listeners.push(func);
        func(this.fontSize);
    }

    unobserve(func) {
        let index = this.listeners.indexOf(func);
        if (index !== -1) {
            this.listeners.splice(index, 1);
        }
    }
}

const fontSizeObserver = new FontSizeObserver();

export default class MediaQueryObserver {
    queryMap = {};
    state = {};
    listeners = [];
    fontSize = 0;

    addQuery(name, query) {
        this.queryMap[name] = {
            query
        };
        if (this.listeners.length > 0) {
            this.updateQuery(name);
            this.queryMap[name].func();
        }
    }
    updateQuery(name) {
        const conf = this.queryMap[name];
        if (conf.mediaQueryList) {
            conf.mediaQueryList.removeListener(conf.func);
        }
        const mediaQueryList = window.matchMedia(json2mq(transformToPixels(conf.query, this.fontSize)));
        const func = () => {
            this.state[name] = mediaQueryList.matches;
            this.broadcast();
        };
        mediaQueryList.addListener(func);
        conf.mediaQueryList = mediaQueryList;
        conf.func = func;

        this.state[name] = mediaQueryList.matches;
    }
    removeQuery(name) {
        const conf = this.queryMap[name];
        if (conf) {
            conf.mediaQueryList.removeListener(conf.func);
            delete this.queryMap[name];
            delete this.state[name];
        }
    }
    broadcast() {
        this.listeners.forEach(listener => listener(this.state));
    }
    addListener(callback) {
        if (this.listeners.length === 0) {
            this._onFontSizeChanged = this.onFontSizeChanged.bind(this);
            fontSizeObserver.observe(this._onFontSizeChanged);
        }
        this.listeners.push(callback);
        callback(this.state);
    }
    removeListener(callback) {
        let index = this.listeners.indexOf(callback);
        if (index !== -1) {
            this.listeners.splice(index, 1);
            if (this.listeners.length === 0) {
                fontSizeObserver.unobserve(this._onFontSizeChanged);
            }
        }
    }

    onFontSizeChanged(fontSize) {
        this.fontSize = fontSize;
        let stateDidChange = false;
        Object.keys(this.queryMap).forEach(name => {
            const oldValue = this.state[name];
            this.updateQuery(name);
            if (oldValue !== this.state[name]) {
                stateDidChange = true;
            }
        });
        if (stateDidChange) {
            this.broadcast();
        }
    }
}
