<template>
  <div
    class="JourneyQuizItem"
    @click="!locked && gotoQuiz()">
    <div
      class="image"
      :style="{ backgroundColor: imageBackgroundColor }">
      <div
        class="quizIcon"
        :style="{ backgroundColor: iconBackgroundColor }">
        <svgicon
          class="icon"
          icon="activities/quiz" />
      </div>
    </div>
    <div class="content">
      <div class="gray-text">
        {{ $t('title') }}
      </div>
      <div class="bold-title">
        {{ $t('title') }}
      </div>
    </div>
    <a
      v-if="!locked"
      href="#"
      class="button-blue">Start</a>
    <StepBullet
      :progress="completed ? 1 : 0"
      :current="current"
      :color="theme.color"
      icon="flag-with-checkmark" />
  </div>
</template>

<translations>
  title: 'Journey Quiz'
  title_no: 'Reisequiz'

  text: 'Complete the quiz to reach the mountain top'
  text_no: 'Fullfør quizen for å nå fjelltoppen'

  textLocked: 'Complete all steps to unlock journey quiz'
  textLocked_no: 'Fullfør alle stegene for å åpne reisequiz'

  textCompleted: You’ve reached the mountain top
  textCompleted_no: Du har nådd fjelltoppen

  repetition: We'll remind you to repeat before your brain forgets
  repetition_no: Vi minner deg på å repetere før hjernen din glemmer

  quiz: 'Start Quiz'
  quiz_no: 'Start quiz'

  redoQuiz: 'Redo Quiz'
  redoQuiz_no: 'Quiz på nytt'
</translations>

<script>
import Color from 'color';
import StepBullet from './StepBullet';

export default {
    components: { StepBullet },
    props: {
        journeyId: {
            type: String,
            required: true
        },
        current: {
            type: Boolean,
            default: false
        }
    },
    inject: ['theme'],
    computed: {
        journey() {
            return this.$store.state.moduleCourse.stepGroups[this.journeyId];
        },
        imageBackgroundColor() {
            return this.theme.color;
        },
        iconBackgroundColor() {
            return Color(this.theme.color).lighten(0.1).hex();
        },
        quizType() {
            return this.journey.quiz ? this.journey.quiz.generator : '';
        },
        locked() {
            if (
                ['standardStepGroupQuiz', 'chemistryStepGroupQuiz', 'historyJourneyQuiz'].indexOf(this.quizType) !== -1
            ) {
                return this.$store.getters['moduleCourse/getStepGroupStats'](this.journeyId).quizUnlockProgress < 1;
            } else {
                return false;
            }
        },
        completed() {
            return this.journey.quiz.done;
        }
    },
    methods: {
        gotoQuiz() {
            this.$router.push({
                name: 'StepGroupQuizView',
                params: { courseId: this.journey.courseId, stepGroupId: this.journeyId }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.JourneyQuizItem {
    position: relative;
    border-radius: 1em;
    display: flex;
    align-items: center;
    @media (max-width: 530px) {
        border-radius: 0.5em;
    }
}
.image {
    min-width: 95px;
    min-height: 95px;
    border-radius: 13.3405px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 530px) {
        min-width: 68px;
        min-height: 68px;
        border-radius: 10px;
    }
}

.quizIcon {
    $size: 5em;
    width: $size;
    height: $size;
    background-color: rgba(white, 0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 530px) {
        width: 1.5em;
        height: 1.5em;
    }

    .svg-icon {
        $size: 50%;
        width: $size;
        height: $size;
        fill: white;
    }
}

.gray-text {
    color: #99B1BF;
    @media (max-width: 530px) {
        font-size: 14px;
    }
}
.content {
    padding-left: 25px;
    flex: 1;
    align-self: stretch;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 530px) {
        padding-left: 1em;
    }
}

.button-blue {
    cursor: pointer;
}

.bold-title {
    color: #EEEEEE;
    margin-top: 5px;
    @media (max-width: 530px) {
        font-size: 16px;
        margin-top: 0;
    }
}

.spacer {
    flex: 1;
}

.text {
    margin-top: 0.3em;
    color: rgba(black, 0.7);
}

.buttonContainer {
    display: flex;
    margin-top: 1em;
}
.button {
    border-radius: 2em;
    color: white;
    padding: 0.8em 1em;
    display: flex;
    align-items: center;
}
.icon-arrow {
    $size: 1em;
    width: $size;
    height: $size;
    fill: white;
    margin-left: 1em;
}

.StepBullet {
    display: none;
    position: absolute;
    top: -0.5em;
    right: -0.5em;
}
</style>
