<template>
  <div class="SimpleCarousel">
    <div
      ref="container"
      class="container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
    props: {
        index: {
            type: Number
        }
    },
    data() {
        return {
            size: 0
        };
    },
    watch: {
        index() {
            this.update();
        },
        size(val) {
            if (this.index > val - 1) {
                this.$emit('update:index', val - 1);
            }
            this.update();
        }
    },
    mounted() {
        const targetNode = this.$refs.container;
        const config = { childList: true };
        this.size = targetNode.childElementCount;
        const vm = this;
        const callback = function (mutationsList) {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    vm.size = targetNode.childElementCount;
                    vm.$emit('size', vm.size);
                }
            }
        };

        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);
        this.observer = observer;

        this.$emit('size', this.size);
    },
    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
    methods: {
        update() {
            for (let child of this.$refs.container.children) {
                child.classList.remove('somenamespace-active');
            }
            let child = this.$refs.container.children[this.index];
            if (child) {
                child.classList.add('somenamespace-active');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.SimpleCarousel {
}
.container {
    & > * {
        &.somenamespace-active {
        }
        &:not(.somenamespace-active) {
            display: none;
        }
    }
}
.dots {
    display: flex;
}
.Dot {
    margin: 0.5em;
    padding: 1em;
    border-radius: 50%;
    background-color: gray;

    &.active {
        background-color: black;
    }
}
</style>
