<template>
  <div
    class="DashboardCalculationGeniusItem"
    @click.stop="openCalculationGenius()">
    <div class="header">
      <div
        class="icon"
        :style="{ backgroundImage: `url(${image})` }" />
    </div>
    <div class="body">
      <div class="spacer" />
      <div
        v-t="'title'"
        class="name" />
      <div
        v-t="'subtitle'"
        class="subtitle" />
    </div>
  </div>
</template>

<translations>
  title: 'CalculationGenius'
  title_no: 'TallGeni'
  subtitle: 'Super-fast mental calculation'
  subtitle_no: 'Bli lynrask i hoderegning'
</translations>

<script>
import image from '@/assets/images/calculationgenius.png';

export default {
    computed: {
        image() {
            return image;
        }
    },
    methods: {
        async openCalculationGenius() {
            const token = this.$store.getters['moduleAuth/token'];
            location.href = `https://tallgeni.memolife.com/auth/?access_token=${token}`;
        }
    }
};
</script>

<style lang="scss" scoped>
.DashboardCalculationGeniusItem {
    background-color: $defaultItemBackgroundColor;
    display: flex;
    flex-direction: column;

    min-height: 100%;
}

.icon {
    width: 50%;
    min-width: 4em;
    max-width: 6em;
    margin: auto;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &::after {
        content: '';
        display: block;
        padding-top: 100%;
    }
}

.header {
    padding-top: 1em;
}

.body {
    padding: 1em 0.5em;
    padding-top: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.spacer {
    flex: 1;
    min-height: 1em;
}

.name {
    text-align: center;
    color: rgba(black, 0.9);
    font-size: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.subtitle {
    text-align: center;
    color: rgba(black, 0.9);
    font-size: 90%;
    margin-top: 1em;
}
</style>
