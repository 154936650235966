<template>
  <div
    class="VerticalTimeline"
    :class="{ extendTop, trim }">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="Step"
      :class="{ 'is-active': index === activeIndex }"
      @click.self="$emit('click', index)">
      <div
        class="bullet"
        :class="[`align-${item.align}`]">
        <div
          v-if="extendTop && index === 0"
          class="extendedTopLine"
          :style="item.progress ? activeLine2Style : line2Style" />
        <div
          class="line1"
          :style="checkLine1Active(index) ? activeLine1Style : line1Style" />
        <slot
          name="bullet"
          :type="item.type"
          :item="item"
          :index="index" />
        <div
          class="line2"
          :style="checkLine2Active(index) ? activeLine2Style : line2Style" />
      </div>
      <div
        class="content"
        :class="{ 'use-overflow-hidden': contentOverflowHidden }">
        <slot
          name="content"
          :item="item"
          :text="item.text"
          :index="index">
          {{ item.title }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
function gradientFromDasharray(dasharray = [], color, rotate = 0, reverse = false) {
    if (reverse) {
        dasharray = dasharray.slice().reverse();
    }
    const args = [`${rotate}deg`];
    let lastStop = 0;
    dasharray.forEach((d, i) => {
        const c = i % 2 ^ reverse ? color : 'transparent';
        args.push(`${c} ${lastStop}px`);
        args.push(`${c} ${lastStop + d}px`);
        lastStop += d;
    });
    return `repeating-linear-gradient(${args.join(',')})`;
}

const defaultLineStyle = {
    color: 'rgba(0, 0, 0, 0.05)',
    width: 1.5
};

export default {
    props: {
        items: {
            type: Array
        },
        lineStyle: {
            type: Object,
            default() {
                return {};
            }
        },
        activeLineStyle: {
            type: Object,
            default() {
                return {};
            }
        },
        contentOverflowHidden: {
            type: Boolean,
            default: true
        },
        activeIndex: {
            type: Number,
            default: -1
        },
        trim: {
            type: Boolean,
            default: false
        },
        extendTop: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        line1Style() {
            const color = this.lineStyle.color || defaultLineStyle.color;
            return {
                backgroundColor: color,
                width: (this.lineStyle.width || defaultLineStyle.width) + 'px',
                background: gradientFromDasharray(this.lineStyle.dasharray, color, 180)
            };
        },
        line2Style() {
            const color = this.lineStyle.color || defaultLineStyle.color;
            return {
                backgroundColor: color,
                width: (this.lineStyle.width || defaultLineStyle.width) + 'px',
                background: gradientFromDasharray(this.lineStyle.dasharray, color, 0, true)
            };
        },
        activeLine1Style() {
            const color = this.activeLineStyle.color || defaultLineStyle.color;
            return {
                backgroundColor: color,
                width: (this.activeLineStyle.width || defaultLineStyle.width) + 'px',
                background: gradientFromDasharray(this.activeLineStyle.dasharray, color, 180)
            };
        },
        activeLine2Style() {
            const color = this.activeLineStyle.color || defaultLineStyle.color;
            return {
                backgroundColor: color,
                width: (this.activeLineStyle.width || defaultLineStyle.width) + 'px',
                background: gradientFromDasharray(this.activeLineStyle.dasharray, color, 0, true)
            };
        }
    },
    methods: {
        checkLine1Active(itemIndex) {
            const item = this.items[itemIndex];
            if (itemIndex === 0 && item.progress) {
                return true;
            }
            if (itemIndex > 0 && this.items[itemIndex - 1].progress === 1 && item.progress) {
                return true;
            }
            return false;
        },
        checkLine2Active(itemIndex) {
            const item = this.items[itemIndex];
            const nextItem = this.items[itemIndex + 1];
            if (nextItem && item.progress === 1 && nextItem.progress) {
                return true;
            }
            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
$margin: 0.8em;

.VerticalTimeline {
    &.extendTop {
        .Step:first-child .line1 {
            visibility: visible;
        }
    }
    &.trim {
        .Step {
            &:first-child {
                .content {
                    margin-top: 0;
                }
                .line1 {
                    min-height: 0;
                }
                .line2 {
                    flex-basis: $margin;
                }
            }
            &:last-child {
                .content {
                    margin-bottom: 0;
                }
                .line1 {
                    flex-basis: $margin;
                }
                .line2 {
                    min-height: 0;
                }
            }
        }
    }
}

.Step {
    position: relative;
    display: flex;
    align-items: center;

    &:first-child {
        .line1 {
            visibility: hidden;
        }
    }
    &:last-child {
        .line2 {
            visibility: hidden;
        }
    }
}

.bullet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    @media (max-width: 530px) {
        min-width: 66px;
    }

    &.align-top {
        .line1 {
            display: none;
        }
    }
}

.extendedTopLine {
    // NOTE: Must get height from outside this scope
    position: absolute;
    top: 0;
    transform: translateY(-100%);
}

.line1,
.line2 {
    min-height: $margin;
    flex: 1;
}

.content {
    margin: $margin 0;
    flex: 1;

    &.use-overflow-hidden {
        overflow: hidden; // NOTE: needed for ellipsis on children
    }
}
</style>
