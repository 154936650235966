<template>
  <div
    class="GridWithExpansion"
    :class="{ hasActiveItem }">
    <div
      v-for="groupNumber of groupCount"
      :key="groupNumber"
      class="Group">
      <div
        class="Row"
        :style="layout.row">
        <template v-for="(itemIndex, i) of itemIndexesForGroup(groupNumber - 1)">
          <div
            v-if="itemIndex < itemCount"
            :key="i + '-a'"
            class="ItemContainer --global-clickable"
            :class="{ active: itemIndex === index, useHeightRatio: layout.heightRatio }"
            :style="layout.item"
            @click="handleClick(itemIndex)">
            <div
              v-if="layout.heightRatio"
              class="aspect"
              :style="{ paddingTop: `${layout.heightRatio * 100}%` }" />
            <slot
              name="item"
              :index="itemIndex"
              :item="items[itemIndex]"
              :active="itemIndex === index" />
            <div
              class="ItemContainer__funnel"
              :style="{ borderBottomColor: expansionColor }" />
          </div>
          <div
            v-else
            :key="i + '-a'"
            class="ItemContainer--dummy"
            :style="layout.item" />
          <div
            v-if="i < columnCount - 1"
            :key="i + '-b'"
            class="Gap"
            :style="layout.gap" />
        </template>
      </div>
      <transition name="grow">
        <div
          v-if="activeGroupIndex === groupNumber - 1"
          class="ExpansionContainer"
          :style="{ backgroundColor: expansionColor }">
          <div
            class="DetailContainer"
            :style="[{ margin: '3em auto' }, layout.detail]">
            <slot
              name="detail"
              :row="groupNumber - 1" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        layout: {
            type: Object,
            required: true
        },
        expansionColor: {
            type: String,
            default: 'red'
        }
    },
    computed: {
        itemCount() {
            return this.items.length;
        },
        columnCount() {
            return this.layout.columns;
        },
        groupCount() {
            return Math.ceil(this.items.length / this.layout.columns);
        },
        hasActiveItem() {
            return this.index !== null && this.index !== -1;
        },
        activeGroupIndex() {
            return Math.floor(this.index / this.columnCount);
        }
    },
    methods: {
        itemIndexesForGroup(groupIndex) {
            let indexes = [];
            for (let i = 0; i < this.columnCount; i++) {
                indexes.push(groupIndex * this.columnCount + i);
            }
            return indexes;
        },
        handleClick(itemIndex) {
            if (this.index === itemIndex) {
                itemIndex = -1;
            }
            this.$emit('update:index', itemIndex);
        }
    }
};
</script>

<style lang="scss" scoped>
$expandedSectionBackgroundColor: black; //white;

.GridWithExpansion {
    &.hasActiveItem {
        .ItemContainer {
            &:not(.active) {
                opacity: 0.2;
                filter: grayscale(100%);
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

.Group {
    &:not(:last-child) {
        margin-bottom: 1em;
    }
}
.Row {
    display: flex;
    margin: auto;
    padding-bottom: 1em;
}
.ItemContainer {
    position: relative;

    &.useHeightRatio {
        & > *:nth-child(2) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
    &:not(.useHeightRatio) {
        & > *:nth-child(1) {
            height: 100%;
        }
        .aspect {
            display: none;
        }
    }
    &.active {
        .ItemContainer__funnel {
            $height: 1em;
            border-bottom-width: $height;
        }
    }
}
.aspect {
}
.ItemContainer--dummy {
    visibility: hidden;
}
.ItemContainer__funnel {
    $width: 1.8em;
    $height: 0em;
    width: $width;
    position: absolute;
    bottom: -1em;
    left: calc(50% - 1em);
    border-left: $width / 2 solid transparent;
    border-right: $width / 2 solid transparent;
    border-bottom: $height solid $expandedSectionBackgroundColor;

    transition: border-bottom-width 0.3s;
}

.ExpansionContainer {
    min-height: 5em;
    overflow: hidden;
    background-color: $expandedSectionBackgroundColor;
    margin-bottom: 1em;
}
</style>
