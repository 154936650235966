<template>
  <div class="CalculationGeniusPanel">
    <GridWithExpansion
      class="items"
      :items="languages"
      :index.sync="selectedItemIndex"
      :layout="layout"
      expansion-color="white"
      @select-item="selectItem">
      <DashboardCalculationGeniusItem
        slot="item"
        class="Item" />

      <!-- <div class="details" slot="details">
        <h2>Details</h2>
      </div> -->
    </GridWithExpansion>
  </div>
</template>

<script>
import GridWithExpansion from '@/components/GridWithExpansion';
import DashboardCalculationGeniusItem from '../../components/DashboardCalculationGeniusItem';
import GridLayoutController from './GridLayoutController';

export default {
    components: { DashboardCalculationGeniusItem, GridWithExpansion },
    data() {
        return {
            layout: null,
            selectedItemIndex: -1
        };
    },
    computed: {
        languages() {
            return ['french'];
        }
    },
    created() {
        this.disposeLayoutConroller = new GridLayoutController(
            {
                heightRatio: null
            },
            layout => {
                this.layout = layout;
            }
        );
    },
    beforeDestroy() {
        this.disposeLayoutConroller();
    },
    methods: {
        selectItem(x) {
            // alert(x)
        }
    }
};
</script>

<style lang="scss" scoped>
.CalculationGeniusPanel {
    padding-top: 2em;
}

.Item {
    border: 1px solid $defaultItemBorderColor;
}
</style>
