<template>
  <div class="DashboardLanguageBetaPopup">
    <div class="header">
      <div
        v-t="'title'"
        class="title" />
      <div
        v-t="'text'"
        class="text" />
    </div>
    <div class="body">
      <div class="buttons">
        <form
          ref="formElem"
          method="POST"
          target="_blank"
          :action="url">
          <input
            type="hidden"
            name="email"
            :value="profile.email">
          <input
            type="hidden"
            name="Cookies2"
            :value="legacyToken">
          <input
            type="hidden"
            name="dictionaryId"
            :value="dictionaryId">
          <input
            type="hidden"
            name="display"
            :value="`${profile.first_name} ${profile.last_name}`">
          <input
            type="hidden"
            name="picture"
            :value="profile.avatar">
          <input
            type="hidden"
            name="FB"
            :value="profile.facebook_id">
          <input
            type="hidden"
            name="TW"
            :value="profile.twitter_id">

          <button
            class="realButton"
            :disabled="!legacyToken"
            @click="$emit('signal', 'old')">
            <StandardButton
              :label="$t('launchOld')"
              :disabled="!legacyToken" />
          </button>
        </form>
        <StandardButton
          class="betaButton"
          :label="$t('launchNew')"
          @click="$emit('signal', 'beta')" />
      </div>
    </div>
  </div>
</template>

<translations>
  title: 'We are creating a new Memolanguage'
  title_no: 'En ny versjon av Memolanguage er her'
  text: 'Even more optimized for mobile you''ll have access to accelerated language learning on the go - with many new features.'
  text_no: 'Med enda bedre tilpasning for mobil får du nå akselerert språklæring på farten - med mange nye funksjoner.'

  launchOld: 'Launch old version'
  launchOld_no: 'Gammel versjon'
  launchNew: 'Try new beta'
  launchNew_no: 'Prøv ny beta'
</translations>

<script>
import { getLegacyToken } from '@/account-utils';
import StandardButton from '@/components/StandardButton';

export default {
    components: { StandardButton },
    props: {
        languageId: {
            type: String,
            require: true
        }
    },
    data() {
        const sourceLanguage = { no: 'norwegian' }[this.$store.getters['moduleApp/locale']] || 'english';
        return {
            url: 'https://memolanguage.memolife.com',
            legacyToken: null,
            dictionaryId: legacyDictionaryIdMap[`${sourceLanguage}-${this.languageId}`],
            profile: this.$store.state.moduleAuth.profile
        };
    },
    created() {
        getLegacyToken().then(legacyToken => {
            this.legacyToken = legacyToken;
        });
    }
};

const legacyDictionaryIdMap = {
    'english-norwegian': 102,
    'english-german': 103,
    'english-french': 104,
    'english-italian': 105,
    'english-portuguese': 106,
    'english-spanish': 107,
    // 'english-chinese': 108,

    'norwegian-engelsk': 201,
    'norwegian-tysk': 203,
    'norwegian-fransk': 204,
    'norwegian-italiensk': 205,
    'norwegian-portugisisk': 206,
    'norwegian-spansk': 207
    // 'norwegian-kinesisk': 208,
};
</script>

<style lang="scss" scoped>
.DashboardLanguageBetaPopup {
    background-color: white;
    padding: 3em;

    position: relative;
    width: 100%;
    overflow: hidden;
}

.title {
    font-weight: bold;
    font-size: 120%;
    color: rgba(black, 0.8);
    text-align: center;
}

.text {
    line-height: 1.5em;
    margin-top: 1em;
    font-size: 90;
    color: rgba(black, 0.6);
    text-align: center;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 2em;
}

.realButton {
    font: inherit;
    background: none;
    border: none;
    padding: 0;
    &:focus {
        outline: none;
    }
}

.betaButton {
    margin-left: 1em;
}
</style>
