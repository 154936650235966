<template>
  <div class="JourneyChallenge">
    <div v-if="games.length === 0">
      <div class="ChallengeButton" @click="createQuizGame()">
        {{ $t('createTeamQuiz') }}
      </div>
      <div class="ChallengeButton" @click="createFallingGame()">
        {{ $t('createTeamMemo') }}
      </div>
    </div>

    <div v-for="game of games" :key="game.id" class="challengeCreatedContainer">
      <div class="challengeCreatedTitle">
        {{ $t('teamChallenge') }}
      </div>

      <div class="challengeCreatedButtons">
        <div v-if="game.leaderboardReveal" class="leaderboard" @click="gotoGame(game.id)">
          <div class="tooltip">Leaderboard</div>
          <img class="leaderboardIcon" src="./leaderboardIcon.png" alt="" />
        </div>

        <div v-if="!game.leaderboardReveal" class="start" @click="gotoGame(game.id)">
          <div class="tooltip">Start</div>
          <img class="startIcon" src="./startIcon.png" alt="" />
        </div>

        <div v-if="!game.startedAt && !game.leaderboardReveal">
          <!-- Share the challenge -->
          <router-link v-slot="{ route }" :to="{ name: 'MemoransierLobby', params: { gameId: game.id } }" custom>
            <div class="share" @click="copy(host + route.fullPath)">
              <div v-if="!linkCopied" class="tooltip">{{ $t('copyLink') }}</div>
              <div v-if="linkCopied" class="tooltipCopied">{{ $t('copied') }} ✓</div>
              <div class="shareIcon">
                <img src="./shareIcon.png" alt="" />
              </div>
            </div>
          </router-link>
        </div>

        <div class="delete" @click="deleteGame(game.id)">
          <div class="tooltip">{{ $t('deleteChallenge') }}</div>
          <img class="deleteIcon" src="./deleteIcon.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<translations>

createTeamQuiz: Create team quiz
createTeamQuiz_no: Lag team quiz
createTeamMemo: Create team memo
createTeamMemo_no: Lag team-memo
teamChallenge: Team challenge
teamChallenge_no: Team-konkurranse

copyLink: Copy link
copyLink_no: Kopier link
deleteChallenge: Delete challenge
deleteChallenge_no: Slett konkurransen

leaderboard: Leaderboard
leaderboard_no: Leaderboard
copied: Copied
copied_no: Kopiert

fallingGame: Falling game test
fallingGame_no: Falling game test


</translations>

<script>
import Color from 'color'

export default {
  filters: {},

  inject: ['theme'],

  props: {
    journeyId: {
      required: true,
    },
    courseId: {
      required: true,
    },
  },
  data: function () {
    return {
      linkCopied: false,
    }
  },

  computed: {
    games() {
      return this.$store.getters['moduleMemoransier/games'].filter(game => {
        const options = game.options
        return (
          // options.teamId === this.teamId &&
          options.courseId === this.courseId && options.stepGroupId === this.journeyId && game.owner === this.profile.id
        )
      })
    },

    profile() {
      return this.$store.getters['moduleAuth/profile']
    },

    imageBackgroundColor() {
      return this.theme.color
    },

    iconBackgroundColor() {
      return Color(this.theme.color).lighten(0.2).hex()
    },

    host() {
      return location.origin
    },
  },

  mounted() {
    this.$store.dispatch('moduleMemoransier/fetchAll')
  },

  methods: {
    createQuizGame() {
      this.createGame(false)
    },
    createFallingGame() {
      this.createGame(true)
    },
    createGame(isFallingGame) {
      this.$store.dispatch('moduleMemoransier/createGame', {
        name: 'name',
        role: '',
        options: {
          courseId: this.courseId,
          stepGroupId: this.journeyId,
          fallingGame: isFallingGame,
        },
      })
    },

    deleteGame(gameId) {
      this.$store.dispatch('moduleMemoransier/deleteGame', gameId)
      this.linkCopied = false
    },

    gotoGame(gameId) {
      this.$router.push({
        name: 'MemoransierLobby',
        params: { gameId },
      })
    },

    copy(url) {
      if (!this.linkCopied) {
        console.log('copy url', url)
        navigator.clipboard.writeText(url)
        this.linkCopied = true
        setTimeout(() => {
          this.linkCopied = false
        }, 2000)
      }
      // alert("link was copied to clipboard");
    },
  },
}
</script>

<style lang="scss" scoped>
.JourneyChallenge {
  margin-top: 1em;

  .ChallengeButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #305fdf;
    padding: 0.7em 1.3em;
    margin-top: 0.6em;
    border-radius: 0.5em;
    font-size: 1.2em;
    color: #eeeeee;
    cursor: pointer;

    &:hover {
      background: #2b55c9;
    }
  }

  .challengeCreatedContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #1f2362;
    padding: 0.7em 1.3em;
    border-radius: 0.5em;

    .tooltip {
      visibility: hidden;
      // width: 120px;
      background-color: #333a92;
      color: #ffffff;
      text-align: center;
      font-size: 0.95em;
      border-radius: 0.5em;
      padding: 0.5em 1em;
      justify-self: flex-start;
      margin-bottom: 3em;

      position: absolute;
      z-index: 1;
    }

    .challengeCreatedTitle {
      font-size: 1.3em;
      color: #eeeeee;
      @media (max-width: 620px) {
        font-size: 1.1em;
        margin-right: 1em;
      }
    }

    .challengeCreatedButtons {
      display: flex;
      flex-direction: row;

      .leaderboard {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: #1f2362;
        border: 0.1em solid #a4b1cd51;
        border-radius: 0.3em;
        color: white;
        padding: 0.3em 0.5em;
        padding-bottom: 0.55em;
        margin-right: 0.5em;
        cursor: pointer;

        .leaderboardIcon {
          display: flex;
          width: 1.4em;
          height: 1.4em;
          // border: 1px solid red;
          @media (max-width: 620px) {
            width: 1.1em;
            height: 1.1em;
          }
        }

        .tooltip {
          margin-bottom: 3.25em;
        }
        &:hover .tooltip {
          visibility: visible;
          @media (max-width: 620px) {
            visibility: hidden;
          }
        }
      }

      .share {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: #1f2362;
        border: 0.1em solid #a4b1cd51;
        border-radius: 0.3em;
        color: white;
        padding: 0.7em;
        margin-right: 0.5em;
        cursor: pointer;

        .tooltipCopied {
          visibility: hidden;
          // width: 120px;
          background-color: #333a92;
          color: #ffffff;
          text-align: center;
          font-size: 0.95em;
          border-radius: 0.5em;
          padding: 0.5em 1em;
          justify-self: flex-start;
          margin-bottom: 3em;

          position: absolute;
          z-index: 1;
          @media (max-width: 620px) {
            margin-bottom: 2.7em;
          }
        }

        &:hover .tooltip {
          visibility: visible;
          @media (max-width: 620px) {
            visibility: hidden;
          }
        }
        &:hover .tooltipCopied {
          visibility: visible;
        }
        .shareIcon {
          display: flex;
          width: 1em;
          height: 1em;
          @media (max-width: 620px) {
            width: 0.7em;
            height: 0.7em;
          }
        }
      }

      .delete {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        background: #1f2362;
        border: 0.1em solid #a4b1cd51;
        border-radius: 0.3em;
        padding: 0.7em;
        cursor: pointer;

        .tooltip {
          margin-right: -2.2em;
        }
        &:hover .tooltip {
          visibility: visible;
          @media (max-width: 620px) {
            visibility: hidden;
          }
        }

        .deleteIcon {
          width: 1em;
          height: 1em;
          @media (max-width: 620px) {
            width: 0.7em;
            height: 0.7em;
          }
        }
      }

      .start {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: #1f2362;
        border: 0.1em solid #a4b1cd51;
        border-radius: 0.3em;
        padding: 0.7em;
        margin-right: 0.5em;
        cursor: pointer;

        &:hover .tooltip {
          visibility: visible;
          @media (max-width: 620px) {
            visibility: hidden;
          }
        }

        .startIcon {
          width: 1em;
          height: 1em;
          @media (max-width: 620px) {
            width: 0.7em;
            height: 0.7em;
          }
        }
      }
    }
  }
}
</style>
