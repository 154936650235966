<template>
  <div class="CourseToc">
    <CourseTocIntroEntry
      v-if="course.introStepId"
      class="course-toc-intro-entry"
      :course-id="courseId"
      :step-id="course.introStepId"
      @action="$emit('action')" />
    <JourneyItem
      v-for="stepGroupId of course.stepGroupIds"
      :key="stepGroupId"
      class="JourneyItem"
      :step-group-id="stepGroupId"
      :use-ellipsis="useEllipsis"
      :hide-activities="hideActivities"
      :collapsed="expandedJourneyId !== stepGroupId"
      @request-collapse="collapse => (collapse ? (expandedJourneyId = null) : (expandedJourneyId = stepGroupId))" />

      <CourseChallenge
        v-if="courseId==='ls-41-no'"
        :course-id="courseId"
      />

  </div>
</template>

<script>
import scrollparent from '@/scrollparent';
import JourneyItem from './JourneyItem';
import CourseTocIntroEntry from './CourseTocIntroEntry';
import CourseChallenge from './JourneyChallenge/courseChallenge.vue'

export default {
    components: { JourneyItem, CourseTocIntroEntry, CourseChallenge },
    props: {
        courseId: {
            type: String
        },
        useEllipsis: {
            type: Boolean,
            default: false
        },
        hideActivities: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            collapseMap: {},
            expandedJourneyId: null,
            scrollToStep: null
        };
    },
    computed: {
        course() {
            return this.$store.state.moduleCourse.courses[this.courseId];
        }
    },
    created() {
        const lastStepId = this.$store.state.moduleCourse.otherStuff.lastStepId;
        this.$store.commit('moduleCourse/setLastStepId', { stepId: null });
        const lastJourneyId = this.$store.state.moduleCourse.otherStuff.lastJourneyId;
        this.$store.commit('moduleCourse/setLastJourneyId', { journeyId: null });

        if (lastStepId) {
            const step = this.$store.state.moduleCourse.steps[lastStepId];
            if (step) {
                if (this.course.stepGroupIds.indexOf(step.stepGroupId) !== -1) {
                    this.scrollToStep = lastStepId;
                    this.expandedJourneyId = step.stepGroupId;
                    return;
                }
            }
        } else if (lastJourneyId) {
            this.scrollToStep = 'QUIZ';
            this.expandedJourneyId = lastJourneyId;
            return;
        }

        const courseStats = this.$store.getters['moduleCourse/getCourseStats'](this.courseId);
        if (courseStats.firstActiveJourneyId || courseStats.firstActiveStepId) {
            this.expandedJourneyId = courseStats.firstActiveJourneyId;
        }
    },
    async mounted() {
        if (this.scrollToStep) {
            const scrollContainer = scrollparent(this.$el);

            if (scrollContainer) {
                let adjust = 14;
                if (scrollContainer.__vue__) {
                    const stickyHeight = scrollContainer.__vue__.stickyHeight();
                    adjust += stickyHeight;
                }
                const y1 = scrollContainer.getBoundingClientRect().top;
                const elem =
                    this.scrollToStep === 'QUIZ'
                        ? this.$el.querySelector('.JourneyQuizItem')
                        : this.$el.querySelector(`.StepItem[data-step-id="${this.scrollToStep}"]`);
                const y2 = elem.getBoundingClientRect().top;

                scrollContainer.scrollTop = y2 - y1 - adjust;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.CourseToc {
    .course-toc-intro-entry {
        margin-bottom: 1em;
    }
    @media (max-width: 1026px) {
        margin-right: 0;
    }
}
.JourneyItem {
    margin-bottom: 1em;
}
</style>
