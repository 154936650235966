<template>
  <div
    class="RichTextBlock"
    v-html="html" />
</template>

<script>
export default {
    props: {
        html: {
            type: String
        }
    }
};
</script>

<style lang="scss" scoped>
.RichTextBlock {
    line-height: 1.5em;
    font-weight: 300;
    color: #444;

    /deep/ {
        h1,
        h2,
        h3,
        strong {
            color: #333;
        }
        h1 {
            font-size: 2.25em;
            border-bottom: solid 1px #ddd;
            padding-bottom: 0.3em;
            font-weight: 400;
            line-height: 1.2em;
        }
        h2 {
            font-size: 1.75em;
            padding-bottom: 0.3em;
            border-bottom: solid 1px #ddd;
            margin-bottom: 0.5em;
            font-weight: 400;
            line-height: 1.2em;
        }
        h3 {
            padding-bottom: 0.3em;
            margin-bottom: 0.5em;
            font-weight: 400;
            line-height: 1.2em;
        }
        p {
            margin-bottom: 1em;
            // color: #666;
        }
        ul {
            list-style-type: disc;
            // color: #666;
            padding-left: 2em;
        }
        li {
            margin-bottom: 0.5em;
        }
        strong {
            font-weight: 600;
        }
        .meta {
            display: none;
        }
    }
}
</style>
