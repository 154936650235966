<template>
  <div class="CoursePanel">
    <PanelHeader>
      <div slot="title">
        {{ $t('title') }}
      </div>
      <div slot="text">
        {{ $t('text') }}
      </div>
    </PanelHeader>
    <SectionWithTabs
      v-if="activeSection"
      class="mainSection"
      :items="sections"
      :active-item="activeSection"
      @click="selectTab">
      <component
        :is="activeSection.component"
        :lifeskill-id="lifeskillId" />
    </SectionWithTabs>
  </div>
</template>

<translations>
  title: 'Unlock your natural memory genius'
  title_no: 'Slipp løs ditt naturlige memogeni'

  text: 'Enjoy the combination of new memory research, ancient memory principles and groundbreaking new memory techniques. The result is one master memory system that will unlock your memory super powers in everyday life.'
  text_no: 'Nyt kombinasjonen av ny hukommelsesforskning, antikkens huskeprinsipper og banebrytende nye husketeknikker. Resultatet er et mastersystem for hukommelse som vil slippe løs ditt naturlige memogeni i hverdagen.'

  systems: 'Systems'
  systems_no: 'Systemer'
  games: 'Games'
  games_no: 'Spill'
</translations>

<script>
import SectionWithTabs from './SectionWithTabs';
import SectionGames from './SectionGames';
import SectionMemorySystems from './SectionMemorySystems';
import PanelHeader from '../PanelHeader';

export default {
    components: {
        SectionWithTabs,
        SectionGames,
        SectionMemorySystems,
        PanelHeader
    },
    inject: ['theme'],
    props: {
        lifeskillId: {
            type: String
        },
        tabName: {
            type: String
        }
    },
    computed: {
        showStats() {
            return !!this.masterCourseId;
        },
        lifeskill() {
            return this.$store.getters['moduleApp/getLifeskillById'](this.lifeskillId);
        },
        masterCourseId() {
            return this.lifeskill.masterCourseId;
        },
        activeSection() {
            return this.sections.find(section => section.name === this.tabName) || this.sections[0];
        },
        sections() {
            let sections = [];

            sections.push({
                title: this.$t('systems'),
                name: 'systems',
                icon: '02systems',
                component: 'SectionMemorySystems'
            });

            sections.push({
                title: this.$t('games'),
                name: 'games',
                icon: '02games',
                component: 'SectionGames'
            });
            return sections;
        }
    },
    created() {
        if (!this.tabName) {
            this.selectTab(this.sections[0]);
        }
    },
    methods: {
        selectTab(tab) {
            if (tab.name === this.tabName) {
                return;
            }
            this.$router.replace({
                name:
                    tab.name === 'systems'
                        ? 'DashboardLifeskillViewActiveSystemsTab'
                        : 'DashboardLifeskillViewActiveGamesTab'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
$statsSectionBackroundColor: mix(black, white, 5%);

.CoursePanel {
    background-color: $statsSectionBackroundColor;
}

.mainSection {
    margin-top: 2em;
}
</style>
