<template>
  <div
    class="AboutAuthorPopup"
    data-bg="light">
    <div class="topSection">
      <div
        class="image"
        :style="{ backgroundImage: `url(${image})` }" />
      <div class="name">
        {{ name }}
      </div>
      <div class="position">
        {{ position }}
      </div>
    </div>
    <div class="bottomSection">
      <div class="text">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
import defaultImage from '@/assets/images/frank-avatar.jpg';

export default {
    props: {
        image: {
            type: String,
            default: defaultImage
        },
        name: {
            type: String,
            default: 'Frank Wedde'
        },
        position: {
            type: String,
            default: 'CEO Memolife'
        },
        text: {
            type: String,
            // default: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            default:
                'Frank Wedde er hjernetrener, foredragsholder, forfatter og gründer av Memolife. Hans lidenskap er å bygge bro mellom forskning til både praktiske og morsomme teknikker som trener hjernen til hurtigere læring og vaneendring.'
        }
    }
};
</script>

<style lang="scss" scoped>
.AboutAuthorPopup {
    background-color: white;
    color: black;
    width: 26em;
}

.topSection {
    padding: 2em;
}

.image {
    $size: 7em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: gray;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    margin: auto;
}

.name {
    font-size: 140%;
    font-weight: 600;
    color: rgba(black, 0.9);
    text-align: center;
    margin-top: 0.5em;
}
.position {
    color: rgba(black, 0.7);
    margin-top: 0.3em;
    text-align: center;
    font-size: 90%;
}
.bottomSection {
    background-color: mix(black, white, 5%);
    padding: 2em;
}
.text {
    margin-top: 0.3em;
    color: rgba(black, 0.5);
    line-height: 1.4em;
}
</style>
