<template>
  <div
    class="ScenarioNotification --global-clickable"
    @click="gotoScenario()">
    <div class="text">
      {{ $t('text') }}
      <span class="courseName">{{ $t(`dashboard.general.lifeskillLabels.${course.lifeskillNumber}`) }}</span>
      <!-- COMBAK how can I providee courseName when scenario can be part of multiple courses? -->
    </div>
    <div
      v-if="image"
      class="image" />
    <div
      v-if="time"
      class="time">
      {{ time }}
    </div>
  </div>
</template>

<translations>
  text: 'Your daily habit training with a new scenario in'
  text_no: 'Din daglige vanetrening med et nytt scenario i'
</translations>

<script>
export default {
    props: {
        id: String,
        image: String,
        time: String,
        stepId: String,
        scenarioIndex: Number,
    },
    computed: {
        course() {
            return this.$store.state.moduleCourse.courses[this.step.courseId];
        },
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        }
    },
    methods: {
        gotoScenario() {
            this.$emit('action');
            this.$router.push({
                name: 'ScenarioView',
                params: {
                    id: this.id,
                    lifeskillId: this.course.lifeskillNumber,
                    stepId: this.stepId,
                    scenarioIndex: this.scenarioIndex
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.ScenarioNotification {
    padding: 1em;
    color: rgba(black, 0.5);
}
.image {
    width: 5em;
    height: 4em;
    background-color: gray;
    margin-top: 0.5em;
}
.text {
    line-height: 1.5em;
}
.time {
    color: rgba(black, 0.5);
    margin-top: 0.5em;
}
.courseName {
    color: rgba(black, 0.8);
}
</style>
