<template>
  <div
    v-if="course && courseId !== 'ls-70-no'"
    class="ScenariosPanel">
    <Practice
      class="tocSection"
      :course-id="courseId" />
    <div>
      <About
        popup-type="practice"
        :lifeskill-id="course.lifeskillNumber" />
      <TeamLifekeys :lifeskill="course.lifeskillNumber" section="Practice" />
    </div>
  </div>
  <div
    v-else
    class="ScenariosPanel">
    <MentalHygienePractice />
    <div>
      <About
        popup-type="practice"
        :lifeskill-id="course.lifeskillNumber" />
      <TeamLifekeys :lifeskill="course.lifeskillNumber" section="Practice" />
    </div>
  </div>
</template>

<translations>
  abstractSectionTitle: 'About Course'
  abstractSectionTitle_no: 'Om kurset'
</translations>

<script>
import Practice from '@/modules/course/components/Practice';
import MentalHygienePractice from '@/modules/course/components/MentalHygienePractice';
import About from '@/modules/dashboard/components/About';
import TeamLifekeys from '@/modules/team/TeamLifekeys';

export default {
    components: {
        Practice,
        MentalHygienePractice,
        About,
        TeamLifekeys,
    },

    inject: ['theme'],

    props: {
        courseId: {
            type: String,
            required: true
        },
        showImage: {
            type: Boolean,
            default: false
        },
        hideTitle: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        course() {
          return this.$store.getters['moduleCourse/getCourseById'](this.courseId);
        }
    },

    watch: {
      courseId() {
        this.load();
      },
    },

    mounted() {
      this.load();
    },

    methods: {
      load() {
        this.$store.dispatch('moduleCourse/loadCourseFull', { courseId: this.courseId });
        this.$store.dispatch('scenario/loadData', this.courseId);
        this.$store.dispatch('scenario/loadProgress');
      },
    }
};
</script>

<style lang="scss" scoped>
.ScenariosPanel {
    position: relative;
    padding-bottom: 4em;
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 886px) {
        flex-direction: column;
        align-items: center;
        padding-top: 0;
    }
    .tocSection {
        width: 100%;
        margin-right: 30px;
        @media (max-width: 886px) {
            margin-right: 0;
            padding-bottom: 30px;
        }
    }

}

.About {
  padding-bottom: 2em;
}

.image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    margin-bottom: 1em;

    &::after {
        content: '';
        display: block;
        padding-bottom: percentage(9 / 16);
    }
}

.title {
    font-size: 140%;
    text-transform: lowercase;
    &::first-letter {
        text-transform: uppercase;
    }
}

.abstractSectionTitle {
    font-size: 120%;
    font-weight: 600;
}
.abstract {
    margin-top: 1em;
    line-height: 1.5em;
    color: rgba(black, 0.7);
}

.progressBox {
    width: 100%;
    margin-bottom: 2em;
}

.AboutAuthor {
    padding: 1em 0;
    border-top: 1px solid rgba(black, 0.05);
    border-bottom: 1px solid rgba(black, 0.05);
}
</style>
