<template>
  <div
    class="UpgradeButton --global-clickable"
    :class="{ compact, inHeader }"
    @click="openPopup()">
    <svgicon
      class="icon"
      icon="lock2" />
    <span
      v-if="!paymentFailure"
      class="label">{{ inHeader ? $t('labelShort') : $t('label') }}</span>
    <span
      v-if="paymentFailure"
      class="label">{{ inHeader ? $t('paymentLabelShort') : $t('paymentLabel') }}</span>
  </div>
</template>

<translations>
  label: 'Upgrade to unlock'
  label_no: 'Få full tilgang'
  labelShort: 'Upgrade'
  labelShort_no: 'Oppgrader'
  paymentLabelShort: "Missing payments"
  paymentLabelShort_no: "Manglende betaling"
  paymentLabel: "Missing payments"
  paymentLabel_no: "Manglende betaling"
</translations>

<script>
import UpgradePopup from './UpgradePopup';
import PaymentFailurePopup from './PaymentFailurePopup';

export default {
    props: {
        compact: {
            type: Boolean,
            default: false
        },
        inHeader: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        paymentFailure() {
            return this.$store.state.moduleAuth.profile.subscription.status === 'payment-failure';
        }
    },
    mounted() {
        const e = this.paymentFailure ? 'payment_failure_button' : 'upgrade_button';
        window.dataLayer.push({
            event: e
        });
    },
    methods: {
        openPopup() {
            this.$modal.open({
                component: this.paymentFailure ? PaymentFailurePopup : UpgradePopup,
                target: 'app-modal'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.UpgradeButton {
    background-color: $standardButtonColor;
    color: white;
    border-radius: 2em;
    padding: 0em 2.3em;
    padding-left: 1.5em;
    text-transform: uppercase;
    // margin: auto;
    // display: inline-block;
    height: 3.5em;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.compact {
        height: 3em;
        .label {
            font-size: 90%;
        }
    }
    &.inHeader {
        height: 2em;
        padding: 0em 1em;
        .label {
            font-size: 90%;
        }
        .icon {
            display: none;
        }
    }
}
.label {
    // font-size: 90%;
}
.icon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: white;
    margin-right: 0.5em;
}
</style>
