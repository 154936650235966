<template>
  <div class="JourneyIcon">
    <img v-if="type === 'theory'" class="icon" src="@/assets/images/lifeskillBadgeImages/theory-badge.png" alt="">
    <img v-else class="icon" src="@/assets/images/lifeskillBadgeImages/practice-badge.png" alt="">
    <div v-if="label" class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.JourneyIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 96px;
  min-height: 96px;
  max-width: 96px;
  max-height: 96px;
  @media (max-width: 530px) {
    min-width: 68px;
    min-height: 68px;
    max-width: 68px;
    max-height: 68px;
  }
  .icon {
    width: 100%;
    height: 100%;
  }
  .label {
    font-size: 100%;
    font-weight: 600;
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    @media (max-width: 530px) {
      font-size: 10px;
    }
  }
}
</style>
