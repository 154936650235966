<template>
  <div
    :data-name="name"
    class="StandardButton --global-clickable"
    :class="{ hasIcon: icon, disabled, pulsate }"
    :style="{ backgroundColor: theme.color, '--pulseColor': theme.color }"
    @click="handleClick">
    <div class="label">
      {{ label }}
    </div>
    <div
      v-if="icon"
      class="circle"
      :style="{ backgroundColor: circleColor }">
      <svgicon
        class="icon"
        :icon="icon" />
    </div>
  </div>
</template>

<script>
import Color from 'color';

export default {
    props: {
        icon: {
            type: String
        },
        name: {
            type: String
        },
        label: {
            type: String,
            required: true
        },
        theme: {
            type: Object,
            default() {
                return { color: '#5081ff' };
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        pulsate: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        circleColor() {
            return Color(this.theme.color).darken(0.2);
        }
    },
    methods: {
        handleClick(e) {
            if (this.disabled) {
                return;
            }
            this.$emit('click', e);
        }
    }
};
</script>

<style lang="scss" scoped>
$circleSize: 2.5em;
$circleMargin: 0.5em;

.StandardButton {
    height: $circleSize + $circleMargin * 2;
    padding: 0 2em;
    background-color: green;
    border-radius: 2em;
    display: inline-flex;
    align-items: center;
    color: white;
    position: relative;

    &.hasIcon {
        padding-right: $circleMargin;
    }

    &.disabled {
        cursor: default;
        opacity: 0.5;
    }
}
.label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.circle {
    width: $circleSize;
    height: $circleSize;
    background-color: darken(green, 5%);
    border-radius: 50%;
    margin-left: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.icon {
    fill: white;
    width: 1em;
    height: 1em;
}

$pulseDuration: 3s;
.StandardButton.pulsate::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2em;

    animation: pulse-animation2 $pulseDuration ease 1s infinite;

    box-shadow: 0 0 0 0 var(--pulseColor);

    @keyframes pulse-animation2 {
        0% {
            box-shadow: 0 0 0 0 var(--pulseColor);
        }
        50% {
            box-shadow: 0 0 0 0.5em transparent;
        }
        100% {
            box-shadow: 0 0 0 0 transparent;
        }
    }
}
</style>
