import categoryThemes from '@/category-themes';
import { getCategoryIdFromLifeskillId } from '@/utils';

const defaultTheme = {
    color: '#5081ff',
    headerBgColor: '#113978'
};

export default {
    provide() {
        return {
            theme: this.theme
        };
    },
    data() {
        const step = this.$store.state.moduleCourse.steps[this.stepId];
        const course = this.$store.state.moduleCourse.courses[this.courseId];
        const lifeskillId = (step && step.fromLifeskill) || (course && course.lifeskillNumber);
        const categoryId = getCategoryIdFromLifeskillId(lifeskillId);
        const theme = categoryThemes[categoryId];

        return {
            theme: theme || defaultTheme
        };
    }
};
