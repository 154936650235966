<template>
  <div class="MemolifeLogo">
    <div class="heart" />
    <div class="text" />
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.MemolifeLogo {
    display: flex;
    align-items: center;
}
.heart {
    $height: 1.8em;
    height: $height;
    width: $height * 1.2;
    margin-right: 0.5em;
    background-image: url('./memolife-heart.svg');
    background-size: contain;
    background-repeat: no-repeat;
}
.text {
    $height: 1em;
    height: $height;
    width: $height * 6.25;
    background-image: url('./memolife-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 530px) {
        display: none;
    }
}
</style>
