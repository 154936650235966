<template>
  <div class="DashboardMemorySystemItem">
    <div class="header">
      <div
        class="icon"
        :style="{ backgroundImage: `url(${image})` }" />
    </div>
    <div class="body">
      <div class="spacer" />
      <div
        v-t="`memogenius.techniques.${systemId}.title`"
        class="name" />
    </div>
  </div>
</template>

<script>
const images = require.context('@/assets/images/memogenius/system/icon/', true, /\.(png|jpg)$/);

export default {
    props: {
        systemId: {
            type: String,
            required: true
        }
    },
    computed: {
        image() {
            try {
                return images(`./${this.systemId}.png`);
            } catch (err) {
                return null;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.DashboardMemorySystemItem {
    background-color: $defaultItemBackgroundColor;
    display: flex;
    flex-direction: column;

    min-height: 100%;
}

.icon {
    width: 50%;
    min-width: 4em;
    max-width: 6em;
    margin: auto;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &::after {
        content: '';
        display: block;
        padding-top: 100%;
    }
}

.header {
    padding-top: 1em;
}

.body {
    padding: 1em 0.5em;
    padding-top: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.spacer {
    flex: 1;
    min-height: 1em;
}

.name {
    text-align: center;
    color: rgba(black, 0.9);
    font-size: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
