<template>
  <div class="Practice">
    <ScenarioItem
      v-for="journey of journeyStepsThatHasScenarios"
      :key="'journey-' + journey.id"
      :use-ellipsis="useEllipsis"
      :hide-activities="hideActivities"
      :journey="journey"
      :course-id="courseId"
      :expand=" journey.id === getJourneyToExpand" />
  </div>
</template>

<script>
import ScenarioItem from './ScenarioItem';
import { mapGetters } from 'vuex';
import uniqBy from 'lodash/uniqBy';

export default {
    components: { ScenarioItem },
    props: {
        courseId: {
            type: String
        },
        useEllipsis: {
            type: Boolean,
            default: false
        },
        hideActivities: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            collapseMap: {},
        };
    },

    computed: {
        ...mapGetters({
            getJourneyById: 'moduleCourse/getJourneyById',
            getStepById: 'moduleCourse/getStepById'
        }),

        scenarios() {
          return this.$store.getters['scenario/byCourseId'](this.courseId)
            .map(x => x.stepGroups).flat()
            .map(sg => sg.steps || []).flat()
            .map(step => {
              return (step.scenarios||[]).map(s => ({
                stepId: step.id,
                  ...s,
              }));
            })
            .flat()
        },

        getJourneyToExpand(){
            if(this.nextJourneyWithOpenScenario!=null){
                return this.nextJourneyWithOpenScenario;
            } 
            return '';
        },

        nextJourneyWithOpenScenario(){
          if(this.scenarios){
            for (var i = 0; i < this.scenarios.length; i++) {
              if (this.$store.getters['scenario/isOpen'](this.scenarios[i].id) && !this.$store.getters['scenario/isDone'](this.scenarios[i].id)) {
                const step = this.getStepById(this.scenarios[i].stepId)
                return step.stepGroupId;
              } 
            }
          } 
          return null;
        },

        course() {
            return this.$store.state.moduleCourse.courses[this.courseId];
        },

        courseStepGroups() {
            return this.course.stepGroupIds.map(id => this.getJourneyById(id));
        },

        journeys() {
          return this.$store.getters['scenario/byCourseId'](this.courseId)
            .map(s => s.stepGroups)
            .flat()
            .filter(sg => sg.steps.some(s => s.scenarios));

        },
        journeyStepsThatHasScenarios() {
          return uniqBy(this.journeys, 'id');
        },

        courseStats() {
            return this.$store.getters['moduleCourse/getCourseStats'](this.courseId);
        },

        items() {
            return [
                {
                    title: 'title',
                    align: 'center',
                    text: 'text',
                    progress: 0
                },
                {
                    title: 'title',
                    align: 'center',
                    text: 'text',
                    progress: 0
                },
                {
                    title: 'title',
                    align: 'center',
                    text: 'text',
                    progress: 0
                }
            ];
        }
    },


    methods: {
        scrollToJourney(){
            if (this.nextJourneyWithOpenScenario!=null){
                const element = this.$el;
                element.scrollIntoView({behavior: "smooth"});
            } 
        }
    },

    mounted() {
        this.scrollToJourney();
    }
};
</script>

<style lang="scss" scoped>
.Practice {
    @media (max-width: 884px) {
        margin-right: 0;
    }
}
.ScenarioItem {
    &:not(:first-child) {
        margin-top: 1em;
    }
}
</style>
