<template>
  <div class="SectionGames">
    <GridWithExpansion
      class="items"
      :items="games"
      :index.sync="selectedItemIndex"
      :layout="layout"
      expansion-color="#1b1a1a"
      @select-item="selectItem">
      <DashboardGameItem
        slot="item"
        slot-scope="props"
        class="Item"
        :game-id="props.item" />

      <DashboardGameDetails
        slot="detail"
        :key="selectedGame"
        :game-id="selectedGame"
        :index="selectedItemIndex" />
    </GridWithExpansion>
  </div>
</template>

<script>
import GridWithExpansion from '@/components/GridWithExpansion';

import DashboardGameItem from '@/modules/dashboard/components/DashboardGameItem';
import DashboardGameDetails from '@/modules/dashboard/components/DashboardGameDetails';

import GridLayoutController from '../GridLayoutController';

export default {
    components: { DashboardGameItem, DashboardGameDetails, GridWithExpansion },
    data() {
        return {
            layout: null,
            selectedItemIndex: -1
        };
    },
    computed: {
        games() {
            return this.$store.getters['moduleMemogenius/getGameIds'];
        },
        selectedGame() {
            return this.games[this.selectedItemIndex] || null;
        }
    },
    created() {
        this.disposeLayoutConroller = new GridLayoutController(
            {
                heightRatio: null
            },
            layout => {
                this.layout = layout;
            }
        );
    },
    beforeDestroy() {
        this.disposeLayoutConroller();
    },
    methods: {
        selectItem(x) {
            // alert(x)
        }
    }
};
</script>

<style lang="scss" scoped>
.SectionGames {
    padding-top: 2em;
}
.Item {
    border: 1px solid $defaultItemBorderColor;
}
</style>
