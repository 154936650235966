import { render, staticRenderFns } from "./JourneyItem.vue?vue&type=template&id=4cba2094&scoped=true&"
import script from "./JourneyItem.vue?vue&type=script&lang=js&"
export * from "./JourneyItem.vue?vue&type=script&lang=js&"
import style0 from "./JourneyItem.vue?vue&type=style&index=0&id=4cba2094&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cba2094",
  null
  
)

/* custom blocks */
import block0 from "./JourneyItem.vue?vue&type=custom&index=0&blockType=translations"
if (typeof block0 === 'function') block0(component)

export default component.exports