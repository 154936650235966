<template>
  <div
    class="VideoItem --global-clickable"
    :data-video-id="videoId"
    @click="!isDragging && gotoVideo()">
    <div class="card">
      <div class="layer">
        <div class="play-button">
          <svgicon
            icon="play-button"
            class="icon" />
        </div>
      </div>
      <div
        v-lazy:background-image="thumbnail"
        class="thumbnail" />
    </div>

    <div class="title">
      {{ title }}
    </div>

    <p class="braindates-count">
      {{ braindatesCountText }}
    </p>
  </div>
</template>

<translations>
    brainDates: '{count} brain dates'
    brainDates_no: '{count} hjernedates'
    brainDatesCompletedOfTotal: '{completed} of {total} brain bates'
    brainDatesCompletedOfTotal_no: '{completed} av {total} hjernedates'
</translations>

<script>
import VideoController from '../../VideoController';

export default {
    props: {
        videoId: {
            type: String,
            required: true
        },
        lifeskillId: {
            type: String,
            default: null
        },
        hideLifeskillTag: {
            type: Boolean,
            default: false
        },
        videoTitle: {
            type: String,
            default: null
        },
        videoDuration: {
            type: Number,
            default: null
        },
        videoThumbnail: {
            type: String,
            default: null
        },
        isDragging: {
            type: Boolean,
            default: false
        }
    },

    created() {
        this.videoController = new VideoController(this.video.id, this.lifeskillId);
    },

    computed: {
        video() {
            return this.$store.getters['moduleApp/getVideoById'](this.videoId);
        },

        thumbnail() {
            return this.videoThumbnail || this.video.thumbnail;
        },

        title() {
            if (this.videoTitle) {
                return this.videoTitle;
            }

            return this.video.title.length > 80 ? this.video.title.substr(0, 76) + ' ...' : this.video.title;
        },

        numberOfParts() {
            return this.videoController.parts.length;
        },

        numberOfCompletedParts() {
            return this.videoController.parts.filter(p => p.completed).length;
        },

        braindatesCountText() {
            if (this.numberOfCompletedParts) {
                return this.$t('brainDatesCompletedOfTotal', {
                    completed: this.numberOfCompletedParts,
                    total: this.numberOfParts
                });
            }

            return this.$t('brainDates', { count: this.numberOfParts });
        }
    },

    methods: {
        gotoVideo() {
            this.$router.push({
                name: 'DashboardVideoView',
                params: {
                    videoId: this.videoId,
                    lifeskillId: this.lifeskillId || this.video.lifeskillIds[0]
                }
            });

            this.$emit('clicked-video', this.videoId);
        }
    }
};
</script>

<style lang="scss" scoped>
$transitionDuration: 0.3s;

.VideoItem {
    display: flex;
    flex-direction: column;

    .braindates-count {
        color: #5983f7;
        font-size: 16px;
        @media (max-width: 530px) {
            font-size: 14px;
        }
    }

    &:hover {
        .layer {
            visibility: visible;
        }
    }
}

.layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(34, 29, 29, 0.3);
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .play-button {
        width: 6em;
        height: 4em;
        border-radius: 0.5em;
        background: #5983f7;
        opacity: 0.7;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
            fill: white;
            width: 2em;
        }
    }
}

.card {
    position: relative;

    transform: translate3d(0, 0, 0); // Avoid Safari visual bug, where overflow: hidden is ignored while scaling .image
    box-shadow: $dashboard-itemShadow;
}
.thumbnail {
    background-position: center;
    transition: transform $transitionDuration;
    background-size: 100% 135%;
    height: 12em;
    border-radius: 0.25em;
}

.numberOfParts {
    background-color: rgba(black, 0.9);
    color: #00bcd4;
    padding: 0.5em 1em;
    position: absolute;
    border-radius: 2em;
    bottom: 1em;
    right: 1em;
    font-size: 90%;
}

.label {
    color: rgba(white, 0.5);
    font-size: 80%;
}
.title {
    margin-top: 1em;
    line-height: 1.3;
    font-size: 16px;
    color: #EEEEEE;
    @media (max-width: 530px) {
        font-size: 14px;
    }
}

.lifeskill {
    display: flex;
    align-items: center;
    margin-top: 1em;
}
.lifeskillLabel {
    color: $dashboard-smallTextColor;
    font-size: 90%;
}
.lifeskillTag {
    position: absolute;
    top: 1em;
    right: 1em;
}
</style>
