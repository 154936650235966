<template>
  <div
    data-bg="light"
    class="SimpleSlides"
    :class="{ onDarkBackground }">
    <div class="header">
      <slot name="header" />
    </div>
    <div class="slides">
      <SimpleCarousel
        class="slides"
        :index.sync="activeSlideIndex"
        @size="numberOfSlides = $event">
        <slot name="slides" />
      </SimpleCarousel>
    </div>

    <div class="footer">
      <div
        class="dots"
        :class="{ hidden: activeSlideIndex === numberOfSlides - 1 }">
        <div
          v-for="i of numberOfSlides"
          :key="i"
          class="Dot --global-clickable"
          :class="{ active: i - 1 === activeSlideIndex }"
          @click="activeSlideIndex = i - 1" />
      </div>
      <StandardButton
        class="doneButton"
        :class="{ hidden: activeSlideIndex < numberOfSlides - 1 }"
        :label="buttonLabel"
        @click="$emit('done')" />

      <div class="navigButtons">
        <div
          class="navigButton navigButton--prev --global-clickable"
          :class="{ disabled: activeSlideIndex === 0 }"
          @click="activeSlideIndex = Math.max(0, activeSlideIndex - 1)">
          <svgicon
            class="svg-icon"
            icon="play-triangle" />
        </div>
        <div
          class="navigButton navigButton--next --global-clickable"
          :class="{ disabled: activeSlideIndex === numberOfSlides - 1 }"
          @click="activeSlideIndex = Math.min(numberOfSlides - 1, activeSlideIndex + 1)">
          <svgicon
            class="svg-icon"
            icon="play-triangle" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleCarousel from '@/components/SimpleCarousel';
import StandardButton from '@/components/StandardButton';

export default {
    components: { SimpleCarousel, StandardButton },
    props: {
        buttonLabel: {
            type: String,
            required: true
        },
        onDarkBackground: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            numberOfSlides: 0,
            activeSlideIndex: 0
        };
    },
    watch: {
        activeSlideIndex(val) {
            this.$emit('update:index', val);
        }
    }
};
</script>

<style lang="scss" scoped>
$lightGradient: linear-gradient(0deg, #f3f6f7, #f2f4f5);
$transitionDuration: 0.5s;

.SimpleSlides {
    background-image: $lightGradient;
    padding: 2em;

    &.onDarkBackground {
        background-image: none;

        .Dot {
            background-color: rgba(white, 0.2);
            &.active {
                background-color: white;
            }
        }

        .navigButton {
            background-color: rgba(white, 0.05);

            &.disabled {
                opacity: 0.2;
            }

            .svg-icon {
                fill: rgba(white, 0.7);
            }
        }
    }
}
._title {
    font-weight: 600;
    font-size: 130%;
    text-align: center;
}
._subtitle {
    margin-top: 1em;
}

.footer {
    position: relative;
    text-align: center;
    margin-bottom: 1.4em;
}

.dots {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity $transitionDuration;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }
}
.Dot {
    $size: 8px;
    position: relative;
    width: $size;
    height: $size;
    margin: 0.5em;
    border-radius: 50%;
    background-color: rgba(black, 0.2);

    &::after {
        content: '';
        position: absolute;
        display: block;
        $offset: -0.5em;
        top: $offset;
        right: $offset;
        bottom: $offset;
        left: $offset;
    }

    &.active {
        background-color: black;
    }
}

.navigButtons {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
}
.navigButton {
    $size: 2em;
    width: $size;
    height: $size;

    border-radius: 50%;
    background-color: rgba(black, 0.05);
    text-align: center;
    transition: opacity $transitionDuration;

    &.disabled {
        opacity: 0.2;
        cursor: default;
    }

    .svg-icon {
        display: inline;
        fill: #45505f;
        margin-top: 25%;
        width: 50%;
        height: 50%;
    }
}
.navigButton--prev {
    margin-right: 0.3em;
    .svg-icon {
        transform: scale(-1, 1);
    }
}

.doneButton {
    transition: opacity $transitionDuration;
    &.hidden {
        opacity: 0;
        pointer-events: none;
    }
}
</style>
