<template>
  <container>
    <div class="VideosPanel">
      <h3>{{ $t('title') }}</h3>

      <videos-progress-bar :lifeskill-id="lifeskillId" />

      <playlist-carousel
        v-for="playlist in playlists"
        :key="playlist.name"
        :lifeskill-id="lifeskillId"
        :playlist="playlist" />
    </div>
    <TeamLifekeys :lifeskill="lifeskillId" section="Video" />
  </container>
</template>

<translations>
  title: 'Edutain yourself with video journeys'
  title_no: "'Edutain' deg selv med videoreiser"
</translations>

<script>
import Container from '@/modules/dashboard/components/Container';
import VideosProgressBar from '@/modules/dashboard/components/VideosProgressBar';
import PlaylistCarousel from '../../views/MemolifeTvView/PlaylistCarousel';
import _ from 'lodash';
import TeamLifekeys from '@/modules/team/TeamLifekeys';

const playlistSort = (a, b) => {
    const ordering = {
        'Mixed Up': 1,
        Blandet: 1,
        BrainCraft: 2,
        BigThink: 3,
        'TED-Ed': 4,
        TED: 5,
        'Chill & Deep Dive': 6,
        'Slapp av og dypdykk': 6
    };

    return (ordering[a.name] || 10) - (ordering[b.name] || 10);
};

export default {
    components: {
        Container,
        VideosProgressBar,
        PlaylistCarousel,
        TeamLifekeys,
    },

    props: {
        lifeskillId: {
            type: String
        }
    },

    data() {
        return {
            loading: true,
            layout: null,
            selectedVideoIndex: -1
        };
    },

    computed: {
        playlists() {
            const videos = this.$store.getters['moduleApp/getVideosForLifeskill'](this.lifeskillId);

            return _.chain(videos)
                .groupBy('playlist')
                .map((v, i) => {
                    return {
                        name: i,
                        videos: _.sortBy(v, v => parseInt(v.ordering))
                    };
                })
                .value()
                .sort(playlistSort);
        }
    }
};
</script>

<style lang="scss" scoped>
$backgroundColor: mix(black, white, 3%);

.container {
    max-width: 965px;
    width: calc(100% - 6vw);
}

.VideosPanel {
    // background-color: $backgroundColor;
    padding-bottom: 4em;

    h3 {
        font-size: 22px;
        font-weight: 400;
        line-height: 26px;
        color: #EEEEEE;
        margin-top: 54px;
        margin-bottom: 22px;
        @media (max-width: 886px) {
            font-size: 18px;
            margin: 54px auto 22px;
        }
        @media (max-width: 530px) {
            font-size: 16px;
        }
    }
}

.LifeskillVideos {
    /deep/ .Playlist {
        .title {
            .count {
                color: #EEEEEE;
            }
        }
    }
}

.loadingIndication {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3em;
}

.loadingIcon {
    $size: 2em;
    width: $size;
    height: $size;
    fill: #EEEEEE;
    margin-left: 1em;
    animation: rotate360 2s linear infinite;
}

@keyframes rotate360 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
