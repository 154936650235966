import axios from 'axios';
import settings from '@/settings';

export function getLegacyToken() {
    const url = `${settings.endpoints.bouncer}/legacy-login/`;

    return axios.get(url).then(response => {
        return response.data.memoapp;
    });
}

export function getCoupon(id) {
    const url = `${settings.endpoints.bouncer}/coupons/${id}`;
    return axios.get(url).then(response => {
        return response.data;
    });
}
