<template>
  <div class="DashboardNotificationPopup">
    <div class="header">
      <div
        v-t="'title'"
        class="title">
        Notifications
      </div>
    </div>
    <div class="body">
      <div class="notifications">
        <component
          :is="notification.componentName"
          v-for="(notification, i) of notifications"
          :key="i"
          class="Notification"
          v-bind="notification.props"
          @action="$emit('signal', 'done')" />
      </div>
    </div>
  </div>
</template>

<translations>
  title: 'Notifications'
  title_no: 'Meldinger'
</translations>

<script>
import DashboardGenericNotification from './DashboardGenericNotification';
import ScenarioNotification from '@/modules/scenario/ScenarioNotification';

export default {
    components: {
        DashboardGenericNotification,
        ScenarioNotification,
    },
    computed: {
        notifications() {
            return this.$store.getters['moduleApp/notifications'];
        }
    }
};
</script>

<style lang="scss" scoped>
.DashboardNotificationPopup {
    background-color: white;
    width: 24em;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.header {
    padding: 1em;
}
.title {
    font-size: 120%;
}

.body {
    overflow: auto;
    flex: 1;
}

.Notification {
    &:not(:last-child) {
        border-bottom: 1px solid #efefef;
    }
}
</style>
